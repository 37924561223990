import React, { Fragment } from 'react';
import { Spin, Image, Typography, Button, Row, Col, Card, Form, Input, Select, Radio, DatePicker, Tooltip, Space } from 'antd';
import { createLead } from '../../api/pets-api';
import { Link } from "react-router-dom";
import moment from 'moment';
import { CURRENCY, BenifitsMap } from '../../constants';
import { notifications } from '../../helpers/utilities';
import FeaturesTable from './features_table';
const { Paragraph, Title } = Typography;

const { Option } = Select;

class RenewalMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            petTypeBreeds: [],
            petBreeds: [],
            plans: [],
            spin: true,
            disableBtn: false,
            dogId: '',
            type: 'Cat',
            genderBtnType: 'Male',
            petBtnType: 'Cat',
            petBtnClass: 'Cat',
        }
        this.changeType = this.changeType.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.renewalSubmit = this.renewalSubmit.bind(this);
        // this.saveQoute = this.saveQoute.bind(this);
    }
    qouteRef = React.createRef()
    formRef = React.createRef();
    pageRef = React.createRef();
    mainRef = React.createRef();

    onGenderChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ genderBtnType: e.target.value })
    }
    onTypeChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ petBtnType: e.target.value })
        if (e.target.value === this.state.dogId) {
            this.setState({ petBtnClass: 'dog' })
        }else{
            this.setState({ petBtnClass: 'cat' })
        }
    }

    onDetailClick = () => {
        this.pageRef.current.scrollIntoView();
    }

    componentDidMount = () => {
        console.log('props child', this.props);
        
        
        this.props.setQouteRef(this.qouteRef)
        this.mainRef.current.scrollIntoView()
        // console.log('mount', this.props.petTypeBreeds);
        this.props.petTypeBreeds.map(type => {
            if (type.name == 'Cat') {
                this.setState({ dogId: type.id, petBreeds: type.breeds })
            }
        });
        this.setState({ petTypeBreeds: this.props.petTypeBreeds, plans: this.props.plans }, ()=>{
            
            if(this.props.location.pathname === "/ins"){
                this.goToQouteSection()
            }
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.petTypeBreeds !== prevProps.petTypeBreeds) {
            if (this.state.spin) {
                console.log('update', this.props.petTypeBreeds);

                this.props.petTypeBreeds.map(type => {
                    if (type.name == 'Cat') {
                        this.setState({ dogId: type.id, petBreeds: type.Breads })
                    }
                })
                this.setState({ spin: false })
            }
            let typeBreeds = this.props.petTypeBreeds;
            typeBreeds.map(type => {
                let crossIndex = type.Breads.findIndex(breed => breed.name === 'Crossbreed');
                if (crossIndex !== -1) {
                    type.Breads.unshift(type.Breads.splice(crossIndex, 1)[0]);
                }
            })
            this.setState({ petTypeBreeds: typeBreeds })
        }
        if (this.props.plans !== prevProps.plans) {
            console.log('plans', this.props.plans);

            this.setState({ plans: this.props.plans });
        }
    }

    async onFinish(values) {

        let selectedDate = values.age;
        let type = this.state.type;
        let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
        let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
        console.log('age different params', ageMthDiff, ageYrDiff, type);
         if (ageMthDiff > 96 && type === 'Dog') {
            let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        } else if (ageMthDiff > 120 && type === 'Cat') {
            let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        }

        console.log('cleicked')
        values = { ...values, age: moment(values.age).toDate() }
        console.log(values);
        let pPlanObj = {}
        pPlanObj = this.props.preQoute.hasOwnProperty('petsWithPlans') ? { petsWithPlans: this.props.preQoute.petsWithPlans } : { petsWithPlans: [] }
        pPlanObj.petsWithPlans.splice(pPlanObj.petsWithPlans.length, 0, { ...values, planId: 1, reimburseId: 1, deductionId: 1 });
        console.log('pPlanObj',pPlanObj);
        var respLead = await createLead(pPlanObj);
        if(respLead.data.error){
            notifications('Error occored while saving the lead', 'Alert', 'error')
        }else{
            
            this.props.setLeadId(respLead.data.result);
        }
        this.props.populateQoute(pPlanObj)
        this.props.changeFlag('qoute')
    };

    renewalSubmit() {
        
        let pPlanObj = {petsWithPlans : [
            {
                "name": this.props.policy.name,
                "gender": this.props.policy.gender,
                "age": this.props.policy.dob,
                "breed": this.props.policy.breedId,
                "email": this.props.policy.email,
                "type": this.props.policy.typeId,
                "planId": this.props.policy.planTitle == 'Vital' ? 0 : this.props.policy.planTitle == 'Favorite' ? 1 : 2,
                "reimburseId": this.props.policy.copayment == 70 ? 0 : this.props.policy.copayment == 80 ? 1 : 2,
                "deductionId": this.props.policy.deductable == 500 ? 0 : this.props.policy.deductable == 1000 ? 1 : 2,
                "isPrimaryPet":  this.props.policy.isPrimaryPet
            }
            ]
        }

        this.props.populateQoute(pPlanObj)
        this.props.changeFlag('qoute')
    }


    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    changeType(event) {
        let index = event.target.id;
        let type = event.target.petType;
        let petBreeds = this.state.petTypeBreeds[index].Breads
        this.setState({ petBreeds, type })
    }

    goToQouteSection = () => {
        this.qouteRef.current.scrollIntoView()
    }

    ageChange = () => {
        this.setState({ disableBtn: false }, () => {

            let selectedDate = this.formRef.current.getFieldValue('age');
            let type = this.state.type;
            let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
            let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
            console.log('age different params', ageMthDiff, ageYrDiff, type);
            if (ageMthDiff < 2) {
                let dateCopy = moment(selectedDate);
                let nextDate = dateCopy.add(2, 'M');
                console.log(ageMthDiff, nextDate);
                let ageMsg = 'At Oslo we love puppies and kitten, the effective date of your insurance will be 15th of ' + nextDate.format('MM') + '/' + nextDate.format('Y') + ' , for more info on age requirements please check our FAQ'
                notifications(ageMsg, 'Alert', 'warning')
            } else if (ageMthDiff > 96 && type === 'Dog') {
                let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            } else if (ageMthDiff > 120 && type === 'Cat') {
                let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            }
        });
    }

    render() {

        let date = new Date(this.props.policy.createdAt);
        let effectiveDate = new Date(this.props.policy.effectiveDate);
        let expireDate = new Date(this.props.policy.effectiveDate);

        expireDate.setFullYear(expireDate.getFullYear() + 1);
        expireDate.setDate(expireDate.getDate() - 1);

        

        return (
            <div ref={this.mainRef}>
                <Fragment>
                    
                    <div className="customise-paln bg-gray-light pt-5 pb-5 full-width" ref={this.qouteRef}>
                        <div className="container renewal-initiator">
                            <Title level={2} className="text-green text-center section-title mb-5">{!this.props.policy.renewed ? 'Let’s renew' : 'Awesome, you have already renewed' } {this.props.policy.name}'s plan </Title>
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={13}>
                                    <Row gutter={15} align="middle" className="full-width">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <figure>
                                                <div className="pet-image image-uploaded">
                                                    <img alt={this.props.policy.name} src={this.props.policy.petImage} />
                                                </div>
                                            </figure>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Pet Parent Name : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1 renewal-data' strong={1}>{this.props.policy.firstName} {this.props.policy.lastName}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Pet's Name : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.name}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Gender : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.gender}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Breed : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.breed}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Type : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.type}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Policy Number : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.policySerialNumber}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Policy Category : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.planTitle}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Deductible: </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{ this.props.policy.policyCurrency ? (this.props.policy.policyCurrency).toUpperCase() : ''} {this.props.policy.deductable}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Reimbursement : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{this.props.policy.copayment} {this.props.policy.copayment ? '%' : ''}</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='m-0 w-100'>
                                                <Row>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Title level={3} className="m-0 p-0 text-light-green" align="right" >Expiry Date : </Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Paragraph className='ms-2 mb-1  renewal-data' strong={1}>{ this.props.policy ? (expireDate.toLocaleString('en-us', { month: 'long' }) + '-' + expireDate.getDate() + '-' + expireDate.getFullYear()) : '' }</Paragraph>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {
                                                !this.props.policy.renewed &&
                                                <div className='m-0 w-100 text-center mt-3'>
                                                    <Button type="primary" className="btn-red rounded-100 ant-btn-lg" htmlType="button" onClick={this.renewalSubmit} >Let's Renew</Button>
                                                </div>
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Fragment>
            </div>
        );
    }
}

export default RenewalMainPage;