import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Typography, Row, Col, Card, Button } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import { getUserPets } from '../../api/pets-api';

const { Title, Paragraph } = Typography;
const { Meta } = Card;
const { Content } = Layout;

class contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPets: []
        };
    }
    async componentDidMount() {
        await getUserPets().then((userPets) => {
            this.setState({ userPets: userPets.data.result })
        })
    }
    render() {
        return (
            <Layout className="dashboard pet-cloud-contact middle">
                <LoginHeader />
                <Content className="bg-paws2 full-width-section pl-0 pb-3">
                    <div className="container">
                        <Row gutter={15} justify="center">
                            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                <Title level={2} className="text-green text-center mb-3 section-title">Contact us the way you want</Title>
                                <Row gutter={[15, 15]} align="middle" justify="center">
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                        <div className="contact-option text-center">
                                            <div className="circle-box">
                                                <figure><img src="/images/icon-contact-phone.png" /></figure>
                                            </div>
                                            <Paragraph className="text-green font-600 mb-2">Phone</Paragraph>
                                            <Button className="text-light-green" type="link" href="tel:+971 4 248 0561 ">+971 4 248 0561 </Button>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={12}>
                                        <div className="contact-option text-center">
                                            <div className="circle-box">
                                                <figure><img src="/images/icon-email.png" /></figure>
                                            </div>
                                            <Paragraph className="text-green font-600 mb-2">Email</Paragraph>
                                            <Button className="text-light-green" type="link" href="mailto:support@osloinsurance.pet">support@osloinsurance.pet</Button>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                        <div className="contact-option text-center">
                                            <div className="circle-box">
                                                <figure><img src="/images/icon-contact-whatsapp.png" /></figure>
                                            </div>
                                            <Paragraph className="text-green font-600 mb-2">WhatsApp</Paragraph>
                                            <Button className="text-light-green" type="link" href="tel:+971 4 248 0561 ">+971 4 248 0561 </Button>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-3 mt-3">
                                        <Row gutter={[15, 15]} justify="center">
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <div className="contact-text-block mt-0 mb-0">
                                                    <Title level={3} className="text-green font-600 text-center mb-2">Customer Experience</Title>
                                                    <Paragraph className="text-muted text-center mb-0">
                                                        Monday to Friday : 8am to 5pm 
                                                        Saturday & Sunday : email us at: support@osloinsurance.pet
                                                    </Paragraph>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <div className="contact-text-block mt-0 mb-0">
                                                    <Title level={3} className="text-green font-600 text-center mb-2">Claims Support <small>(Central time)</small></Title>
                                                    <Paragraph className="text-muted text-center mb-0">
                                                        Sunday to Thursday <br />
                                                        8.30am-5.30pm
                                                    </Paragraph>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Title level={2} className="text-red text-center mb-2 section-title">Have a media enquiry?</Title>
                                <Title level={2} className="text-green text-center mt-0 font-300"><span className="text-muted">Email us at</span> <a href="mailto:media@osloinsurance.pet" className="text-light-green">media@osloinsurance.pet.</a></Title>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <LoginFooter />
            </Layout >
        );
    }
}

export default contact;