import React, { Component, Fragment } from 'react';
import { Table, Typography, Row, Col, Tooltip, Collapse } from 'antd';
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

class features_table extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <Row gutter={15} justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="table-responsive features-table">
                            <div className="top-headings full-width">
                                <Row gutter={0}>
                                    <Col xl={12}></Col>
                                    <Col xl={4}>
                                        <div className="cat-title dark-blue">VITAL</div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="cat-title light-blue">FAVORITE</div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="cat-title dark-blue">OPTIMUM</div>
                                    </Col>
                                </Row>
                            </div>
                            {/* defaultActiveKey={['1']} */}
                            <Collapse className="full-width" accordion >
                                <Panel header=
                                    {
                                        <Row gutter={0} justify="center">
                                            <Col xl={12} className="border-end">
                                                <div className="cell-inner first-col">
                                                    <Title level={5} className="font-700 text-green mb-0">
                                                        Annual Veterinary Benefits
                                                        <span className="icon-info">
                                                            <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will pay, up to the maximum annual benefits for this coverage, for the actual cost of any medically necessary treatment  your pet receives during the policy period for a covered illness and accident </div>}>
                                                                <i className="fa fa-info-circle"></i>
                                                            </Tooltip>
                                                        </span>
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end">
                                                <div className="cell-inner">
                                                    <Title level={4} className="font-700 text-green mb-0">AED 20,000</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end bg-lightblue">
                                                <div className="cell-inner">
                                                    <Title level={4} className="font-700 text-white mb-0">AED 35,000</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center">
                                                <div className="cell-inner">
                                                    <Title level={4} className="font-700 text-green mb-0">AED 70,000</Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    } key="1">
                                    <Row gutter={0} justify="center">
                                        <Col xl={12} className="border-end">
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Vets exam fees
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Reimbursement for exams/consultation fees, Oslo will pay, up to the maximum annual benefit for the actual cost(s) of any medically necessary treatment your pet receives during the policy period for a covered illness or injury.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Emergency & Hospitalization
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Visit any licensed 24- hour emergency animal hospital or clinic for urgent care - Oslo will cover necessary prolonged observation or treatment at the veterinary clinic/hospital related to a covered illness or injury
                                                        </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Surgeries
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Any surgery considered medically necessary by a licensed vet for a covered accident or illness.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Prescription Medications
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover approved medications perscribed by a licensed veterinarian to treat a covered illness or injury.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Cancer treatments
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">OSLO will cover the most advanced cutting edge tretments for cancer.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Imaging
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover advanced imaging technology including X rays, MRIs, Ultrasounds ...for the purpose of diagnosing and treating a covered injury or illness</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Laboratory & Diagnostic Testing
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover any Laboratory and diagnostic testing that is considered medically necessary by a licensed veterinarian.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Knee Conditions (such as ACL)
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">knee condition coverage is included and begins after the 6 months waiting Period</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Hip Dysplasia
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover hip dysplasia after a 6 month orthopedic waiting period</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Rehabilitation
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover rehabilitation costs prescribed and administered by a licensed veterinarian. This include coverage for threatements like underwater treatmill therapy, swimmimg therapeutic exercices, range of motion exercices, laser therapy, cryotherapy, and heat therapy.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Hereditary & Congential Conditions
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">OSLO will offer coverage for congenital and hereditary conditions granted signs or symptoms were not present prior to the start of the policy or during the applicable waiting periods </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Non-Routine Dental (accident only)
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Non routine dental treatment may be covered when related to a dental injury or illness and only if both veterinarian - recommended dental care has been provided and no history of periodontal disease exists. Coverage does not extend to prevention and routine cleaning</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Holistic & Alternative Therapies
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">OSLO will cover holistic and alternative treatments services performed by a licensed veterinarian.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Prosthetic & Orthotic Devices
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover devices realted to surgery recovery, arthritis, paralysis, and amputation</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Euthanasia
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">If the veterinarian determines euthanasia is medically necessary, that expenses will be covered.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Behavioral Problems
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo may cover any medication prescribed for the treatment of behavioral problems </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Lifetime Maximum Benefit
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">this is the maximum amount OSLO will reimburse to you for the covered claims over the lifetime benefits of your pet. Note Annual veterinary benefit limits still apply, based on plan section </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                        </Col>
                                        <Col xl={12} className="text-center">
                                            <Row gutter={0} className="h-100">
                                                <Col xl={8} className="text-center border-end">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-green.png"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={8} className="text-center bg-lightblue">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-white.png"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={8} className="text-center">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-green.png"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel header=
                                    {
                                        <Row gutter={0} justify="center">
                                            <Col xl={12} className="border-end">
                                                <div className="cell-inner first-col">
                                                    <Title level={5} className="font-700 text-green mb-0">
                                                        OSLO Assistance
                                                        <span className="icon-info">
                                                            <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will enhance your plan with powerups that fit your pet's need and/or as pet parents. Assistance will include Emergency boarding fees, lost pet advertising and rewards, and much more </div>}>
                                                                <i className="fa fa-info-circle"></i>
                                                            </Tooltip>
                                                        </span>
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end bg-lightblue">
                                                <div className="cell-inner h-100">
                                                    <Title level={5} className="font-700 text-white mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    } key="2">
                                    <Row gutter={0} justify="center" className="h-100">
                                        <Col xl={12} className="border-end">
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Emergency Boarding Fees
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover for the actual cost of boarding your pet at a licensed kennel or cattery during the period while you are in a hospital as a result of your own sickness, disease, or bodliy injured. no co-pay and deductible apply to this coverage </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Lost pet advertising and Reward
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover up to the maximum annual limit for this coverage part as specified on the declarations page, for the cost of advertising or offering a reward if your pet is stolen or strays during the policy period</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Lost pet due to Theft or Straying
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will cover up to a maximum limit for this coverage part as shown on the declarations page, the price you paid for your pet, if your pet is stolen or goes missing during the policy period and is not found within 30 days.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col border-bottom">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Emergency Vacation Cancellation
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will pay up to a maximum limit for this coverage as shown on the decalration page, for any travel amd accomodation costs you cannot recover as a result of having to delay, cancel or interrupt a vacation because your pet requires immediate lifesaving treatment </div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                            <div className="cell-inner first-col">
                                                <Title level={5} className="font-700 text-green mb-0">
                                                    Death from covered illness or injury
                                                    <span className="icon-info">
                                                        <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will pay up to maximum limit for this coverage as shown on the declaration page, if your pet dies or has to be euthanized by a licensed vet during the policy period as a result of covered illness or injury.</div>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </Tooltip>
                                                    </span>
                                                </Title>
                                            </div>
                                        </Col>
                                        <Col xl={12} className="text-center">
                                            <Row gutter={0} className="h-100">
                                                <Col xl={8} className="text-center border-end">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-green.png"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={8} className="text-center bg-lightblue">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-white.png"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={8} className="text-center">
                                                    <div className="cell-inner h-100">
                                                        <img
                                                            className="img-fluid"
                                                            src="/images/icon-check-green.png"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse collapsible="disabled" className="full-width border-top-0">
                                <Panel header=
                                    {
                                        <Row gutter={0} justify="center">
                                            <Col xl={12} className="border-end">
                                                <div className="cell-inner first-col">
                                                    <Title level={5} className="font-700 text-green mb-0">
                                                        OSLO Tag for your pet buddy
                                                        <span className="icon-info">
                                                            <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo will offer your pet 's tag with your welcome kit to make sure your pet is more easily found in case it goes missing.</div>}>
                                                                <i className="fa fa-info-circle"></i>
                                                            </Tooltip>
                                                        </span>
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end bg-lightblue">
                                                <div className="cell-inner h-100">
                                                    <Title level={5} className="font-700 text-white mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    } key="3">
                                </Panel>
                                <Panel header=
                                    {
                                        <Row gutter={0} justify="center">
                                            <Col xl={12} className="border-end">
                                                <div className="cell-inner first-col">
                                                    <Title level={5} className="font-700 text-green mb-0">
                                                        OSLO Pet Cloud Access
                                                        <span className="icon-info">
                                                            <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Oslo pet cloud connnects you to OSLO community. You will be able to easily submit your pet's claims and keep your medical records anytime, anywhere. Much more to come </div>}>
                                                                <i className="fa fa-info-circle"></i>
                                                            </Tooltip>
                                                        </span>
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center border-end bg-lightblue">
                                                <div className="cell-inner h-100">
                                                    <Title level={5} className="font-700 text-white mb-0">Included</Title>
                                                </div>
                                            </Col>
                                            <Col xl={4} className="text-center">
                                                <div className="cell-inner">
                                                    <Title level={5} className="font-700 text-light-green mb-0">Included</Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    } key="4">
                                </Panel>
                            </Collapse>
                            <div className="top-headings bottom full-width">
                                <Row gutter={0}>
                                    <Col xl={12}></Col>
                                    <Col xl={4}></Col>
                                    <Col xl={4}>
                                        <div className="cat-title light-blue"></div>
                                    </Col>
                                    <Col xl={4}></Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default features_table;