import React, { Component } from 'react';
import { Typography, Row, Col } from 'antd';
import MainLayout from '../layouts/mainLayout'
const { Paragraph, Title } = Typography;

class terms_of_use extends Component {

    render() {
        return (
            <MainLayout>
                <div className="banner privacy-policy">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-tou.jpg"
                    />
                    <div className="banner-caption">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-green tagline">Terms Of Use</Title>
                                        <Title level={3} className="text-green mt-0 mb-5 sub-tagline">You may wish to put  <br /> your glasses on!</Title>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="full-width-section privacy-policy-content full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="points-toknow">
                                    <ul className="red-dots">
                                        <li>
                                            <Title level={4} className="text-green">Please read these Terms carefully and make sure that you understand them. By using OSLO Sites or Services, you accept and agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Sites or Services. </Title>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green mb-0">These Terms and Conditions of Use (these “<span className="text-light-green">Terms</span>”) govern your access to and the use of OSLO (“<span className="text-light-green">OSLO</span>” or “<span className="text-light-green">we</span>” or “<span className="text-light-green">us</span>”) sites or services, including our website <span className="text-light-green">www.osloinsurance.pet</span> (“<span className="text-light-green">Our Site</span>”), our mobile app, products and services, and certain other tools offered, whether as a registered user or guest (collectively, “<span className="text-light-green">our Sites or Services</span>”).
                                            </Title>
                                            <Title level={4} className="text-green">OSLO may modify or update these Terms from time to time. By using our Sites and Services, you are expected to review the Terms on a regular basis to ensure that you understand all the terms and conditions governing the use of our Sites and Services.
                                            </Title>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">1. Information About Us</Title>
                                            <Paragraph className="text-green mb-2">OSLO FZE is a privately-owned technology company, registered with DTEC/Dubai Silicon Oasis in Dubai, United Arab Emirates.</Paragraph>
                                            <Paragraph className="text-green mb-2">
                                                OSLO is not an insurance company. OSLO has designed pet insurance plans along with a unique customer experience. and we partnered with AL ITTIHAD AL WATANI, a Dubai insurance company, to make our OSLO plans available in the UAE.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">To contact us, please email <a href="mailto:support@Osloinsurance.pet" className="font-600 text-light-green">support@osloinsurance.pet.</a>
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">2. Insurance Quotes</Title>
                                            <Paragraph className="text-green mb-0">All quotes for insurance products provided to you on our Site are estimates based upon the information you provided and are not a binding agreement to extend insurance coverage. You represent and warrant that any application submitted by you is, to the best of your knowledge, true, correct, and complete. Any coverage descriptions provided on our Site are general descriptions of available coverages and are not a statement of contract. To obtain coverage, you must submit an application to OSLO and AIAW. All applications are subject to underwriting approval.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">3. Reliance on Certain Posted Information</Title>
                                            <Paragraph className="text-green mb-2">The information presented on or through our Sites or Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of such information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to our Sites or Services, or by anyone who may be informed of any of its contents. Coverage under any pet insurance policy is expressly subject to the conditions, restrictions, limitations, exclusions, and terms of the policy documentation issued by the insurer.</Paragraph>
                                            <Paragraph className="text-green mb-2">Our Sites or Services may include content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, aggregators or reporting services. All statements or opinions expressed in any such materials, other than the content provided by us, are solely the opinions and the responsibility of the person or entity providing those materials. Any such materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</Paragraph>
                                            <Paragraph className="text-green mb-2">If any of our Sites or Services contain links to other sites and resources provided by third parties, these links are provided for your convenience only. These links include those contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party sites linked to our sites, you do so entirely at your own risk and subject to the terms of use for such websites.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">4. Site Use</Title>
                                            <Paragraph className="text-green mb-0">You do not need to register with us to visit our Site and access certain information. However, you will need to create an account in order to access certain password-protected areas of our Site and some of our Sites or Services (“<span className="text-light-green">your Account</span>”). You hereby represent and warrant that the information you provide to us is truthful, accurate, complete, and current.</Paragraph>
                                            <Paragraph className="text-green mt-4 mb-0"><span className="text-light-green">License</span> - Subject to these Terms, we grant you a limited, non-transferable, non-exclusive, and revocable permission to access and use our Sites and Services, provided that: (a) you will not copy, distribute, or modify any part of the our Sites or Services without our prior written authorization, (b) you will not send unsolicited or unauthorized advertisements, spam, chain letters, etc., (c) you will not transmit any content that contains software viruses, or any other harmful computer code, files, or programs, (d) you will not disrupt servers or networks connected to our Sites or Services, and (e) you comply with these Terms, as may be amended from time to time.</Paragraph>

                                            <Paragraph className="text-green mt-4 mb-2"><span className="text-light-green">Password</span> - You must create a password for your Account. Responsibility for the confidentiality of your password rests solely with you, as well as for any use of any of our Sites or Services made using your login information and password. This password should be used only by you, should not be shared with anyone else, and may be changed by us for any reason at any time upon notice to you.</Paragraph>
                                            <Paragraph className="text-green mb-0"><span className="text-light-green">Accurate Information</span> - In order to access to the OSLO Pet Cloud, we will ask you to create a profile in your Account containing information about you and your pet(s). Your profile will contain your name, email address, picture, as well as certain information as to your pet(s) including name, breed, age, sex, and medical history. Our customers are automatically enrolled in the OSLO Pet Cloud. If you are a customer and wish to opt out of the OSLO Pet Cloud, please contact us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-light-green">support@osloinsurance.pet.</a></Paragraph>

                                            <Paragraph className="text-green mb-2">Any information that you provide about yourself and your pets must be accurate. You shall not use language that is offensive, vulgar, violent, unlawful, or discriminatory, or that infringes someone’s intellectual property rights, or otherwise violates the Terms. You must not post private or confidential information via our Sites or Services, including any credit card information, national identity numbers, non-public phone numbers, or non-public email addresses. You may not use our Sites or Services for any illegal or unauthorized purpose. You agree to comply with all laws, rules, and regulations applicable to your use of our Sites or Services.</Paragraph>
                                            <Paragraph className="text-green mb-2">Some portions of our Sites or Services may be restricted and require authorization for access. Unauthorized use of or access to any such areas is prohibited. It is a condition of your use of our Sites or Services that all information you provide is correct, current, and complete. You agree that all information you provide to register with our Sites or Services, including through the use of any interactive features, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</Paragraph>
                                            <Paragraph className="text-green mb-4">You are responsible for maintaining the security of your information technology systems and computers. If you are an authorized user of any restricted area, you are responsible to maintain the security/confidentiality of your password. OSLO encourages you to change your password regularly. If you suspect any unauthorized activity related to your account, you should contact us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-light-green">support@osloinsurance.pet.</a></Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">5. Your Content on OSLO</Title>
                                            <Paragraph className="text-green mb-2">If you post or submit photographs, images, video, or related media or comments or content (collectively, “<span className="text-light-green">User Content</span>”) on our Sites or Services and social network accounts, including by creating the profile associated by your Account, and otherwise accessing features in our Sites or Services, you agree to abide by these Terms.</Paragraph>
                                            <Paragraph className="text-green mb-2">You shall not submit User Content on our Site that (a) you are not authorized to submit or that are illegal, indecent, profane, threatening, defamatory, derogatory, counter to our Privacy Policy, or otherwise injurious to OSLO or third parties, (b) consist of or invoke malicious software code, (c) constitute commercial solicitation or (d) consist primarily of an unsolicited electronic mass mailing, be political in nature, or the like.</Paragraph>
                                            <Paragraph className="text-green mb-2">User Content that infringes in any manner on the copyright, trademark, or other intellectual property rights of any person or entity, or that contain privileged, confidential, proprietary, or trade secret information of any individual or entity, or that may violate the legal right of any person or entity in any jurisdiction, will not be accepted by OSLO and should not be posted by you.</Paragraph>
                                            <Paragraph className="text-green mb-2">You retain all ownership rights in the original aspects of your User Content. OSLO does not claim ownership of any User Content that you post on our Sites and Services. Instead, you grant to OSLO a non-exclusive, royalty-free, worldwide, perpetual, irrevocable, and fully transferable, sub-licensable, and assignable right to use, edit, modify, adapt, translate, edit, and reformat your User Content as necessary and for any purpose, anywhere in the world, including into those forms that may constitute advertising or marketing.</Paragraph>
                                            <Paragraph className="text-green mb-2">We take no responsibility and assume no liability for any User Content posted by you or any third party. User Content removed from our Sites or Services may continue to be stored by OSLO, including in order to comply with certain legal obligations, but may not be retrievable without a valid court order. OSLO is not a backup service and you agree that you will not rely on OSLO for the purposes of User Content backup or storage. OSLO encourages you to maintain your own backup of your User Content. OSLO will not be liable to you for any modification, suspension, or discontinuation of our Sites or Services, or the loss of any User Content. You also acknowledge that the internet may be subject to breaches of security and that the submission of User Content or other information may not be secure.</Paragraph>
                                            <Paragraph className="text-green mb-2">We reserve the right to modify or terminate your access to our Sites or Services for any reason, without notice, at any time, and without liability to you.</Paragraph>
                                            <Paragraph className="text-green mb-2">You are solely responsible for your interaction with other users of our Sites or Services, whether online or offline. You agree that OSLO is not responsible or liable for the conduct of any user. We reserve the right, but have no obligation, to monitor or become involved in disputes between you and other users. </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">6. Intellectual Property</Title>
                                            <Paragraph className="text-green mb-2">OSLO owns all the intellectual property rights and materials contained in our Sites and Services, and all such rights are reserved. All copyrights, trademarks, design rights, patents and other intellectual property rights (registered and unregistered) in and on the Site belong to OSLO and/or third parties. Nothing in the Terms grants you a right or license to use any trademark, design right or copyright owned or controlled by us or any other third party except as expressly provided in the Terms.</Paragraph>
                                            <Paragraph className="text-green mb-2">You must not, without OSLO prior approval:</Paragraph>
                                            <ul>
                                                <li>republish or show in public material from this Site (including republication on another Site);</li>
                                                <li>sell, rent or sub-license material from the Site;</li>
                                                <li>reproduce, duplicate, copy or otherwise exploit material on this Site for a commercial purpose.</li>
                                            </ul>
                                            <Paragraph className="text-green mb-2">Use of any OSLO trademarks as metatags on any third-party site is strictly prohibited. You may not co-brand our Site or display our Site in frames (or any OSLO content via in-line links) without prior written permission from OSLO. </Paragraph>
                                            <Paragraph className="text-green mb-2">If you wish to link to our Site, please contact us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-light-green">support@osloinsurance.pet.</a> to obtain our prior written approval.</Paragraph>
                                            <Paragraph className="text-green mb-2">Use of OSLO insurance quoting tool or Get A Quote landing page for commercial use or to create product quote comparison is prohibited without our prior written consent.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">7. User Social Media Content</Title>
                                            <Paragraph className="text-green mb-2">By tagging any of your social media content (the “<span className="text-light-green">User Social Media Content</span>”) with the hashtag <span className="text-light-green">#Osloinsurance.pet</span>, you agree to abide by these Terms.</Paragraph>
                                            <Paragraph className="text-green mb-2">By agreeing to these Terms, you grant our affiliates and us, and our respective agents, representatives, successors, and assigns (collectively, the “<span className="text-light-green">Licensed Parties</span>”) a license to reproduce, display, perform, distribute, create derivative works from, combine with other materials, alter, edit, and otherwise use the User Social Media Content in connection with our Site and Services, social media channels, platforms, and promotional materials, with no obligation to you whatsoever (the “<span className="text-light-green">License</span>”).</Paragraph>

                                            <Paragraph className="text-green mb-2">This License is non-exclusive, royalty-free, worldwide, perpetual, irrevocable, and fully transferable, sub-licensable, and assignable. You acknowledge and agree that the User Social Media Content is not confidential or proprietary, and that it will not be treated as such by the Licensed Parties.</Paragraph>
                                            <Paragraph className="text-green mb-2">You also represent, warrant, and covenant to the Licensed Parties that.</Paragraph>
                                            <ul>
                                                <li>you are legally considered an adult in your country of residence;</li>
                                                <li>you have full power and authority to agree to these Terms;</li>
                                                <li>the User Social Media Content is your own original creation and does not contain anything that (i) violates or infringes the rights of any person or entity, (ii) violate any applicable laws, rules, or regulations or the terms of the social media platform(s) where you posted the User Social Media Content; (iii) is illegal, defamatory, obscene, or offensive;</li>
                                                <li>you have permission from any person(s) appearing in the User Social Media Content to provide the Licensed Parties with the rights to use the User Social Media Content;</li>
                                            </ul>
                                            <Paragraph className="text-green mb-0">You agree to indemnify, defend, and hold harmless each of the Licensed Parties from and against any claims, demands, liabilities, losses, damages, or expenses (including reasonable attorneys’ fees) related in any way to the Licensed Parties’ use of the User Social Media Content.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">8. Third Party Service Providers</Title>
                                            <Paragraph className="text-green mb-2">OSLO may partner with third party company to make available certain telemedical services to OSLO customers. Any such telemedical services (the “<span className="text-light-green">Telemedical Services</span>”) are provided solely by third party providers and not by OSLO or any of its affiliates.</Paragraph>
                                            <Paragraph className="text-green mb-2">Please note that advice provided in connection with the Telemedical Services are not as thorough or complete as a face-to-face visit with your veterinarian and, as such, the Telemedical Services are not a substitute or replacement for an in-person consultation with your veterinarian.  The Telemedical Services are offered as a benefit to users of OSLO Pet Cloud, but no advice, treatment plans, or results received through the Telemedical Services can be guaranteed.</Paragraph>

                                            <Paragraph className="text-green mb-0">For the avoidance of doubt, insurance coverage under any insurance policy remains subject to the express terms and conditions set forth in such policy and therefore recommendations provided in connection with the Telemedical Services are not guaranteed to be covered by your insurance policy.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">9. Warranties</Title>
                                            <Paragraph className="text-green mb-2">The Site and Services are provided “as is” and OSLO makes no express or implied representations or warranties of any kind, related to the materials contained on the Site, including but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement.</Paragraph>
                                            <Paragraph className="text-green mb-2">Without prejudice to the generality of the foregoing paragraph, we do not warrant that our Sites and Services will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">10. Liabilities</Title>
                                            <Paragraph className="text-green mb-2">In no event shall OSLO nor any of its officers, directors and employees be liable to you for damages or for any claims by any third parties arising out or any way related with your use of this Site, whether such liability is under contract, tort or otherwise, and OSLO, including but not limited to, its officers, directors and employees shall not be liable for any direct or indirect, consequential or special liability arising out of or in any way connected to your use of our Sites and Services.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">11. Indemnification</Title>
                                            <Paragraph className="text-green mb-2">You hereby agree to indemnify to the fullest OSLO from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including attorney’s fees) arising out of or in any way related to your breach of any provisions of the Terms.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">12. Electronic Transmission and Signatures</Title>
                                            <Paragraph className="text-green mb-2">In order to access and use our Sites or Services, you agree to receive information related to such products and services delivered to you electronically. Such information may include quotes, policies and plans, updates, notifications, requested information, transaction receipts, documents requiring your signature, or any other documents (“<span className="text-light-green">Communications</span>”). Your consent does not mean that OSLO must provide the Communications electronically; OSLO may, at its option, provide the Communications through non-electronic means in its sole discretion.</Paragraph>
                                            <Paragraph className="text-green mb-2">You are responsible for informing OSLO if you have changed your personal information, including your name, telephone number, mailing address, e-mail address, or other personal information. You may update your personal information by contacting us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-light-green">support@osloinsurance.pet.</a></Paragraph>

                                            <Paragraph className="text-green mb-2">As part of some OSLO products or services, you may be given the option to sign certain Communications electronically by either checking the appropriate box or engaging in a similar online process as instructed online. You agree that by checking the appropriate box within or adjacent to the applicable Communication or engaging in a similar online electronic signature process, you are providing your electronic signature and agree to be bound by these Terms in such Communication just as if you had signed your name to a paper document.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">13. Underwriter Information</Title>
                                            <Paragraph className="text-green mb-2">OSLO insurance products are underwritten and administered in the UAE by:</Paragraph>
                                            <Paragraph className="text-green mb-2">Al-Ittihad Al-Watani (AIAW), a UAE regulated insurance company,</Paragraph>

                                            <Paragraph className="text-green mb-0">Dubai, UAE</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">14. Other Important Terms</Title>
                                            <Paragraph className="text-green mb-2">These Terms are governed by UAE Laws and any disputes relating to these Terms will be subject to the exclusive jurisdiction of the courts of Dubai.</Paragraph>
                                            <Paragraph className="text-green mb-2">These Terms, including any disclaimer contained on our Site, constitute the entire agreement between you and OSLO in relation to your use of our Sites or Services, and supersede all previous agreements in respect of your use of our Sites and Services.</Paragraph>
                                            <Paragraph className="text-green mb-2">We may transfer our rights and obligations under these Terms to another organization, but this will not affect your rights or our obligations under these Terms. You may not assign, transfer or sub-contract any of your rights or obligations stipulated under the Terms.</Paragraph>
                                            <Paragraph className="text-green mb-2">We reserve the right at any time in our sole discretion to modify, suspend, or discontinue our Sites or Services, content, feature, or product offered through our Sites, with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension or discontinuance of our Sites or Services.</Paragraph>
                                            <Paragraph className="text-green mb-2">If any provision of these Terms is, or is found to be, unenforceable under any applicable law, that will not affect the enforceability of the other provisions of these Terms, or the enforcement of the Terms as a whole.</Paragraph>
                                            <Paragraph className="text-green mb-2">If we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations.</Paragraph>
                                            <Paragraph className="text-green mb-2">We may terminate these Terms at any time without notice, and accordingly may deny you access to our Sites or Services, if in our sole judgment you fail to comply with any term or provision of these Terms. The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of these Terms for all purposes.</Paragraph>
                                            <Title level={4} className="text-green mt-5">© 2021 OSLO FZE All rights reserved.</Title>
                                            <em className="text-light-green">Last updated on December 8, 2021.</em>
                                        </li>
                                    </ul>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default terms_of_use;
