import React, { Component } from 'react';
import { Typography, Collapse, Row, Col, Button, Form, Input } from 'antd';
import {DownCircleOutlined} from '@ant-design/icons';
import MainLayout from '../layouts/mainLayout'
import { Link } from "react-router-dom";
import faqItems from '../helpers/faqList';
import searchFAQData from '../helpers/searchFAQData';
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

class faqs_page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // State to manage the component
            faqItems: []
        }
    }


    componentDidMount() {
        // Code to run after the component has been mounted to the DOM
        this.setState({faqItems: faqItems});
    }

    componentDidUpdate(prevProps, prevState) {
        // Code to run after the component has been updated
    }

    componentWillUnmount() {
        // Code to run before the component is unmounted from the DOM
    }

    executeSearch = (values) => {
        // Code to execute search
        console.log(values);

        if(values.search_pet === "") {
            this.setState({faqItems: faqItems});
            return;
        }
        else{
            console.log("Search for: ", values.search_pet);
            const result = searchFAQData(faqItems, values.search_pet);
            console.log("Search Result: ", result);
            this.setState({faqItems: result});
        }
    }

    

    render() {
        return (
            <MainLayout>
                <div className="banner content-center">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-faqs.jpg"
                    />
                    <div className="banner-caption">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-green tagline">FAQs</Title>
                                        <Title level={3} className="text-green mt-0 mb-5 sub-tagline">So you have a question?  <br /> We are here to answer!</Title>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-get-started position-relative">
                                <Row gutter={15} align="middle" justify="center">
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form
                                            layout={"inline"}
                                            name="search"
                                            className="form-pet-search no-shadow"
                                            onFinish={this.executeSearch}
                                            size={"large"}
                                        >
                                            <Form.Item
                                                name="search_pet"
                                                rules={[{ required: false, message: 'Please type your question keywords.' }]}
                                            >
                                                <Input placeholder="Type your question keywords" />
                                            </Form.Item>
                                            <Form.Item className="btn-search">
                                                <Button block htmlType="submit" className="btn-red">Search</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width-section faqs-content full-width">
                    <div className="container">
                    <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Collapse className="points-toknow" bordered={false} defaultActiveKey={0} expandIconPosition={"right"}>
                                {
                                    this.state.faqItems.map((faqItem, index) => {
                                        return (
                                            <Panel header={<Title level={3} className="m-0 text-light-green font-700">{faqItem.title}</Title>} key={index}>
                                                <Collapse className="points-toknow" bordered={false} defaultActiveKey={0} expandIconPosition={"right"}>
                                                    {faqItem.items.map((item, indexItm) => {
                                                        return (
                                                                <Panel header={<Title level={3} className="m-0 text-red font-700">{item.question}</Title>} key={indexItm}>
                                                                    <Paragraph className="text-green">{item.answer}</Paragraph>
                                                                    {
                                                                        item.hasChildItems && 
                                                                        <Collapse className="points-toknow" bordered={false} expandIconPosition={"right"}>
                                                                            {
                                                                                item.childItems.map((childItem, indexChild) => {
                                                                                    return (
                                                                                        <Panel header={<Title level={4} className="m-0 text-green font-700">{childItem.question}</Title>} key={indexChild}>
                                                                                            <Paragraph className="text-green">{childItem.answer}</Paragraph>
                                                                                        </Panel>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Collapse>
                                                                    }
                                                                </Panel>
                                                        )
                                                    })}
                                                </Collapse>
                                                
                                            </Panel>
                                        )
                                    })
                                } 
                                    
                                    
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default faqs_page;