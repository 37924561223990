import React, { Component, Fragment } from 'react';
import { Spin, Collapse, Typography, Row, Col, Card, Button, Image, Divider, Menu, Modal, Form, Input, Radio, Select, DatePicker, Popconfirm, Badge, Tooltip, Popover } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import { Deduction, DeductionMap, Reimburse, BenifitsMap, CURRENCY } from "../../constants";
import { generatePrice } from '../../api/pets-api';
import { notifications, applyDiscountVat } from '../../helpers/utilities';
import Sidebar from '../../components/common/sidebar';


const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;


class QuotePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            edit: false,
            planSpinning: false,
            planDivSpinning: false,
            planIndex: 1,
            reimburse: 1,
            deduction: 1,
            selectedPetIndex: 0,
            petTypeBreeds: [],
            petBreeds: [],
            plans: [],
            planPrices: [],
            preQoute: {},
            disableBtn: false,
            spin: true,
            popVisible: false,
            planPrice: [],
            petPlansPrices: [],
            type: '',
            userAlreadyExists: false
        }
        this.pageRef = React.createRef();
        this.formRef = React.createRef();
        this.changeType = this.changeType.bind(this);
        this.checkType = this.checkType.bind(this);
        this.onPetFormFailed = this.onPetFormFailed.bind(this);
    }

    async componentDidMount() {
        this.pageRef.current.scrollIntoView();
        window.scrollTo(0,0);
        this.setState({ petTypeBreeds: this.props.petTypeBreeds, preQoute: this.props.preQoute, plans: this.props.plans, planDivSpinning: true }, () => {
            this.fetchPlanPrices(0);
            console.log('state plan', this.state.plans);
        });
        if (this.state.spin && this.props.petTypeBreeds) {
            this.props.petTypeBreeds.map(type => {
                if (type.name == 'Dog') this.setState({ dogId: type.id, petBreeds: type.Breads });
            })
            this.setState({ spin: false })
        }
        console.log('mount', this.props.preQoute);
        console.log('propssssss', this.props);
        this.setState({userAlreadyExists: this.props.hideUserInfo});
        
        
    }

    componentDidUpdate(prevProps) {

        if (this.props.petTypeBreeds !== prevProps.petTypeBreeds) {
            this.setState({ petTypeBreeds: this.props.petTypeBreeds })
        }
        if (this.props.preQoute !== prevProps.preQoute) {
            console.log('updtae', this.props.preQoute);
            this.setState({ preQoute: this.props.preQoute });

        }
        if (this.props.plans !== prevProps.plans) {
            this.setState({ plans: this.props.plans }, () => {
                console.log('update plan', this.state.plans);
            });

        }
        if (this.state.spin && this.props.petTypeBreeds !== prevProps.petTypeBreeds) {
            // console.log('update',this.props.petTypeBreeds);
            this.props.petTypeBreeds.map(type => {
                if (type.name == 'Dog') this.setState({ dogId: type.id, petBreeds: type.Breads })
            })
            this.setState({ spin: false })
        }
    }

    fetchPlanPrices = (petIndex) => {
        let pPlanObj = this.props.preQoute;
        let promises = [];
        let petDeductId = pPlanObj.petsWithPlans[petIndex].deductionId;
        let petReimburseId = pPlanObj.petsWithPlans[petIndex].reimburseId;
        let pet = {
            "petTypeId": pPlanObj.petsWithPlans[petIndex].type,
            "breedId": pPlanObj.petsWithPlans[petIndex].breed,
            "gender": pPlanObj.petsWithPlans[petIndex].gender,
            "name": pPlanObj.petsWithPlans[petIndex].name,
            "dob": pPlanObj.petsWithPlans[petIndex].age,
        }
        let planObj = {
            reimbursment: Reimburse[petReimburseId].reimbursment,
            deductable: DeductionMap[Deduction[petDeductId]],
            pet: pet
        }

        console.log('Submitting Plan', planObj);
        this.props.plans?.map(async plan => {
            planObj.planId = plan.id;
            promises.push(generatePrice(planObj));
        });
        this.setState({ planDivSpinning: true }, () => {
            Promise.all(promises).then((values) => {
                this.setState({ planDivSpinning: false });
                let prices = [], plan1, plan2, plan3;
                plan1 = Math.ceil(values[0].data.result);
                plan2 = Math.ceil(values[1].data.result);
                plan3 = Math.ceil(values[2].data.result);
                console.log('preprices', plan1, plan2, plan3);

                //prices.push(petIndex || this.state.userAlreadyExists ? applyDiscountVat(plan1, 5) : plan1);
                //prices.push(petIndex || this.state.userAlreadyExists ? applyDiscountVat(plan2, 5) : plan2);
                //prices.push(petIndex || this.state.userAlreadyExists ? applyDiscountVat(plan3, 5) : plan3);

                prices.push(plan1);
                prices.push(plan2);
                prices.push(plan3);
                console.log('prices', prices);

                let pPrices = this.state.planPrice;
                pPrices[petIndex] = prices;

                let petPlansPrices = this.state.petPlansPrices;
                petPlansPrices[petIndex] = prices
                this.setState({ planPrice: pPrices, planPrices: prices, petPlansPrices });
                pPlanObj.petsWithPlans[petIndex].plans = prices;
                this.setState({ preQoute: pPlanObj });
                this.props.populateQoute(pPlanObj);
                this.props.setPlanPrices(prices);
                // console.log(values,prices);
                this.props.updateGTAG("view_item");
            });
        })
    }

    changePlan(planIndex, index) {

        let pPlanObj = this.props.preQoute.petsWithPlans;
        console.log('pPlanObj', pPlanObj);

        pPlanObj[index].planId = planIndex;
        pPlanObj[index].planUUID = this.props.plans[planIndex].id;
        this.props.populateQoute({ petsWithPlans: pPlanObj });
        this.setState({ planIndex }, () => {
        })
    }

    changeReimburse(id, index) {
        let pPlanObj = this.props.preQoute.petsWithPlans
        console.log('pPlanObj', pPlanObj);

        pPlanObj[index].reimburseId = id
        this.props.populateQoute({ petsWithPlans: pPlanObj })
        this.setState({ planIndex: 1, planDivSpinning: true }, () => {
            this.fetchPlanPrices(index);
        })
    }

    changeDeduction(deductId, petIndex) {
        let pPlanObj = this.props.preQoute.petsWithPlans
        console.log('pPlanObj', pPlanObj);

        pPlanObj[petIndex].deductionId = deductId
        this.props.populateQoute({ petsWithPlans: pPlanObj });
        this.setState({ planIndex: 1, planDivSpinning: true }, () => {
            this.fetchPlanPrices(petIndex);
        })
    }

    changeType(event) {
        let index = event.target.id;
        let type = event.target.petType;
        let petBreeds = this.state.petTypeBreeds[index].Breads
        this.setState({ petBreeds, type }, () => { if (this.formRef.current.getFieldValue('age')) this.ageChange() })
    }

    callback = (key) => {
        console.log(key);
    }

    addPetForm = (values) => {
        values = { ...values, age: moment(values.age).toDate() }
        let pPlanObj = {};
        let pet = {};
        let petIndex;
        pPlanObj = this.props.preQoute.hasOwnProperty('petsWithPlans') ? { petsWithPlans: this.props.preQoute.petsWithPlans } : { petsWithPlans: [] }
        if (this.state.edit) {
            let petToChange = this.props.preQoute.petsWithPlans[this.state.selectedPetIndex];
            pPlanObj.petsWithPlans[this.state.selectedPetIndex] = { ...petToChange, ...values };
            petIndex = this.state.selectedPetIndex;
            // pet = pPlanObj.petsWithPlans[this.state.selectedPetIndex];
        } else {
            pPlanObj.petsWithPlans.splice(pPlanObj.petsWithPlans.length, 0, { ...values, planId: 1, reimburseId: 1, deductionId: 1 });
            petIndex = (pPlanObj.petsWithPlans.length - 1);
            // pet = { ...values, planId: 1, reimburseId: 1, deductionId: 1 };
        }
        this.props.populateQoute(pPlanObj);
        this.fetchPlanPrices(petIndex);
        this.handleCancel();
        console.log('Success:', pPlanObj, pet);
    }

    onPetFormFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    WhatWeCoverMenu = () => {
        return (
            <Menu className="dropdown-link">
                <Menu.Item key="1">1st menu item</Menu.Item>
                <Menu.Item key="2">2nd menu item</Menu.Item>
                <Menu.Item key="3">3rd menu item</Menu.Item>
                <Menu.Item key="4">a danger item</Menu.Item>
            </Menu>
        )
    }

    checkType(petType) {
        let img = ''
        this.props.petTypeBreeds.map(type => {
            if (type.id == petType) {
                if (type.name == 'Dog') img = "/images/icon-dog-sm.png"
                else img = "/images/icon-cat-sm.png"
            }
        })
        return img;
    }

    editPetClick(event, objIndex) {
        event.stopPropagation();
        let pPlanObj = this.props.preQoute.petsWithPlans;
        console.log(pPlanObj[objIndex]);
        // console.log(moment(pPlanObj[objIndex].age).format('mm/yyyy'));
        let editObj = {
            name: pPlanObj[objIndex].name,
            type: pPlanObj[objIndex].type,
            gender: pPlanObj[objIndex].gender,
            age: moment(pPlanObj[objIndex].age),
            breed: pPlanObj[objIndex].breed,
            promo: pPlanObj[objIndex].promo,
        }
        this.setState({ edit: true, visible: true, selectedPetIndex: objIndex, type: pPlanObj[objIndex].type }, () => {

            this.formRef.current.setFieldsValue({ ...editObj });
        });
    }

    removePet = (removePet) => {
        let pPlanObj = this.props.preQoute;
        pPlanObj.petsWithPlans.splice(removePet, 1);
        console.log(pPlanObj, 'remove');
        this.setState({ preQoute: pPlanObj });
        this.props.populateQoute(pPlanObj);
    }

    ageChange = () => {
        this.setState({ disableBtn: false }, () => {



            let selectedDate = this.formRef.current.getFieldValue('age');
            let type = this.state.type;
            let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
            let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
            console.log('age different parameter', ageMthDiff, ageYrDiff, type);
            if (ageMthDiff < 2) {
                let dateCopy = moment(selectedDate);
                let nextDate = dateCopy.add(2, 'M');
                console.log(ageMthDiff, nextDate);
                let ageMsg = 'At Oslo we love puppies and kitten, the effective date of your insurance will be 15th of ' + nextDate.format('MM') + '/' + nextDate.format('Y') + ' , for more info on age requirements please check our FAQ'
                notifications(ageMsg, 'Alert', 'warning')
            }
            
            if (ageMthDiff > 96 && (type === 'Dog' || type === '4bed113e-bf4f-4a64-ba64-5fc0ab563a05')) {
                let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            } else if (ageMthDiff > 120 && type === 'Cat' || type === '81d11a99-8756-4dc7-828d-e1d190a2ad06') {
                let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            }
        });
    }

    checkVisible = (event) => {
        event.stopPropagation();
    }

    cardHeaderInfo = (pp, objIndex) => {
        // console.log('collapse', this.state.plans, planId );
        return (

            <Row gutter={15} align="middle">
                <Col xs={24} sm={14} md={14} lg={14} xl={17}>
                    <div className="pet-name">
                        <Image preview={false}
                            className="img-fluid"
                            src={this.checkType(pp.type)} />
                        <span className="name-text">{pp.name}</span>
                        {(!this.props.renewPolicy) && <Image preview={false}
                            className="img-fluid"
                            src="/images/icon-pencil.png"
                            onClick={(event) => this.editPetClick(event, objIndex)} />}

                        {this.state.preQoute && this.state.preQoute.petsWithPlans.length > 1 &&
                            <>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="Are you sure you want to remove this pet"
                                    onConfirm={() => this.removePet(objIndex)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        style={{ verticalAlign: 'baseline' }}
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={(e) => { this.checkVisible(e) }}
                                    />
                                </Popconfirm>
                            </>
                        }

                        {
                            (!!objIndex || this.state.userAlreadyExists || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)) && <>
                            <Divider type="vertical" />
                            <Popover content={"Hurray! You are getting a discount"} title=""><Badge style={{ marginTop: '-10px' }} count={'-5%'} /></Popover>
                            
                            </>
                        }

                    </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={7}>
                    <div className="recomended-plan full-width">
                        {<div className="text-muted text-line">Recommended plan</div>}
                        <div class="plan-price position-relative">
                            {/* <Button className="btn-category btn-blue rounded-100">{this.state.plans ? this.state.plans[pp.planId].title : ''}</Button> */}
                            <Button className="btn-category btn-blue rounded-100">{this.state.plans ? this.state.plans[1].title : ''}</Button>
                            {pp.plans && <Fragment> <sub>{CURRENCY}</sub> <span className="price-amount"> {Math.ceil(((pp.plans[1] / 12) * 3.67) * ((!!objIndex || this.state.userAlreadyExists || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)) ? 0.95 : 1))}</span></Fragment>}
                            <div class="payment-period">Monthly</div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }

    showModal = () => {
        (this.formRef && this.formRef.current) ? this.formRef.current.resetFields() : console.log("empty")
        console.log("this", this.formRef)
        this.setState({
            visible: true,
            edit: false
        });
    }

    handleCancel = e => {
        this.setState({
            visible: false,
            edit: false,
            selectedPetIndex: 0
        });
    }

    render() {
        console.log('render', this.state.preQoute.petsWithPlans);

        return (
            <div className="full-width mb-4" ref={this.pageRef}>
                <Fragment>
                    {
                        this.state.preQoute && this.state.preQoute?.petsWithPlans?.map((pp, objIndex) => (
                            <Fragment>
                                <Title level={2} className="text-green section-title text-center mt-5 mb-4">Let’s customize {pp.name}’s plan</Title>
                                <Card className="card-customize-plan" key={objIndex} title={this.cardHeaderInfo(pp, objIndex)}>
                                    <Spin spinning={this.state.planDivSpinning} size="medium">
                                        <Row gutter={[15, 15]} align="middle" justify="center">
                                            {
                                                pp.plans && this.state.plans.map((obj, index) => (
                                                    <Col key={index} className="pointer" xs={24} sm={24} md={8} lg={8} xl={8} onClick={() => this.changePlan(index, objIndex)}>
                                                        <Card className={index === pp.planId ? 'selected plan-box' : 'plan-box'}>
                                                            {/* {(index === pp.planId ? true : false) && <div className="text-white">{obj.title.toUpperCase()}</div>} */}
                                                            <Button className="btn-category btn-blue rounded-100">{obj.title}</Button>
                                                            <div className="plan-tagline mt-4" style={{ fontSize: '18px' }}>AED {(BenifitsMap[obj.annualy].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} Annual Benefits</div>
                                                            <div className="plan-price text-center mt-4">
                                                                {/* <sup>{CURRENCY}</sup> {obj.monthly.toString().split(".")[0]} <sup>{obj.monthly.toString().split(".")[1]}</sup> */}
                                                                <sub>{CURRENCY}</sub> <span className="price-amount">{Math.ceil(((pp.plans[index] / 12) * 3.67) * ((!!objIndex || this.state.userAlreadyExists || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)) ? 0.95 : 1))}</span>
                                                                <div className="full-width">Monthly</div>
                                                            </div>
                                                            <div className="get-quote full-width mt-3">
                                                                <figure><i className="fa fa-check"></i></figure>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))
                                            }

                                            {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Card className="plan-box selected">
                                                    <div className="text-white">PREFERRED</div>
                                                    <Button className="btn-blue rounded-100">
                                                        <Link to={"/get-a-quote"}>FAVORITE</Link>
                                                    </Button>
                                                    <div className="plan-price mt-2 mb-2">
                                                        <sup>{CURRENCY}</sup> 60 <sup>.97</sup>
                                                    </div>
                                                    <div className="plan-tagline">MONTHLY</div>
                                                    <div className="illness-type mt-2">10K <br /> ANNUAL BENEFITS</div>
                                                    <div className="get-quote mt-3">
                                                        <Row gutter={15} align="middle">
                                                            <Col sm={24} md={24} lg={24} xl={24}>
                                                                <figure><i className="fa fa-check"></i></figure>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Card className="plan-box">
                                                    <Button className="btn-blue rounded-100">
                                                        <Link to={"/get-a-quote"}>OPTIMUM</Link>
                                                    </Button>
                                                    <div className="plan-price mt-2 mb-2">
                                                        <sup>{CURRENCY}</sup> 63 <sup>.31</sup>
                                                    </div>
                                                    <div className="plan-tagline">MONTHLY</div>
                                                    <div className="illness-type mt-2">20K <br /> ANNUAL BENEFITS</div>
                                                    <div className="get-quote mt-3">
                                                        <Row gutter={15} align="middle">
                                                            <Col sm={24} md={24} lg={24} xl={24}>
                                                                <figure><i className="fa fa-check"></i></figure>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>
                                            */}
                                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className="dropdown-link text-center mt-3">
                                                    <Dropdown overlay={this.WhatWeCoverMenu}>
                                                        <a className="ant-dropdown-link text-light-green" onClick={e => e.preventDefault()}>
                                                            What We Cover <DownOutlined />
                                                        </a>
                                                    </Dropdown>
                                                </div>
                                            </Col> */}
                                        </Row>
                                    </Spin>
                                    <Row gutter={[15, 15]} align="middle" className="mb-5">
                                        <Col xs={24} sm={11} md={11} lg={11} xl={11} className="text-center mt-4">
                                            <Title level={5} className="text-muted mb-4">REIMBURSEMENT</Title>
                                            <div className="steper">
                                                {Reimburse.map((obj, index) => (
                                                    <div key={index} className="pointer step-inner" onClick={() => this.changeReimburse(index, objIndex)}>
                                                        <div className={index === pp.reimburseId ? 'step-dot step-slected' : 'step-dot'}></div>
                                                        <strong>{obj.reimbursment}%</strong>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={2} md={2} lg={2} xl={2} className="text-center mt-4">
                                            <Divider type="vertical" className="border-vtcl-lg" />
                                        </Col>
                                        <Col xs={24} sm={11} md={11} lg={11} xl={11} className="text-center mt-4">
                                            <Title level={5} className="text-muted mb-4">DEDUCTIBLE</Title>
                                            <div className="steper">
                                                {Deduction.map((obj, index) => (
                                                    <div key={index} className="pointer step-inner" onClick={() => this.changeDeduction(index, objIndex)}>
                                                        <div className={index === pp.deductionId ? 'step-dot step-slected' : 'step-dot'}></div>
                                                        <strong>{obj} {CURRENCY}</strong>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={15} justify="center">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={8} className="mt-5 mb-5">
                                            <Sidebar />
                                        </Col>
                                    </Row>

                                    {this.props.preQoute?.petsWithPlans.length < 5 && this.props.preQoute?.petsWithPlans.length == (objIndex + 1) && (!this.props.renewPolicy) &&
                                    <div className="add-pet">
                                        <Button type={"link"} className="text-light-green" onClick={this.showModal}>
                                            <div className="icon"><i className="fa fa-plus-circle"></i></div>
                                            Add A Pet 
                                            
                                        </Button>
                                        <span className="icon-info text-light-green font-600">
                                            <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="left" title={<div className="tooltip-content">Receive 5% discount when adding multiple pets. </div>}>
                                                <i className="fa fa-info-circle"></i>
                                            </Tooltip>
                                        </span>
                                        <Modal
                                            title={<div className="text-dark-green font-600">Add a Pet</div>}
                                            visible={this.state.visible}
                                            footer={false}
                                            closable={false}
                                            onCancel={this.handleCancel}
                                        >
                                            <Form
                                                name="basic"
                                                onFinish={this.addPetForm}
                                                onFinishFailed={this.onPetFormFailed}
                                                size={"large"}
                                                className="customise-form"
                                                ref={this.formRef}
                                            >
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"Pet’s Name *"} />
                                                </Form.Item>

                                                <Row gutter={15} justify="center">
                                                    <Col sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            className="text-center"
                                                            name="type"
                                                        >
                                                            <Spin spinning={this.state.spin}>
                                                                {
                                                                    this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" onChange={this.changeType}>
                                                                        {
                                                                            this.state.petTypeBreeds.map((type, index) =>
                                                                                <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                            )
                                                                        }
                                                                        {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                    </Radio.Group>
                                                                }
                                                            </Spin>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item className="text-center" name="gender"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select gender!',
                                                                },
                                                            ]}>
                                                            <Radio.Group buttonStyle="solid">
                                                                <Radio.Button value="Male">Male</Radio.Button>
                                                                <Radio.Button value="Female">Female</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    name="age"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select DOB (MM/YYYY)*!',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Please select DOB (MM/YYYY)!" format="MM/YYYY" picker="month" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="breed"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select breed!',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select Breed"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {
                                                            this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                <Option key={index} value={breed.id}>{breed.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>

                                                {/* <Form.Item
                                                            name="email"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input your email!',
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: 'Please input correct email!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Email" />
                                                        </Form.Item> */}

                                                {/* <Form.Item name="promo">
                                                    <Input placeholder="Promo Code" />
                                                </Form.Item> */}
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item className="mb-0 text-center">
                                                        <Button className="text-muted btn-150" type="link" onClick={this.handleCancel} size="large">
                                                            Cancel
                                                        </Button>
                                                        <Button disabled={this.state.disableBtn} type="primary" htmlType="submit" size="large" className="btn-outline-light-green btn-150 rounded-100">
                                                            Update
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Form>
                                        </Modal>
                                    </div>}
                                </Card>
                            </Fragment>
                        ))
                    }
                </Fragment>
            </div>
        );
    }
}

export default QuotePage;
