import React, { Component, Fragment } from 'react';
import { Layout, Typography, Button, Row, Col, Card, Table, MonitorOutlined, DatePicker, Form  } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getLeadById, getPolicyByIdForAdmin, SavePolicyDocumentSentStatusByIdForAdmin, SavePetTagSentStatusByIdForAdmin } from '../../api/pets-api';
import { PolicyStatus } from '../../api/constants';
import { notifications, getToken, fixPhoneNumber } from "../../helpers/utilities";
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;


class AdminLeadDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadId:'',
            lead:{},
            leadEmail:'',
            policySerial:'',
            policy:{},
            policyStatus: ''
        };
        this.policyDocFormRef = React.createRef();
        this.petTagFormRef = React.createRef();
    }

    async componentDidMount() {
        
    }

    async componentWillMount(){
        var splittedPath = this.props.location.pathname.split('/');
        console.log(splittedPath[splittedPath.length - 1]);
        let id = splittedPath[splittedPath.length - 1];
        this.setState({leadId:id});
        this.getLead(id);
    }

    async getLead(id){
        let respo = await getLeadById(id);
        debugger;
        if(respo.status == 200){
            let leadResult = respo.data.result;
            let firstPet = leadResult.planObj.petsWithPlans[0];
            this.setState({leadEmail:firstPet.email});
        }
    }

    async getPolicy(id){
        try{
            let respo = await getPolicyByIdForAdmin(id);
            console.log(respo);
            let policy = {};
            if(respo.status == 200){
                policy = respo.data.result[0];
                var stringPolicyCode = '0000000000' + policy.policySerial;
                var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
                policy.policySerialNumber = 'OSLO-'+policy.policyCurrency.toUpperCase()+'-'+policy.policyType+'-'+delimitedSerial
                
                let benefits = policy.benefits == 5000 ? 20000 : policy.benefits == 10000 ? 35000 : 70000
                
                policy.benefits = benefits;

                let status = policy.status == PolicyStatus.submitted ? "Just Purchased" : policy.status == PolicyStatus.policy_doc_sent ? "Policy Document Sent" : policy.status == PolicyStatus.pet_tag_sent ? "Pet Tag Sent" : "";

                var dateOfBirth = moment(policy.dob);
                var dateOfPurchase = moment(policy.createdAt);

                let policyActBasedDate = dateOfPurchase;
                let dateCopy = dateOfPurchase;

                var diff = dateOfPurchase.diff(dateOfBirth, 'months');
                if(diff < 2){
                    policyActBasedDate = (dateOfBirth.add(2, 'M'));
                    dateCopy = (dateOfBirth.add(2, 'M'));
                }

                let activationDate = dateCopy.toDate();
                activationDate.setDate(policyActBasedDate.toDate().getDate() + 6);

                let illnessCoverDate = dateCopy.toDate();
                illnessCoverDate.setDate(policyActBasedDate.toDate().getDate() + 14);

                let kneeCoverDate = dateCopy.toDate();
                kneeCoverDate.setMonth(policyActBasedDate.toDate().getMonth() + 6);

                policy.effectiveDate = activationDate;
                policy.illnessCoverDate = illnessCoverDate;
                policy.kneeCoverDate = kneeCoverDate;

                this.setState({policy:policy, policySerial:policy.policySerialNumber, policyStatus:status});
                
                console.log(policy);
            }else{
                this.setState({policy:{}});
            }
        }catch(ex){
            this.setState({policy:{}});
        }
    }

    savePolicyDocSentDate = async (values) => {
        let data = {
            id:this.state.policy.policyId,
            status:PolicyStatus.policy_doc_sent,
            date:moment(values.documentSentDate).format('YYYY/MM/DD')
        }
        let res = await SavePolicyDocumentSentStatusByIdForAdmin(data);
        if(res.data.result){
            window.location.reload();
        }
    }

    savePetTagSentDate = async (values) => {
        let data = {
            id:this.state.policy.policyId,
            status:PolicyStatus.pet_tag_sent,
            date:moment(values.documentSentDate).format('YYYY/MM/DD')
        }
        let res = await SavePetTagSentStatusByIdForAdmin(data);
        if(res.data.result){
            window.location.reload();
        }
    }

    render() {
        return (
            <Layout className="dashboard claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Lead</Title>
                    <Title level={3} className="text-green font-600 pt-1 ps-3 m-0">{this.state.leadId}</Title>
                    <Title level={4} className="text-red font-600 pt-1 ps-3 m-0">{this.state.leadEmail}</Title>
                </div>
                <div className="m-2">
                    <Row gutter={15}>
                        <Col offset={6} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card title="Lead Details" className='full-width p-2'>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Pet Name</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>Test Name</span>
                                </Col>
                            </Row>
                            {/* <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Pet Type</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.type}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Breed</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.breed}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Date of Birth</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{moment(this.state.policy.dob).format('MMM/YYYY')}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Gender</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.gender}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Microchip No</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.microchip}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Tag</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.tag}</span>
                                </Col>
                            </Row>
                            <Title level={5} className="text-green font-600 pt-1 mt-2">Policy</Title>
                            <hr/>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Plan</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.planTitle}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Benefits</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>AED {this.state.policy.benefits}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Co-Payment</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{this.state.policy.copayment} %</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Deductible</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>AED {this.state.policy.deductable}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className='text-green'>Policy Eff. Date</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <span>{moment(this.state.policy.createdAt).format('YYYY/MMM/DD')}</span>
                                </Col>
                            </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Accident Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.effectiveDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Illness Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.illnessCoverDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Knee Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.kneeCoverDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
                { 
                    this.state.policy.status == PolicyStatus.submitted &&
                    <div className='m-2'>
                        <Row gutter={15}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="end">
                                <Form
                                    name="basic"
                                    onFinish={this.savePolicyDocSentDate}
                                    // onFinishFailed={this.onPetFormFailed}
                                    size={"large"}
                                    ref={this.policyDocFormRef}
                                >
                                    <Form.Item
                                        className='formiteminline'
                                        name="documentSentDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select submitted Date*!',
                                            },
                                        ]}
                                    >
                                        <DatePicker className="mx-2"  placeholder="Please select submitted date!" format="YYYY/MMM/DD" />
                                    </Form.Item>
                                    <Form.Item className='formiteminline'>
                                        <Button className='btn-blue mx-2' type="primary" htmlType="submit">Policy Document Submitted</Button>
                                    </Form.Item>
                                </Form>
                                
                            </Col>
                        </Row>
                    </div>
                }
                {
                    this.state.policy.status == PolicyStatus.policy_doc_sent &&
                    <div className='m-2'>
                        <Row gutter={15}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="end">
                                <Form
                                    name="basic"
                                    onFinish={this.savePetTagSentDate}
                                    // onFinishFailed={this.onPetFormFailed}
                                    size={"large"}
                                    ref={this.petTagFormRef}
                                >
                                    <Form.Item
                                        className='formiteminline'
                                        name="documentSentDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select tag sent Date*!',
                                            },
                                        ]}
                                    >
                                        <DatePicker className="mx-2"  placeholder="Please select tag sent date!" format="YYYY/MMM/DD" />
                                    </Form.Item>
                                    <Form.Item className='formiteminline'>
                                        <Button className='btn-blue mx-2' type="primary" htmlType="submit">Pet Tag Sent</Button>
                                    </Form.Item>
                                </Form>
                                
                            </Col>
                        </Row>
                    </div>
                }
            </Layout>
        );
    }
}

export default AdminLeadDetails;