import React, { Component, Fragment } from 'react';
import { Spin, Image, Typography, Button, Row, Col, Card, Form, Input, Select, Radio, DatePicker, Tooltip } from 'antd';
import MainLayout from '../layouts/mainLayout'
const { Paragraph, Title } = Typography;
const { Option } = Select;
class upload_policy_docs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            petTypeBreeds: [],
            petBreeds: [],
            plans: [],
            spin: true,
            disableBtn: false,
            dogId: '',
            type: '',
            genderBtnType: 'Male',
            petBtnType: 'Dog',
            petBtnClass: 'dog',
        }
        // this.saveQoute = this.saveQoute.bind(this);
    }

    render() {
        return (
            <Fragment>
                <MainLayout>
                <div className="customise-paln bg-gray-light pt-5 pb-5 full-width" >
                        <div className="container">
                            <Title level={2} className="text-green text-center section-title mb-5">Let’s build our new mate’s plan </Title>
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={13}>
                                    <Row gutter={15} align="middle" className="full-width">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                name="basic"
                                                size={"large"}
                                                className="customise-form"
                                            >
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"My Pet’s Name *"} />
                                                </Form.Item>
                                                <Row gutter={15} justify="center">
                                                    {true && <Col sm={12} md={12} lg={12} xl={12}>

                                                        <Spin spinning={false}>
                                                            <Form.Item
                                                                className="text-center"
                                                                name="type"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select type! *',
                                                                    },
                                                                ]}
                                                                initialValue={this.state.dogId}
                                                                onChange={this.onTypeChange}
                                                            >

                                                                {
                                                                    this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.petBtnClass} onChange={this.changeType}>
                                                                        {
                                                                            this.state.petTypeBreeds.map((type, index) =>
                                                                                <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                            )
                                                                        }
                                                                        {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                        <span className="switch-selection"></span>
                                                                    </Radio.Group>
                                                                }
                                                            </Form.Item>
                                                        </Spin>
                                                    </Col>}
                                                    <Col sm={12} md={12} lg={12} xl={12} className={this.state.genderBtnType}>
                                                        {/* value='Male' initialValue={'Male'} */}
                                                        <Form.Item className="text-center" name="gender"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select gender! *',
                                                                },
                                                            ]}
                                                            initialValue={"Male"}
                                                            onChange={this.onGenderChange}>
                                                            <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.genderBtnType.toLowerCase()}>
                                                                <Radio.Button value="Male">Male</Radio.Button>
                                                                <Radio.Button value="Female">Female</Radio.Button>
                                                                <span className="switch-selection"></span>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    name="age"
                                                    size={"large"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Select age! *',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Select age! *" format="MM/YYYY" picker="month" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="breed"
                                                    size={"large"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select breed! *',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select breed *"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {
                                                            this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                <Option key={index} value={breed.id}>{breed.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your email! *',
                                                        },
                                                        {
                                                            type: 'email',
                                                            message: 'Please input correct email!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Email *" />
                                                </Form.Item>

                                                {/* <Form.Item
                                                    name="promo"
                                                >
                                                    <Input placeholder="Promo Code" />
                                                </Form.Item> */}
                                                <Form.Item className="text-center mt-5">
                                                    <Button disabled={this.state.disableBtn} type="primary" className="btn-red rounded-100" htmlType="submit">Get Quote</Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section contact-us">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <Title level={2} className="text-green text-center mb-5 section-title">Contact us the way you want</Title>
                                    <Row gutter={[15, 15]} align="middle" justify="center">
                                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/icon-contact-phone.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">Phone</Paragraph>
                                                <Button className="text-light-green" type="link" href="tel:+971 4 248 0561 ">+971 4 248 0561 </Button>
                                            </div>
                                        </Col>
                                        {/* <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/icon-contact-whatsapp.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">WhatsApp</Paragraph>
                                                <Button className="text-light-green" type="link" href="tel:+971 527 77 77 77 ">+971 527 77 77 77 </Button>
                                            </div>
                                        </Col> */}
                                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/icon-email.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">Email</Paragraph>
                                                <Button className="text-light-green" type="link" href="mailto:support@osloinsurance.pet">support@osloinsurance.pet</Button>
                                            </div>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="contact-text-block">
                                                <Title level={3} className="text-green font-600 text-center mb-2">Customer Experience </Title>
                                                <Paragraph className="text-muted text-center mb-0">
                                                    Monday to Friday 8am to 5pm <br />
                                                    {/* Friday 11am-3pm (WhatsApp only) <br /> */}
                                                    Saturday & Sunday email us at support@osloinsurance.pet
                                                </Paragraph>
                                            </div>
                                        </Col>
                                        {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <div className="contact-text-block">
                                                <Title level={3} className="text-green font-600 text-center mb-2">Claims Support <small>(Central time)</small></Title>
                                                <Paragraph className="text-muted text-center mb-0">
                                                    Monday to Friday <br />
                                                    8.30am-5.30pm
                                                </Paragraph>
                                            </div>
                                        </Col> */}
                                    </Row>
                                    {/* <Title level={2} className="text-green text-center mb-2 section-title">Have a media enquiry?</Title>
                                    <Title level={2} className="text-green text-center mt-0 font-300"><span className="text-muted">Email us at</span> <a href="mailto:media@osloinsurance.pet" className="text-light-green">media@osloinsurance.pet.</a></Title> */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </MainLayout>
            </Fragment>
        );
    }
}

export default upload_policy_docs;