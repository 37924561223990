import moment from "moment"
import { TOKEN_COOKIE, PATH_COOKIE, USER_COOKIE, COOKIE_EXPIRE } from '../api/constants';
import cookie from 'react-cookies'

import { message, notification } from 'antd';

export const popupMessages = (text = "Success", type = "success", duration = 5) => {
    if(type == 'error') message.error({ content: text, duration: duration })
    else if(type == 'warning') message.warning({ content: text, duration: duration });
    else if(type == 'loading') message.loading({ content: text, duration: duration });
    else message.success({ content: text, duration: duration })
}

// Helper function to convert a string to title case
export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Helper function to convert a string to paragraph case (first letter capitalized)
export const toParagraphCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const notifications = (text = "Success", title = "Notification", type = "success", duration = 5) => {
    const notificationTypes = ["success", "error", "info", "warning"];
    
    // Validate type or fallback to "info"
    const notificationType = notificationTypes.includes(type) ? type : "info";

    notification[notificationType]({
        message: toTitleCase(title),
        duration: duration,
        description: toParagraphCase(text)
    });
}

export const setUser = (data) => {
    if (data !== undefined) {
        cookie.save(USER_COOKIE, data, { path: PATH_COOKIE, expires: moment().add(COOKIE_EXPIRE, 's')._d })
    }
}
export const getUser = () => {
    return cookie.load(USER_COOKIE)
}
export const setToken = (data) => {
    if (data !== undefined) {
        cookie.save(TOKEN_COOKIE, data, { path: PATH_COOKIE, expires: moment().add(COOKIE_EXPIRE, 's')._d })
        // init()
    }
}
export const getToken = () => {
    return cookie.load(TOKEN_COOKIE)
}
export const logout = () => {
    cookie.remove(TOKEN_COOKIE, { path: PATH_COOKIE })
    cookie.remove(USER_COOKIE, { path: PATH_COOKIE })
    window.location.pathname = '/login'
    // init()
}
export const upperCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}
export const convertAED = (val) => {
    return Number(parseFloat(val * 3.67).toFixed(2))
}
export const applyDiscountVat = (val,dis,flag) => {
    let value,discount;
    discount = (val*dis)/100;
    value = val - Number(parseFloat(discount).toFixed(2));
    return flag==='discount' ? Number(parseFloat(discount).toFixed(2)) : Number(parseFloat(value).toFixed(2));
}
export const calculateAge = (dob) => {
    dob = new Date(dob);
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const fixPhoneNumber = (strNumber) => {

    if(!strNumber) {
        return '';
    } else if(strNumber.startsWith('+')) {
        return strNumber;
    } else if(strNumber.startsWith('971')) {
        return '+'+strNumber;
    } else if(strNumber.startsWith('00')) {
        return '+'+strNumber.substring(2);
    } else if(strNumber.startsWith('0')) {
        return '+971'+strNumber.substring(1);
    } else{
        return '+971'+strNumber;
    }
}