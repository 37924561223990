import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { popupMessages } from "./utilities";

export default ({ component: C, authFlag, returnUrl}) =>{
    useEffect(() => {
        if(authFlag.isAuthenticated) popupMessages("Authenticated","success", 1.5)
    });
    return(
    <Route
        exact
        render={props =>
            !authFlag.isAuthenticated
                ? <C {...props} {...authFlag} />
                : <Redirect to={returnUrl} />}
    />)
}