import React, { Component, Fragment } from 'react';
import { Layout, Typography, Button, Row, Col, Card, Table, MonitorOutlined  } from 'antd';
import { SearchOutlined, CheckCircleFilled } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getPoliciesForAdmin } from '../../api/pets-api';
import { PolicyStatus } from '../../api/constants';
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;


class AdminPolicies extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            userPets:[],
            policies:[],
            claims:[],
            progress:[],
            completed:[],
            selectedPetId: 0,
            currentBenefits: 0,
            currentRemainingBenefits: 0,
            currentDeductable: 0,
            currentRemainingDeductable: 0,
            tabPosition: 'left',
            setTabPosition: 'left' ,
            tableTitle: 'Purchased Policies',
            selectedButton: 1
        };
    }
    
    getPolicies = async (status, title, button) => {
        if(!button)
            button = 1;
        try{
            let respo = await getPoliciesForAdmin(status);
            console.log(respo);
            let polics = [];
            if(respo.status == 200){
                polics = respo.data.result;
                let polics2 = polics.map((itm) => {
                    var stringPolicyCode = '0000000000' + itm.policySerial;
                    var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
                    itm.policySerialNumber = 'OSLO-'+itm.policyCurrency.toUpperCase()+'-'+itm.policyType+'-'+delimitedSerial


                    return itm;
                })
                this.setState({policies:polics2, tableTitle:title, selectedButton:button});
                console.log(polics2);
            }else{
                this.setState({policies:[], tableTitle:title, selectedButton:button});
            }
        }catch(ex){
            this.setState({policies:[], tableTitle:title, selectedButton:button});
        }
        
    }

    async componentDidMount() {
        await this.getPolicies(PolicyStatus.submitted, 'Purchased Policies');
    }

    navigateToPolicy(policyNumber){
        console.log('Policy Number', policyNumber);
        window.location.pathname = '/admin_policies/'+policyNumber
    }


    render() {

        const columns = [
            {
                title: 'Policy',
                dataIndex: 'policySerialNumber',
                render: (policySerialNumber) => <Fragment key={1}>
                    
                    <Paragraph className="text-muted p-0 mb-0"> {policySerialNumber} </Paragraph>
                </Fragment>
            },
            {
                title: 'Pet Name',
                dataIndex: 'name',
                render: name => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{name}</Paragraph>
                </Fragment>
            },
            {
                title: 'DOB',
                dataIndex: 'dob',
                render: dob => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{moment(dob).format('MM/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Policy Eff. Date',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{moment(createdAt).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Policy Exp. Date',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{moment(createdAt).add(1, 'y').format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Policy Doc Sent',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <CheckCircleFilled />
                </Fragment>
            },
            {
                title: 'Pet Tag Sent',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <CheckCircleFilled />
                </Fragment>
            },
            {
                title: 'View More',
                dataIndex: 'policyId',
                render: (policyId) => <Fragment key={2}>
                    <Button htmlType="button" type="primary" shape="circle" onClick={() => this.navigateToPolicy(policyId)}  >
                        <SearchOutlined  />
                    </Button>
                </Fragment>
            },
        ];

        return (
            <Layout className="dashboard claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Policies</Title>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button htmlType="button" className={this.state.selectedButton == 1 ? "full-width btn-category btn-blue" : "full-width btn-category"} size='large' onClick={(btn) => this.getPolicies(PolicyStatus.submitted, 'Purchased Policies', 1)} >Purchased Policies</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 2 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.getPolicies(PolicyStatus.policy_doc_sent, 'Cancelled Policies', 2)} >Cancelled Policies</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 4 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.getPolicies(PolicyStatus.expired, 'Expired Policies', 4)} >Expired Policies</Button>
                        </Col>
                        <Col className='pt-3 px-3' xs={18} sm={18} md={18} lg={18} xl={18}>
                            <Card className="custom-shadow green" title={<Title level={4} className="text-center text-white">{this.state.tableTitle}</Title>}>
                                <Table className="noborder" columns={columns} dataSource={this.state.policies} pagination={false} size="small" />
                            </Card>
                        </Col>
                    </Row>
                </div>
                
                
            </Layout>
        );
    }
}

export default AdminPolicies;