import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({ component: C, authFlag }) {
    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
    });
    return (
        <Route
            exact
            render={props =>
                authFlag.isAuthenticated
                    ? <C {...props} {...authFlag} />
                    : <Redirect
                        to={`/login`}
                    />}
        />
    );
}