import React, { Component } from 'react';
import { Typography, Card, Row, Col, Button, Input } from 'antd';


const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const onChange = (e) => {
    console.log('Change:', e.target.value);
}

class PetAuthonized extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yesBtn: 'btn-red-outline',
            noBtn: 'btn-red-outline',
            secondYesBtn: 'btn-red-outline',
            secondNoBtn: 'btn-red-outline',
            suggestDisable: true,
            buttonDisable: true,
            isSuggestedByVet: null,
            isEuthanized: null,
        }
    }

    componentDidMount(){
        // this.props.populateClaimObj('isEuthanized',true)
        // this.props.populateClaimObj('isSuggestedByVet',false)
        this.props.claimObj.isEuthanized ?
        this.setState({yesBtn: 'btn-red', noBtn: 'btn-red-outline', secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red-outline', suggestDisable: false, buttonDisable: true, isEuthanized: true})
        :
        this.setState({yesBtn: 'btn-red-outline', noBtn: 'btn-red', secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red-outline', suggestDisable: true, buttonDisable: false, isEuthanized: false})
        
        this.props.claimObj.isSuggestedByVet ?
        this.setState({secondYesBtn: 'btn-red', secondNoBtn: 'btn-red-outline', buttonDisable: false})
        :
        this.setState({secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red', buttonDisable: false})
    
    }

    onYesClicked = () =>{
        this.props.populateClaimObj('isEuthanized',true)
        this.setState({yesBtn: 'btn-red', noBtn: 'btn-red-outline', secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red-outline', suggestDisable: false, buttonDisable: true, isEuthanized: true});
    }
    
    onNoClicked = () =>{
        this.props.populateClaimObj('isEuthanized',false)
        this.props.populateClaimObj('isSuggestedByVet',false)
        this.setState({yesBtn: 'btn-red-outline', noBtn: 'btn-red', secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red-outline', suggestDisable: true, buttonDisable: false, isEuthanized: false});
    }

    onSecondYesClicked = () =>{
        this.props.populateClaimObj('isSuggestedByVet',true)
        this.setState({secondYesBtn: 'btn-red', secondNoBtn: 'btn-red-outline', buttonDisable: false});
    }
    
    onSecondNoClicked = () =>{
        this.props.populateClaimObj('isSuggestedByVet',false)
        this.setState({secondYesBtn: 'btn-red-outline', secondNoBtn: 'btn-red', buttonDisable: false});
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">Was your pet euthanized?</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[40, 20]} justify="center">
                                <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                    <Button onClick={this.onYesClicked} block size="large" className={`${this.state.yesBtn} font-800 border-radius-10`}>
                                        Yes
                                    </Button>
                                </Col>
                                <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                    <Button onClick={this.onNoClicked} block size="large" className={`${this.state.noBtn} font-800 border-radius-10`}>
                                        No
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                
                { 
                    !this.state.suggestDisable && 
                    <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                        <Title level={3} className="text-green font-700 mb-5">If yes, was it suggested by your vet?</Title>
                        <Row gutter={[15, 50]} justify="center">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row gutter={[40, 20]} justify="center">
                                    <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                        <Button onClick={this.onSecondYesClicked} block size="large" className={`${this.state.secondYesBtn} font-800 border-radius-10`}>
                                            Yes
                                        </Button>
                                    </Col>
                                    <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                        <Button onClick={this.onSecondNoClicked} block size="large" className={`${this.state.secondNoBtn} font-800 border-radius-10`}>
                                            No
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            { 
                                !this.state.buttonDisable &&
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Row gutter={[15, 50]} justify="center">
                                            <Col xl={2} lg={4} md={3} sm={3}>
                                                <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('payment')}>
                                                    <i className="fa fa-angle-up"></i>
                                                </Button>
                                            </Col>
                                            <Col xl={2} lg={4} md={3} sm={3}>
                                                <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('payment')}>
                                                    <i className="fa fa-angle-down"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                            }   
                        </Row>
                    </Col>
                      
                }  
                { 
                    this.state.suggestDisable && 
                    <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                        <Row gutter={[15, 50]} justify="center">
                            { 
                                !this.state.buttonDisable &&
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Row gutter={[15, 50]} justify="center">
                                            <Col xl={2} lg={4} md={3} sm={3}>
                                                <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('payment')}>
                                                    <i className="fa fa-angle-up"></i>
                                                </Button>
                                            </Col>
                                            <Col xl={2} lg={4} md={3} sm={3}>
                                                <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('payment')}>
                                                    <i className="fa fa-angle-down"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                            }   
                        </Row>
                    </Col>
                      
                }  
                {/* <Col xl={7} lg={7} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                    </Card>
                </Col> */}
            </Row>
        );
    }
}

export default PetAuthonized;