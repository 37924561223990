import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Radio, Table, Progress, Tooltip } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import MainLayout from '../../layouts/mainLayout'
import MainPage from '../getQoutes/mainPage'
import Index from '../getQoutes/index';
import { getPetBreeds, getPlans, getPaymentStatus, createQoute, createWithoutPayment, updateLead, getLead } from '../../api/pets-api';
import { notifications, upperCase, applyDiscountVat, convertAED } from '../../helpers/utilities';
import { ALLOWED_EXTENSION, Reimburse, Deduction, Countries, CURRENCY, BenifitsMap } from '../../constants';
import { Spin } from 'antd';

class AddNewPetFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qouteObj: {},
            finalQoute: {},
            showFlag: 'main',
            petTypeBreeds: [],
            plans: [],
            paymentSuccess: false,
            paymentFailed: false,
            policyNumber: '',
            spinning: false,
            tip: '',
            currentLeadId:'',
            qouteRef : null
        };
        this.populateQoute = this.populateQoute.bind(this);
        this.changeFlag = this.changeFlag.bind(this);
    }

    
    setQouteRef = (qouteRef) =>{
        this.setState({qouteRef})
    }
    populateQoute(obj){
        this.setState({qouteObj:{...this.state.qouteObj, ...obj}})
        console.log('quoteObj', this.state.qouteObj);
        localStorage.setItem('quoteObj', JSON.stringify(this.state.qouteObj));
        
    }
    changeFlag(val){
        this.setState({showFlag:val})
        console.log(this.state.qouteObj);        
    }
    setFinalQoute = (qoute) => {
        this.setState({finalQoute:qoute});      
    }
    getFinalQoute = () => {
        return this.state.finalQoute;      
    }
    setCurrentLeadId = (leadId) => {
        this.setState({currentLeadId:leadId});   
    }

    async componentWillMount(){
        this.setState({ spinning: true, tip: "Loading..." });
        let resp = await getPetBreeds();
        let petTypeBreeds = resp.data.result;
        this.setState({petTypeBreeds})
        let plans = await getPlans();
        this.setState({plans: plans.data.result});
        this.setState({ spinning: false, tip: "" });
        if(this.props.location.pathname === '/payement_verification'){
            var retrievedObject = localStorage.getItem('quoteObj');
            if(retrievedObject){
                this.setState({qouteObj:JSON.parse(retrievedObject)});
                this.setState({ spinning: true, tip: "Processing Quote for You..." });
                const queryParams = new URLSearchParams(this.props.location.search);
                const ref = queryParams.get("ref")
                let res = await getPaymentStatus(ref);
                let paymentStatus = res.data.result.state;
                
                let quote = JSON.parse(localStorage.getItem('userData'))
                var pet = this.state.qouteObj.petsWithPlans[0];

                var policyObj = {
                    planId: plans.data.result[pet.planId].id,
                    policyCurrency: plans.data.result[pet.planId].currency,
                    policyType: "H01",
                    deductableValue: Deduction[pet.deductionId],
                    copaymentValue: Reimburse[pet.reimburseId].reimbursment,
                    policyValue: Math.ceil((pet.plans[pet.planId]) * 3.67),
                    totalPaid: (convertAED(pet.plans[pet.planId])+ 50 + applyDiscountVat((convertAED(pet.plans[pet.planId])+50), 5, 'discount'))
                }
                console.log("policyObjBeforeSending", policyObj);
                let sum = 0;
                var petPolicies = this.state.qouteObj.petsWithPlans.map((obj, index) => {
                    let planVal = Number(parseFloat(obj.plans[obj.planId] * 3.67).toFixed(2));
                    let discount = Number(parseFloat(index ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
                    let vat = Number(parseFloat(index ? (applyDiscountVat(planVal, 5) + 50) * 0.05 : (planVal + 50) * 0.05).toFixed(2));
                    let disValue = Number(parseFloat(index ? ((applyDiscountVat(planVal, 5) + 50) + vat) : (planVal + 50 + vat)).toFixed(2))
                    sum = sum + disValue;
                    let policyActualBenefits = plans.data.result[obj.planId].annualy == 5000 ? 20000 : plans.data.result[obj.planId].annualy == 10000 ? 35000 : 70000;
                    return {
                        planId: plans.data.result[obj.planId].id,
                        policyCurrency: plans.data.result[obj.planId].currency,
                        policyType: "H01",
                        deductableValue: Deduction[obj.deductionId],
                        copaymentValue: Reimburse[obj.reimburseId].reimbursment,
                        policyValue: planVal,
                        discount: parseFloat(discount).toFixed(2),
                        policyCost: parseFloat(50).toFixed(2),
                        vat: parseFloat(vat).toFixed(2),
                        totalPaid: disValue,
                        ActualValueOfPolicy: policyActualBenefits
                    }
                })

                if(quote){
                    if(paymentStatus === 'PURCHASED' || paymentStatus === 'CAPTURED'){
                        this.setState({ spinning: true, tip: "Saving Quote for You..." });
                        quote.policy = policyObj;
                        quote.policies = petPolicies;
                        quote.paymentRef = ref;
                        quote.total = sum;
                        this.setState({paymentSuccess:true});
                        let res = await createQoute(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                            
                        } else {
                            this.setState({policyNumber:res.data.result.policyCode});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });
                    }
                    else{
                        notifications('Error', 'Payement Failed. Please try again.', 'error');
                        let obj = {
                            email:JSON.parse(retrievedObject).petsWithPlans[0].email,
                            planObj:retrievedObject,
                            userObj:quote
                        }
                        let rest = await updateLead(obj);
                        let res = await createWithoutPayment(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                        } else {
                            notifications('Success', 'Your information saved without activation. Please proceed with the payement and activate your account', 'success');
                            this.setState({paymentFailed:true});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });
                    }
                }
                this.setState({ spinning: false });
            }
        } else if (this.props.location.pathname === '/email_link'){
            // console.log('email submitted', this.props.location.query.email);
            const queryParams = new URLSearchParams(this.props.location.search);
            let email = queryParams.get("email");
            email = email.replace(' ','+');
            this.setState({ spinning: true, tip: "Processing Quote for You..." });
            let userDetails = await getLead(email);
            if (userDetails.data.errors) {
                userDetails.data.errors.map(error => {
                    notifications('Error', error.msg, 'error');
                })
            } else {
                if(userDetails.data.status == 'ok'){
                    let planObj = userDetails.data.result.planObj;
                    this.setState({qouteObj:JSON.parse(planObj)});
                    let userObj = userDetails.data.result.userObj;
                    localStorage.setItem('userData', JSON.stringify(userObj));
                    localStorage.setItem('quoteObj', planObj);
                    this.setState({paymentFailed:true});
                    this.changeFlag('qoute');
                }
                //this.changeFlag('qoute');
            }
            this.setState({ spinning: false, tip: "" });
        }
        
    }

    render() {
        return (
            <Layout className="dashboard middle claims">
                <LoginHeader />
                
                    { this.state.showFlag === 'main' && this.state.petTypeBreeds && <MainPage plans={this.state.plans} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} spinning={this.state.spinning} tip={this.state.tip} setQouteRef={this.setQouteRef} setLeadId={this.setCurrentLeadId} addNewPet={true}  {...this.props}/>}
                    { this.state.showFlag === 'qoute' && <Index plans={this.state.plans} setFinalQoute={this.setFinalQoute} getFinalQoute={this.getFinalQoute} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} payementSuccess={this.state.paymentSuccess} paymentFailed={this.state.paymentFailed} setQouteRef={this.setQouteRef} hideUserInfo={true} addNewPet={true} policyNumber={this.state.policyNumber}/>}
                
                    <LoginFooter />
            </Layout>
        );
    }
}

export default AddNewPetFlow;