import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Radio, Table, Progress, Steps, Button, message } from 'antd';


const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { Step } = Steps;


class StartOfClaim extends Component {
    constructor(props) {
        super(props);

    }



    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-3">A few steps is all we need from you!</Title>
                    <Title level={4} className="text-green mt-0 mb-5">Please have your vet bill and medical documents <br /> handy to send us a pic.</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="text-center">
                            <img src="/images/prescription-bill-icon.png" />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={7} lg={12} md={7} sm={9} xs={24}>
                                    <Button block size="large" className="btn-red border-radius-10 rolover-white" onClick={() => this.props.actionNext()}>
                                        Start
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">That’s the way to go!</span>
                    </Card>

                </Col>
            </Row>
        );
    }
}

export default StartOfClaim;