import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
// import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import Header from './header';
import LeftMenu from './left_menu'
import { Link } from "react-router-dom";
import { Layout, Typography, Card, Row, Col, Button } from 'antd';
import Map from '../getQoutes/map';
import LoginHeader from '../../components/common/loginHeader';
const { Paragraph, Title } = Typography;

// const Map = ReactMapboxGl({
//     accessToken: process.env.REACT_APP_MAP_BOX_KEY
//   });
Geocode.setApiKey("AIzaSyClUQeIMmxUke2X7jkwaBptg4k52-Ac0JE");
Geocode.enableDebug();

const { Content } = Layout;

class Nearme extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lat:'',
            lng:''
        };
    }

    componentDidMount = () => {
        
        navigator.geolocation.getCurrentPosition((position) => {

            this.setState({lat: position.coords.latitude, lng: position.coords.longitude})
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
          });
    }
    render() {
        let {lng, lat} = this.state;
        return (
            <Layout className="dashboard nearme">
                {/* <Header /> */}
                <LoginHeader></LoginHeader>
                <Layout>
                    <LeftMenu />
                    <Content className="p-3 bg-gray-light">
                        <Card className="card-content rounded-5" extra={<img src="/images/icon-map.png" />} title={<Title level={4} className="text-dark-green">Near Me</Title>}>
                            <Row gutter={[40, 15]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                 {/* <Map
                                    style="mapbox://styles/mapbox/streets-v9"
                                    containerStyle={{
                                        height: '40vh'
                                    }}
                                    center={[lng, lat ]}
                                    zoom={[12]}
                                    > */}
                                    {/* <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}> */}
                                        {/* <Marker coordinates={[25.2048, 55.296249 ]} /> */}
                                    {/* </Layer> */}
                                {/* </Map>  */}

                                    <Map
                                        google={this.props.google}
                                        center={{lat: 25.276987, lng: 55.296249}}
                                        height='300px'
                                        zoom={15}
                                        autoComplete={false}
                                    />
                                    
                                    {/* <img src="/images/banner-map.png" /> */}

                                    <div className="contact_info">
                                        <Title level={5}>Umm Suqeim Veterinar</Title>
                                        <Paragraph className="text-muted">1140 Al Wasl Rd - Umm Suqeim <br /> Umm Suqeim 2 - Dubai</Paragraph>
                                        <Button className="text-light-green p-0" href="tel:+97143483799" type="link"><i className="fa fa-phone"></i>  <span className="text-muted">+97143483799</span></Button><br />
                                        <Button className="text-light-green p-0" href="https://www.usvetcentre.com/" type="link">https://www.usvetcentre.com/</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Nearme;