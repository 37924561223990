import React, { Component, Fragment } from 'react';
import { Typography, Button, Row, Col } from 'antd';
import MainLayout from '../layouts/mainLayout'
const { Paragraph, Title } = Typography;

class contact_us extends Component {
    render() {
        return (
            <Fragment>
                <MainLayout>
                    <div className="banner pets-cloud">
                        <img
                            className="img-fluid w-100"
                            src="/images/banner-contact-us.jpg"
                        />
                        <div className="banner-caption">
                            <div className="container">
                                <Row gutter={15}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                        <div className="text-center">
                                            <Title level={1} className="text-light-green tagline">Contact us</Title>
                                            <Title level={3} className="text-white mt-0 mb-5 sub-tagline">Don’t be shy,  <br /> we love hearing from you</Title>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="full-width-section contact-us">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <Title level={2} className="text-green text-center mb-5 section-title">Contact us the way you want</Title>
                                    <Row gutter={[15, 15]} align="middle" justify="center">
                                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/icon-contact-phone.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">Phone</Paragraph>
                                                <Button className="text-light-green" type="link" href="tel:+97142480561">+971 4 248 0561</Button>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/icon-email.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">Email</Paragraph>
                                                <Button className="text-light-green" type="link" href="mailto:support@osloinsurance.pet">support@osloinsurance.pet</Button>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                            <div className="contact-option text-center">
                                                <div className="circle-box">
                                                    <figure><img src="/images/whatsapp-logo-large.png" /></figure>
                                                </div>
                                                <Paragraph className="text-green font-600 mb-2">WhatsApp</Paragraph>
                                                <Button className="text-light-green" type="link" href="https://wa.me/97142461442?text=Hi%2C%20I%27d%20like%20to%20know%20more%20about%20Oslo%20Pet%20Insurance.">Message Us</Button>
                                            </div>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="contact-text-block">
                                                <Title level={3} className="text-green font-600 text-center mb-2">Customer Experience </Title>
                                                <Paragraph className="text-muted text-center mb-0">
                                                    Monday to Friday 8.30 am to 4.30 pm <br />
                                                    Saturday & Sunday email us at support@osloinsurance.pet
                                                </Paragraph>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Title level={2} className="text-green text-center mb-2 section-title">Have a media enquiry?</Title>
                                    <Title level={2} className="text-green text-center mt-0 font-300"><span className="text-muted">Email us at</span> <a href="mailto:media@osloinsurance.pet" className="text-light-green">media@osloinsurance.pet.</a></Title> */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </MainLayout>
            </Fragment>
        );
    }
}

export default contact_us;