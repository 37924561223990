import React, { Component, Fragment,Navigate } from 'react';
import { useParams  } from "react-router-dom";
import { Typography, Row, Col, Image, Input, Card, Form, Button } from 'antd';
import { Link } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout'
import { login, forgetPassword, resetPasswordCheck, resetPassword } from '../../api/pets-api';
import { setToken, setUser, popupMessages } from '../../helpers/utilities';
const { Paragraph, Title } = Typography;



class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: true,
            linkBtn: false,
            token: '',
        }
        this.onFinish = this.onFinish.bind(this);
        
    }

    async componentWillMount(){
        var splittedPath = this.props.location.pathname.split('/');
        console.log(splittedPath[splittedPath.length - 1]);
        var res = await resetPasswordCheck(splittedPath[splittedPath.length - 1])
        console.log(res);
        if (res.data.result) {
            this.setState({ token: splittedPath[splittedPath.length - 1] })
            popupMessages("Please enter new password", "success", 2)
        } else {
            popupMessages("Your token is expired. Please proceed to generate new password", "error");
            setTimeout(() => {
                window.location.href = '/login/reset';
            }, 1000);
        }
    }
    onFinish = async (values) => {
        // console.log('Received values of form: ', values);
        try {
            let forgetPw = {
                token: this.state.token,
                ...values
            }
            if(values.password != values.confirmPassword){
                popupMessages("Password and Confirm Password is not matching!", "error");
                return;
            }

            let res = await resetPassword(forgetPw)
            console.log('resss', res);

            if (res.data.status == 'ok') {
                popupMessages("Password changed successfully", "success")
                setTimeout(() => {
                    window.location.href = '/login'
                }, 1000);
                
                // this.props.history.push('/dashboard')
            } else {
                popupMessages(res.message, "error")
            }

        } catch (error) {
            popupMessages("Authentication Failed", "error", 1.5)
        }

    };

    render() {
        return (
            <Fragment>
                <MainLayout className="asdad">
                    <div className="pet-cloud-login position-relative">
                        {/* <img
                            className="img-fluid bg-img"
                            src="/images/bg-pet-login.png"
                        /> */}
                        <div className="login-inner">
                            <div className="container pt-4">
                                <Row gutter={15} align="middle">
                                    <Col xs={24} sm={24} md={16} lg={12} xl={8}>
                                        <h1 className='text-white text-center'>Pet Cloud</h1>
                                        <h4 className='text-white text-center'>Your very own space and a place you can call home</h4>
                                        <Row gutter={15} justify='center'>
                                            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                                                <div className="login-box mt-5">
                                                    <Card className='bg-gray-light' bordered={false}>
                                                        {
                                                            this.state.showLogin && <Form size="large" name="normal_login" className="customise-form"
                                                                initialValues={{
                                                                    remember: true,
                                                                }}
                                                                onFinish={this.onFinish}
                                                            >
                                                                <div className="text-center mt-3 mb-4">
                                                                    <Image
                                                                        preview={false}
                                                                        className="img-fluid"
                                                                        src="/images/icon-home-new.png"
                                                                    />
                                                                </div>
                                                                <Title level={5} className="text-dark-green">Reset password here</Title>
                                                                <Form.Item
                                                                    name="password"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter Password!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input type="password" placeholder="password" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="confirmPassword"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter Confirm Password!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="password"
                                                                        placeholder="confirm password"
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item className="mb-0">
                                                                    <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                                                        Update Password
                                                                    </Button>
                                                                </Form.Item>
                                                            </Form>

                                                        }
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            </Fragment>
        );
    }
}

export default ResetPassword;