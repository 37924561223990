import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Menu } from 'antd';

const { Content, Sider } = Layout;

class left_menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey : "home"
        }
    }
    componentWillMount(){
        this.setState({activeKey: window.location.pathname.substring(1)})
        console.log(window.location.pathname.substring(1));
    }
    render() {
        return (
            <Sider
                breakpoint="md"
                width="300px"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                className="dashboad-nav"
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[this.state.activeKey]}
                    style={{ height: '100%' }}
                >
                    <Menu.Item key="home">
                        <img src="/images/icon-home.png" />
                        <Link to={"/dashboard"}>Home</Link>
                    </Menu.Item>
                    <Menu.Item key="inbox">
                        <img src="/images/icon-inbox.png" />
                        <Link to={"/inbox"}>Inbox & Docs</Link>
                    </Menu.Item>
                    <Menu.Item key="my-pets">
                        <img src="/images/icon-tags.png" />
                        <Link to={"/my-pets"}>Pet Tags and Profiles</Link>
                    </Menu.Item>
                    <Menu.Item key="nearme">
                        <img src="/images/icon-map.png" />
                        <Link to={"/nearme"}>Near me</Link>
                    </Menu.Item>
                    <Menu.Item key="pet_insurance">
                        <img src="/images/icon-insurance.png" />
                        <Link to={"/pet_insurance"}>Pet Insurance</Link>
                    </Menu.Item>
                    <Menu.Item key="claims">
                        <img src="/images/icon-claims.png" />
                        <Link to={"/claims"}>Claims</Link>
                    </Menu.Item>
                    <Menu.Item key="reminders">
                        <img src="/images/icon-reminder.png" />
                        <Link to="/reminders">Reminders</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }
}

export default left_menu;