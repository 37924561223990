import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Table, Input, Progress, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getClaims, getUserPets, getUserImage, getUserPolicies, searchPolicyByKeywordForAdmin, searchLeads } from '../../api/pets-api';
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { Search } = Input;


class AdminLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            userPets:[],
            policies:[],
            claims:[],
            progress:[],
            completed:[],
            selectedPetId: 0,
            currentBenefits: 0,
            currentRemainingBenefits: 0,
            currentDeductable: 0,
            currentRemainingDeductable: 0,
            tabPosition: 'left',
            setTabPosition: 'left' ,
            tableTitle: 'Leads',
            selectedButton: 1,
            leads:[]
        };
    }

    searchLeads = async (value) => {
        console.log('test', value);
        try{
            let respo = await searchLeads(value);
            console.log('search result', respo);

            let leads = [];
            if(respo.status == 200){
                leads = respo.data.result;
                this.setState({leads:leads});
            }else{
                this.setState({leads:[]});
            }

        }catch(ex){
            this.setState({leads:[]});
        }
    }

    navigateToLead(leadId){
        console.log('leadId', leadId);
        window.open('/email_link?id='+leadId, "_blank")
    }

    render() {
        
        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                render: (email) => <Fragment key={1}>
                    
                    <Paragraph className="text-muted p-0 mb-0"> {email} </Paragraph>
                </Fragment>
            },
            {
                title: 'Pet Name',
                dataIndex: 'petName',
                render: petName => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{petName}</Paragraph>
                </Fragment>
            },
            {
                title: 'DOB',
                dataIndex: 'dob',
                render: dob => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{moment(dob).format('MM/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Created Date',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{moment(createdAt).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'View More',
                dataIndex: 'id',
                render: (id) => <Fragment key={2}>
                    <Button htmlType="button" type="primary" shape="circle" onClick={() => this.navigateToLead(id)}  >
                        <SearchOutlined  />
                    </Button>
                </Fragment>
            },
        ];

        return (
            <Layout className="dashboard middle claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Search Leads</Title>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Search placeholder="Email" enterButton="Search" size="large" onSearch={this.searchLeads} />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Card className="custom-shadow green" title={<Title level={4} className="text-center text-white">{this.state.tableTitle}</Title>}>
                                <Table className="noborder" columns={columns} dataSource={this.state.leads} pagination={false} size="small" />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        );
    }
}

export default AdminLeads;