import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Layout, Image, Typography } from 'antd';
import { getUser } from '../../helpers/utilities';
const { Title } = Typography;

const { Sider } = Layout;

class LoginSidebar extends Component {
    constructor(props) {
        super(props);
        this.state={
            user:{}
        }
        this.getDefaultImage = this.getDefaultImage.bind(this);
    }
    getDefaultImage(type){
        return type=="dog" ? "/images/casper-dog.png" : "/images/smokey-cat.png"
    }
    componentDidMount(){
        this.setState({user:getUser()})
    }
    render() {
        return (
            <Sider
                    breakpoint="md"
                    width="300px"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <div className="logo">
                        <Link to="/"><Image
                            preview={false}
                            className="img-fluid"
                            src="/images/logo.png"
                        /></Link>
                    </div>
                    <div className="sidebar-content">
                        <Image
                            preview={false}
                            className="img-fluid"
                            src="/images/icon-recycle-cloud.png"
                        />
                        <Title level={2} className="text-white mb-0 font-normal">Good morning, {this.state.user?.lastName}</Title>
                        <Title level={4} className="text-dark-green mt-0 font-normal">Welcome to pets Cloud</Title>
                        <div className="pet-box mt-4">
                            <ul>
                                {
                                    this.props.userPets && this.props.userPets.map((pet,index)=>(
                                        <li key={index}>
                                             <>
                                            {pet.image && <Image
                                                preview={false}
                                                className="img-fluid"
                                                src={pet.image}
                                            />}
                                            {!pet.image && (<Image
                                                preview={false}
                                                className="img-fluid"
                                                src={pet.type=='Dog'?"/images/casper-dog.png":"/images/smokey-cat.png"}
                                            />)}
                                            <div className="pet-name">{pet.name}</div>
                                            </> 
                                        </li>
                                    ))
                                }
                                {/* <li>
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/casper-dog.png"
                                    />
                                    <div className="pet-name">Casper</div>
                                </li>
                                <li>
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/smokey-cat.png"
                                    />
                                    <div className="pet-name">Smokey</div>
                                </li>
                                <li>
                                    <i className="fa fa-plus"></i>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </Sider>
                
            // <Sider
            //         breakpoint="md"
            //         width="300px"
            //         collapsedWidth="0"
            //         onBreakpoint={broken => {
            //             // console.log(broken);
            //         }}
            //         onCollapse={(collapsed, type) => {
            //             // console.log(collapsed, type);
            //         }}
            //     >
            //         <div className="logo">
            //             <Image
            //                 preview={false}
            //                 className="img-fluid"
            //                 src="/images/logo.png"
            //             />
            //         </div>
            //         <div className="sidebar-content">
            //             <Image
            //                 preview={false}
            //                 className="img-fluid"
            //                 src="/images/cloud.png"
            //             />
            //             <Title level={2} className="text-white mb-0 font-normal">Good morning, {this.state.user?.lastName}</Title>
            //             <Title level={4} className="text-dark-green mt-0 font-normal">Welcome to pets Cloud</Title>
            //             <div className="pet-box mt-4">
            //                 <ul>
            //                     {
            //                         this.props.usePets?.map((pet,index)=>(
            //                             <li key={index}>
            //                                 <Image
            //                                     preview={false}
            //                                     className="img-fluid"
            //                                     src="/images/casper-dog.png"
            //                                 />
            //                                 <div className="pet-name">{pet.name}</div>
            //                             </li>
            //                         ))
            //                     }
            //                     {/* <li>
            //                         <Image
            //                             preview={false}
            //                             className="img-fluid"
            //                             src="/images/casper-dog.png"
            //                         />
            //                         <div className="pet-name">Casper</div>
            //                     </li> */}
            //                     {/* <li>
            //                         <Image
            //                             preview={false}
            //                             className="img-fluid"
            //                             src="/images/smokey-cat.png"
            //                         />
            //                         <div className="pet-name">Smokey</div>
            //                     </li> */}
            //                     <li>
            //                         <i className="fa fa-plus"></i>
            //                     </li>
            //                 </ul>
            //             </div>
            //         </div>
            //     </Sider>
                
        );
    }
}

export default LoginSidebar;