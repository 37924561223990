import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Radio, Steps, Button } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import CalendarDatePicker from './partial_claims/date_picker';
import SideMenu from './sidebar_menu_card';
import StartOfClaim from './partial_claims/start_of_claim';
import PetForClaim from './partial_claims/pet_for_claim';
import PetProblemCategories from './partial_claims/pet_problem_categories';
import PaymentComponent from './partial_claims/payment';
import UploadDocuments from './partial_claims/upload_documents';
import AddComments from './partial_claims/add_comments';
import LastStep from './partial_claims/last_step';


const { Title } = Typography;
const { Content } = Layout;
const { Step } = Steps;


class claims extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            from: 'category',
            value: "accident",
            claimObj: {}
        };
    }

    next = (from) => {
        const current = (from === 'upload' && this.state.value !== 'accident' && this.state.value !== 'lost') ? this.state.current + 2 : this.state.current + 1;
        this.setState({ current, from });
    }

    prev = (from) => {
        const current = this.state.current - 1;
        this.setState({ current, from });
    }

    resetSteps = () => {
        const current = 0;
        this.setState({ current, from: 'category' ,claimObj: {}, value:"accident" });
    }

    populateClaimObj = (key,value) => {
        let claimObj = this.state.claimObj;
        claimObj[key] = value;
        this.setState({claimObj});
        console.log('claimObjPop', claimObj);
    }

    onChangePetProblem = (e) => {
        this.populateClaimObj('whatHappen',e.target.value);
        this.setState({
            value: e.target.value,
        });
    }

    render() {
        const { current } = this.state;
        const steps = [
            {
                content:
                    <Fragment>
                        <div className="steps-content text-center step1">
                            <StartOfClaim actionNext={this.next} />
                        </div>
                    </Fragment>
            },
            {
                content:
                    <Fragment>
                        <div className="steps-content text-center step2">
                            <PetForClaim populateClaimObj={this.populateClaimObj} actionNext={this.next} actionPrev={this.prev} />
                        </div>
                    </Fragment>
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step3">
                        <PetProblemCategories value={this.state.value} actionNext={this.next} actionPrev={this.prev} populateClaimObj={this.populateClaimObj} changePetProblem={this.onChangePetProblem} />
                    </div>
                </Fragment>
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step4">
                        {
                            <CalendarDatePicker claimObj={this.state.claimObj} populateClaimObj={this.populateClaimObj} value={this.state.value} actionNext={this.next} actionPrev={this.prev} />
                        }
                    </div>
                </Fragment>
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step5">
                        {
                            <PaymentComponent from={this.state.from} claimObj={this.state.claimObj} populateClaimObj={this.populateClaimObj} value={this.state.value} actionNext={this.next} actionPrev={this.prev} />
                        }
                    </div>
                </Fragment>
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step6">
                        {
                            <UploadDocuments claimObj={this.state.claimObj} populateClaimObj={this.populateClaimObj} value={this.state.value} actionNext={this.next} actionPrev={this.prev} />
                        }
                    </div>
                </Fragment>,
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step7">
                        {
                            <AddComments claimObj={this.state.claimObj} populateClaimObj={this.populateClaimObj}  value={this.state.value} actionNext={this.next} actionPrev={this.prev} />
                        }
                    </div>
                </Fragment>,
            },
            {
                content: <Fragment>
                    <div className="steps-content text-center step8">
                        {
                            <LastStep claimObj={this.state.claimObj} populateClaimObj={this.populateClaimObj} actionNext={this.next} actionPrev={this.prev} resetSteps={this.resetSteps} />
                        }
                    </div>
                </Fragment>,
            },
        ];

        return (
            <Layout className="dashboard middle claim-wizard">
                <LoginHeader />
                <Content className="pt-5 pb-5">
                    <SideMenu />
                    <div className="container">
                        {steps[current].content}
                    </div>
                </Content>
                <LoginFooter />
            </Layout>
        );
    }
}

export default claims;