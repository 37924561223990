import React, { Fragment } from 'react';
import { Spin, Image, Typography, Button, Row, Col, Card, Form, Input, Select, Radio, DatePicker, Collapse, Tooltip } from 'antd';
import { createLead } from '../../api/pets-api';
import { Link } from "react-router-dom";
import moment from 'moment';
import { CURRENCY, BenifitsMap } from '../../constants';
import { notifications } from '../../helpers/utilities';
import FeaturesTable from './features_table';
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

const { Option } = Select;

class DogMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            petTypeBreeds: [],
            petBreeds: [],
            plans: [],
            spin: true,
            disableBtn: false,
            dogId: '',
            type: 'Cat',
            genderBtnType: 'Male',
            petBtnType: 'Cat',
            petBtnClass: 'Cat',
            faqItems:[]
        }
        this.changeType = this.changeType.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        // this.saveQoute = this.saveQoute.bind(this);
    }
    qouteRef = React.createRef()
    formRef = React.createRef();
    pageRef = React.createRef();
    mainRef = React.createRef();

    onGenderChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ genderBtnType: e.target.value })
    }
    onTypeChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ petBtnType: e.target.value })
        if (e.target.value === this.state.dogId) {
            this.setState({ petBtnClass: 'dog' })
        }else{
            this.setState({ petBtnClass: 'cat' })
        }
    }

    onDetailClick = () => {
        this.pageRef.current.scrollIntoView();
    }

    componentDidMount = () => {
        console.log('props child', this.props);
        
        
        this.props.setQouteRef(this.qouteRef)
        this.mainRef.current.scrollIntoView()
        // console.log('mount', this.props.petTypeBreeds);
        this.props.petTypeBreeds.map(type => {
            if (type.name == 'Cat') {
                this.setState({ dogId: type.id, petBreeds: type.breeds })
            }
        });
        this.setState({ petTypeBreeds: this.props.petTypeBreeds, plans: this.props.plans }, ()=>{
            
            if(this.props.location.pathname === "/ins"){
                this.goToQouteSection()
            }
        });

        const faqHomePageItems = [
            {
                question: "Can I visit any veterinarian? ( with my dog )",
                answer: "Of course ! We understand the importance of a trusting relationship between you and the vet. If you purchased your plan with OSLO, you may visit any licensed veterinarian in the UAE. This includes specialists and emergency.",
                hasChildItems: false
            },
            {
                question: "What is an annual deductible for pet insurance?",
                answer: "An annual deductible is the fixed amount per policy period (one year) that needs to be met before we would begin to pay benefits for covered claims, at the elected reimbursement amount. Only covered claims will be applied to your annual deductible. Your deductible will reset when your policy renews each year.",
                hasChildItems: false
            },
            {
                question: "What is a co-insurance for pet insurance?",
                answer: "Co-insurance is the percentage of the vet bill the pet parent is responsible for versus what OSLO will pay . With Oslo you have the option to choose between 70%, 80%, 90% . Keep in mind that this is applied to every claim.",
                hasChildItems: false
            },
            {
                question: "When does my policy start?",
                answer: "Oslo policies are effective at 12:01am standard time at your mailing address the day after enrollment.",
                hasChildItems: false
            },
            {
                question: "How do I file a claim? for my dog",
                answer: "Electronic/Paperless Claims Submission",
                hasChildItems: true,
                childItems: [
                    {
                        text: "Log into your Oslo Pet Cloud from any device."
                    },
                    {
                        text: "Select the “Send a claim” card and answer the online questions."
                    },
                    {
                        text: "Upload/attach a copy of your paid invoice and submit your claim."
                    },
                    {
                        text: "Have your veterinarian send us your pet’s medical records."
                    },
                    {
                        text: "You’re done!"
                    }
                ]
            },
            {
                question: "Can I pay on a monthly basis? ",
                answer: "Oslo is working with TAMARA where you can pay up to 4 installments. You will have the choice between annual premium or installment",
                hasChildItems: false
            },
            {
                question: "Can I get a sample of the insurance policy? (dog)",
                answer: "Yes sure at OSLO we are fully transparent. you can download our policy summary from our website.",
                hasChildItems: false
            },
            {
                question: "How do I add another pet?",
                answer: "Each pet needs its own policy and you will have to set each policy one at a time. If you’re buying the policies during your first purchase, please select “add a pet”. If you wish to purchase another policy after having purchased a policy for your first pet, just login to your Oslo Pet Cloud and hit “add a pet” in your account to get a new quote for your other furry friend – and get a 5% multi-pet discount!",
                hasChildItems: false
            }
        ];

        this.setState({faqItems: faqHomePageItems});
    }

    componentDidUpdate(prevProps) {

        if (this.props.petTypeBreeds !== prevProps.petTypeBreeds) {
            if (this.state.spin) {
                console.log('update', this.props.petTypeBreeds);

                this.props.petTypeBreeds.map(type => {
                    if (type.name == 'Cat') {
                        this.setState({ dogId: type.id, petBreeds: type.Breads })
                    }
                })
                this.setState({ spin: false })
            }
            let typeBreeds = this.props.petTypeBreeds;
            typeBreeds.map(type => {
                let crossIndex = type.Breads.findIndex(breed => breed.name === 'Crossbreed');
                if (crossIndex !== -1) {
                    type.Breads.unshift(type.Breads.splice(crossIndex, 1)[0]);
                }
            })
            this.setState({ petTypeBreeds: typeBreeds })
        }
        if (this.props.plans !== prevProps.plans) {
            console.log('plans', this.props.plans);

            this.setState({ plans: this.props.plans });
        }
    }

    async onFinish(values) {

        let isPrimaryPet = this.props.addNewPet ? false : true;

        let selectedDate = values.age;
        let type = this.state.type;
        let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
        let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
        console.log('age different params', ageMthDiff, ageYrDiff, type);
         if (ageMthDiff > 96 && type === 'Dog') {
            let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        } else if (ageMthDiff > 120 && type === 'Cat') {
            let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        }

        console.log('cleicked')
        values = { ...values, age: moment(values.age).toDate(), isPrimaryPet: isPrimaryPet }
        console.log(values);
        let pPlanObj = {}
        pPlanObj = this.props.preQoute.hasOwnProperty('petsWithPlans') ? { petsWithPlans: this.props.preQoute.petsWithPlans } : { petsWithPlans: [] }
        pPlanObj.petsWithPlans.splice(pPlanObj.petsWithPlans.length, 0, { ...values, planId: 1, reimburseId: 1, deductionId: 1 });
        console.log('pPlanObj',pPlanObj);
        var respLead = await createLead(pPlanObj);
        if(respLead.data.error){
            notifications('Error occored while saving the lead', 'Alert', 'error')
        }else{
            
            this.props.setLeadId(respLead.data.result);
        }
        this.props.populateQoute(pPlanObj)
        this.props.changeFlag('qoute')
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    changeType(event) {
        let index = event.target.id;
        let type = event.target.petType;
        let petBreeds = this.state.petTypeBreeds[index].Breads
        this.setState({ petBreeds, type })
    }

    goToQouteSection = () => {
        this.qouteRef.current.scrollIntoView()
    }

    ageChange = () => {
        this.setState({ disableBtn: false }, () => {

            let selectedDate = this.formRef.current.getFieldValue('age');
            let type = this.state.type;
            let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
            let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
            console.log('age different params', ageMthDiff, ageYrDiff, type);
            if (ageMthDiff < 2) {
                let dateCopy = moment(selectedDate);
                let nextDate = dateCopy.add(2, 'M');
                console.log(ageMthDiff, nextDate);
                let ageMsg = 'At Oslo we love puppies and kitten, the effective date of your insurance will be 15th of ' + nextDate.format('MM') + '/' + nextDate.format('Y') + ' , for more info on age requirements please check our FAQ'
                notifications(ageMsg, 'Alert', 'warning')
            } else if (ageMthDiff > 96 && type === 'Dog') {
                let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            } else if (ageMthDiff > 120 && type === 'Cat') {
                let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            }
        });
    }

    render() {
        return (
            <div ref={this.mainRef}>
                <Fragment>
                    {
                        (!this.props.addNewPet && !this.props.renewPolicy) && 
                        <div>
                            <div className="banner doghome">
                                <Spin spinning={this.props.spinning} tip={this.props.tip} size="large">
                                <img
                                    className="img-fluid w-100"
                                    src="/images/banner-dog-landing.svg"
                                />
                                
                                <div className="banner-caption">
                                    <div className="container">
                                        <Row gutter={15}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                                <div className="text-center">
                                                    <Title level={1} className="text-white tagline">Dog Insurance</Title>
                                                    <Title level={4} className="text-white mt-0 mb-5 sub-tagline">Let’s give them the best</Title>
                                                    <Button size="large" type="primary" className="btn-red" htmlType="submit" onClick={this.goToQouteSection}>Get a Quote</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                </Spin>
                            </div>
                            <div className="pet-insurance full-width">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-5 section-title">How does OSLO work?</Title>
                                    <Row gutter={[20, 20]}>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step1.png"
                                                />
                                                <Title level={3} className="text-green font-700">Take your pet to the vets</Title>
                                                <Paragraph>Seek the best veterinary treatment for your pet when an accident or illness occurs.</Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step2.png"
                                                />
                                                <Title level={3} className="text-green font-700">Send us your claim</Title>
                                                <Paragraph>Send us a pic of your vet bill and submit it through our OSLO pet cloud.</Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step3.png"
                                                />
                                                <Title level={3} className="text-green font-700">Get your money back</Title>
                                                <Paragraph>Follow your claim status and get paid by direct transfer.  </Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="text-center mt-3">
                                                <Button size="large" type="primary" className="btn-red" htmlType="submit" onClick={this.goToQouteSection}>Get a Quote</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="insurance-plans full-width home-section bg-gray-light">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-5 section-title">Pet Insurance for Dogs</Title>
                                    <div className="full-width">
                                        <Row gutter={[15, 15]} align="middle" justify="center">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                                                <Row gutter={[15, 15]} align="middle">
                                                    {
                                                        this.state.plans.length && this.state.plans.map((obj, index) => (
                                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                <Card className="plan-box">
                                                                    <Button className="btn-category btn-blue rounded-100">{obj.title}</Button>
                                                                    <div className="plan-tagline mt-5">AED {(BenifitsMap[obj.annualy].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} Annual Benefits</div>
                                                                    <div className="plan-price text-center mt-4 ">
                                                                        <span >from</span>
                                                                    </div>
                                                                    <div className="plan-price text-center">
                                                                        {/* <sub>{CURRENCY}</sub> <span className="price-amount">{obj.monthly.toString().split(".")[0]}</span> */}
                                                                        
                                                                        <sub>{CURRENCY}</sub> <span className="price-amount">{(index) ? (index == 1) ? 77 : 89 : 50 }</span><sub>/month</sub>
                                                                        {/* <div className="full-width">per month</div> */}
                                                                    </div>
                                                                    <div className="what-we-cover full-width mt-4 text-center">
                                                                        <img src="/images/icon-warning-red.png" />
                                                                        <Title level={5} className="text-light-green mt-3">What we cover</Title>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Col>
                                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className="text-center mt-5">
                                                    <Button type="link" className="btn-blue rounded-100" size="large" onClick={this.onDetailClick}>See more</Button>
                                                </div>
                                            </Col> */}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="full-width-section pb-5 full-width">
                                <div className="container">
                                    <Row gutter={[15, 15]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Title level={2} className="text-green mb-2 section-title text-center">The exact coverage level your pet needs</Title>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="full-width bg-gray-light rounded-10 p-4">
                                                <Row gutter={[15, 15]}>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check">
                                                            <ul>
                                                                <li>Exams & Consultation Fees</li>
                                                                <li>Emergency & Hospitalization</li>
                                                                <li>Surgeries</li>
                                                                <li>Hereditary & Congenital conditions</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check">
                                                            <ul>
                                                                <li>Chronic conditions</li>
                                                                <li>Cancer Treatments</li>
                                                                <li>Prescriptions</li>
                                                                <li>Veterinary Specialists</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow dot-center rounded-check">
                                                            <ul>
                                                                <li>Emergency Boarding Fees</li>
                                                                <li>Imaging</li>
                                                                <li>Knee conditions</li>
                                                                <li>Prosthetic & Orthotic devices</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ marginTop: '-20px' }} className="full-width text-center mb-4">
                                                <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="right" title={<Fragment>
                                                    <Title level={4} className="text-white text-center">OSLO Assistance is included for free!</Title>
                                                    <Paragraph className="text-white mb-0">• Emergency boarding fees</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Lost pet</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Pet death</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Vacation cancellation</Paragraph>
                                                </Fragment>}>
                                                    <img
                                                        className="img-fluid"
                                                        src="/images/iocn-gift.png"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className="full-width bg-gray-light rounded-10 p-4 mt-4">
                                                <Row gutter={15}>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check red-cross">
                                                            <ul>
                                                                <li>Pre-existing conditions</li>
                                                                <li>Routine & Wellness</li>
                                                                <li>Preventive Care</li>
                                                                <li>Spaying or Neutering</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check red-cross">
                                                            <ul>
                                                                <li>Experimental procedures</li>
                                                                <li>Breeding, pregnancy or birth</li>
                                                                <li>Cloning procedures or cloned pets</li>
                                                                <li>Cosmetic surgery</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                        <div className="points-toknow dot-center rounded-check red-cross">
                                                            <ul>
                                                                <li>Coprophagia (“eating poop”)</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <div className="full-width text-center mt-4">
                                                <Button type="link" className="btn-blue rounded-100" size="large" onClick={this.onDetailClick}>See more details</Button>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="pet-insurance full-width">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-5 section-title">What real dog parents are saying</Title>
                                    <Row gutter={[20, 20]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <div className="pet-insurance-box bg-gray-light testimonial" style={{ borderRadius: '10px' }}>
                                                <div className="testimonial-glyphe">
                                                    <Image
                                                        preview={false}
                                                        className="img-fluid"
                                                        src="/images/testimonial-icon.svg"
                                                    />
                                                </div>
                                                <div className="testimonial-content">
                                                    <blockquote className="testimonial-quote">
                                                        Pet insurance was really confusing and hard to understand until we found Oslo. The vet bills are not going to be a worry anymore. No financial stress also. Thanks to Oslo pet insurance.
                                                    </blockquote>
                                                    <div className="red-seperator"></div>
                                                    <div className="testimonial-author">
                                                        <span className="author-name text-green">Chole & Tia</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-image-holder">
                                                    <div className="testimonial-image">
                                                        <Image
                                                            preview={false}
                                                            className="img-fluid"
                                                            src="/images/chole-tia-testimonial.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <div className="pet-insurance-box bg-gray-light testimonial" style={{ borderRadius: '10px' }}>
                                                <div className="testimonial-glyphe">
                                                    <Image
                                                        preview={false}
                                                        className="img-fluid"
                                                        src="/images/testimonial-icon.svg"
                                                    />
                                                </div>
                                                <div className="testimonial-content">
                                                    <blockquote className="testimonial-quote">
                                                    Super happy with Oslo. Great to finally have an insurance for my dog Lilly which is not too expensive and reimburse pretty much everything - Well done Oslo!
                                                    </blockquote>
                                                    <div className="red-seperator"></div>
                                                    <div className="testimonial-author">
                                                        <span className="author-name text-green">Chris Delorme</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-image-holder">
                                                    <div className="testimonial-image">
                                                        <Image
                                                            preview={false}
                                                            className="img-fluid"
                                                            src="/images/chris-delorme-testimonial.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="insurance-points full-width faqs-content">
                                <div className="container">
                                    <Title level={2} className="text-green text-center section-title">Any Question, We got you covered.</Title>
                                    {
                                        this.state.faqItems &&
                                        <Row gutter={15} justify="left">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Collapse className="points-toknow" bordered={false} expandIconPosition={"right"}>
                                                {
                                                    this.state.faqItems.map((faqItem, index) => {
                                                        return (
                                                            <Panel header={<Title level={3} className="m-0 text-green font-700">{faqItem.question}</Title>} key={index}>
                                                                <Paragraph className="text-green">{faqItem.answer}</Paragraph>
                                                                {
                                                                    faqItem.hasChildItems && 

                                                                    <ul>
                                                                    {
                                                                        faqItem.childItems.map((childItem, indexChild) => {
                                                                            return (
                                                                                <li>{childItem.text}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                    </ul>
                                                                }
                                                                
                                                            </Panel>
                                                        )
                                                    })
                                                } 
                                                    
                                                    
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    }
                                    
                                    <div className="text-center mt-4">
                                        <Button className="btn-white rounded-100" size="large">
                                            <Link to="/faqs">Learn more</Link>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div className="customise-paln bg-gray-light pt-5 pb-5 full-width" ref={this.qouteRef}>
                        <div className="container">
                            <Title level={2} className="text-green text-center section-title mb-5">Now it's your turn</Title>
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={13}>
                                    <Row gutter={15} align="middle" className="full-width">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                name="basic"
                                                onFinish={this.onFinish}
                                                onFinishFailed={this.onFinishFailed}
                                                size={"large"}
                                                className="customise-form"
                                                ref={this.formRef}
                                            >
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input pet's name!",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"My Pet’s Name *"} />
                                                </Form.Item>
                                                <Row gutter={15} justify="center">
                                                    {!this.state.spin && <Col sm={12} md={12} lg={12} xl={12}>

                                                        <Spin spinning={this.state.spin}>
                                                            <Form.Item
                                                                className="text-center"
                                                                name="type"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select type! *',
                                                                    }
                                                                ]}
                                                                initialValue={this.state.dogId}
                                                                onChange={this.onTypeChange}
                                                            >

                                                                {
                                                                    this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.petBtnClass} onChange={this.changeType}>
                                                                        {
                                                                            this.state.petTypeBreeds.map((type, index) =>
                                                                                <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                            )
                                                                        }
                                                                        {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                        <span className="switch-selection"></span>
                                                                    </Radio.Group>
                                                                }
                                                            </Form.Item>
                                                        </Spin>
                                                    </Col>}
                                                    <Col sm={12} md={12} lg={12} xl={12} className={this.state.genderBtnType}>
                                                        {/* value='Male' initialValue={'Male'} */}
                                                        <Form.Item className="text-center" name="gender"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select gender! *',
                                                                },
                                                            ]}
                                                            initialValue={"Male"}
                                                            onChange={this.onGenderChange}>
                                                            <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.genderBtnType.toLowerCase()}>
                                                                <Radio.Button value="Male">Male</Radio.Button>
                                                                <Radio.Button value="Female">Female</Radio.Button>
                                                                <span className="switch-selection"></span>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    name="age"
                                                    size={"large"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Select Date of Birth (MM/YYYY)! *',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Select Date of Birth (MM/YYYY) *" format="MM/YYYY" picker="month" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="breed"
                                                    size={"large"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select breed! *',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select breed *"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {
                                                            this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                <Option key={index} value={breed.id}>{breed.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                {
                                                !this.props.addNewPet &&            
                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your email! *',
                                                        },
                                                        {
                                                            type: 'email',
                                                            message: 'Please input correct email!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Email *" />
                                                </Form.Item>
                                                }
                                                {/* <Form.Item
                                                    name="promo"
                                                >
                                                    <Input placeholder="Promo Code" />
                                                </Form.Item> */}
                                                <Form.Item className="text-center mt-5">
                                                    <Button disabled={this.state.disableBtn} type="primary" className="btn-red rounded-100" htmlType="submit">Continue</Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Fragment>
            </div>
        );
    }
}

export default DogMainPage;