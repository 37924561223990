import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Table, Input, Progress, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getClaims, getUserPets, getUserImage, getUserPolicies, searchPolicyByKeywordForAdmin } from '../../api/pets-api';
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { Search } = Input;


class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            userPets:[],
            policies:[],
            claims:[],
            progress:[],
            completed:[],
            selectedPetId: 0,
            currentBenefits: 0,
            currentRemainingBenefits: 0,
            currentDeductable: 0,
            currentRemainingDeductable: 0,
            tabPosition: 'left',
            setTabPosition: 'left' ,
            tableTitle: 'Policies',
            selectedButton: 1
        };
    }

    searchPolicies = async (value) => {
        console.log('test', value);
        try{
            let respo = await searchPolicyByKeywordForAdmin(value);
            console.log('search result', respo);

            let polics = [];
            if(respo.status == 200){
                polics = respo.data.result;
                let polics2 = [];

                polics.forEach((itm) => {
                    var stringPolicyCode = '0000000000' + itm.policySerial;
                    var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
                    itm.policySerialNumber = 'OSLO-'+itm.policyCurrency.toUpperCase()+'-'+itm.policyType+'-'+delimitedSerial

                    if(itm.policySerialNumber.includes(value) || itm.phoneNo.includes(value) || itm.email.includes(value)){
                        polics2.push(itm);
                    }
                })

                if(polics2.length == 0){
                    message.success(`No Result Found`);
                }

                this.setState({policies:polics2});
                console.log(polics2);
            }else{
                this.setState({policies:[]});
            }

        }catch(ex){
            this.setState({policies:[]});
        }
    }

    navigateToPolicy(policyNumber){
        console.log('Policy Number', policyNumber);
        window.location.pathname = '/admin_policies/'+policyNumber
    }

    render() {
        
        const columns = [
            {
                title: 'Policy',
                dataIndex: 'policySerialNumber',
                render: (policySerialNumber) => <Fragment key={1}>
                    
                    <Paragraph className="text-muted p-0 mb-0"> {policySerialNumber} </Paragraph>
                </Fragment>
            },
            {
                title: 'Pet Name',
                dataIndex: 'name',
                render: name => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{name}</Paragraph>
                </Fragment>
            },
            {
                title: 'DOB',
                dataIndex: 'dob',
                render: dob => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{moment(dob).format('MM/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Policy Eff. Date',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{moment(createdAt).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'View More',
                dataIndex: 'policyId',
                render: (policyId) => <Fragment key={2}>
                    <Button htmlType="button" type="primary" shape="circle" onClick={() => this.navigateToPolicy(policyId)}  >
                        <SearchOutlined  />
                    </Button>
                </Fragment>
            },
        ];

        return (
            <Layout className="dashboard middle claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Search Policies</Title>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Search placeholder="Policy no / Customer Phone / Email" enterButton="Search" size="large" onSearch={this.searchPolicies} />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Card className="custom-shadow green" title={<Title level={4} className="text-center text-white">{this.state.tableTitle}</Title>}>
                                <Table className="noborder" columns={columns} dataSource={this.state.policies} pagination={false} size="small" />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        );
    }
}

export default AdminDashboard;