import React, { Component, useState, useEffect } from 'react';
import Header from './header';
import LeftMenu from './left_menu'
import { Link } from "react-router-dom";
import { Layout, Typography, Popconfirm, DatePicker, Card, Row, Col, Table, Button, Form, Input, Select, Radio, Modal, Spin } from 'antd';
import { getUserPets, createReminder, editReminder, getReminder, deleteReminder } from '../../api/pets-api';
import { notifications, upperCase } from '../../helpers/utilities';
import moment from 'moment';
import LoginHeader from '../../components/common/loginHeader';
import { REMINDER_TYPES } from '../../api/constants';
const { Paragraph, Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;


const MainDiv = (props) => {
    const [formRef] = Form.useForm();

    const onEdit = (index) => {
        console.log(index, props.reminders[index], moment(props.reminders[index].time).format('YYYY-MM-DD HH:mm'));
        const remindBefore = props.reminders[index].remindBefore.split('-');
        console.log(remindBefore);

        props.modalVisible(true);
        props.setEditFlag(true);
        props.setMakeReminder(props.reminders[index])
        formRef.setFieldsValue({
            title: props.reminders[index].title,
            description: props.reminders[index].description,
            petId: props.reminders[index].petId,
            before: remindBefore[0],
            format: remindBefore[1],
            time: moment(props.reminders[index].time),
            type: props.reminders[index].type,
        })
    }
    const columns = [
        {
            title: 'Title',
            // dataIndex: 'title',
            render: (record) => upperCase(record.title),
            key: 'title',
        },
        {
            title: 'Pet Name',
            // dataIndex: 'Pet.petName',
            render: (record) => upperCase(record.Pet.petName),
            key: 'petName',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: time => <div className="date-modify">{moment(time).format('MM/DD/YYYY hh:mm:ss A')} </div>,

        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'x',
            render: (title, row, index) => {
                return <div key={(index + 1)}><a value={index} onClick={(e) => onEdit(index)}>Edit</a> | <Popconfirm
                    title="Are you sure you want to delete this reminder?"
                    key={index}
                    onConfirm={() => props.onDelete(index)}
                    // onCancel={()=>{this.setState({popVisible:false})}}
                    // visible={this.state.popVisible}
                    okText="Yes"
                    cancelText="No"
                ><a value={index} onClick={props.checkVisible}>Delete</a></Popconfirm></div>
            },

        },
    ]
    return (
        <>
            <div className="table-responsive">
                <Table pagination={false} columns={columns} dataSource={props.reminders} />
            </div>
            <Button onClick={props.showModal} className="btn-blue btn-add"><i className="fa fa-plus"></i></Button>
            <Modal
                title="Set Reminder"
                visible={props.visible}
                onOk={props.onOk}
                onCancel={props.onCancel}
                footer={false}
            >
                <Form
                    name="basic"
                    onFinish={props.saveReminder}
                    onFinishFailed={props.reminderFailed}
                    size={"large"}
                    className="customise-form"
                    // ref={formRef}
                    form={formRef}
                >
                    <Form.Item name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Reminder Title!',
                            }
                        ]}
                        shouldUpdate={true}
                    >
                        <Input placeholder="Reminder Title" />
                    </Form.Item>

                    <Form.Item name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Reminder Description!',
                            }
                        ]}
                    >
                        <TextArea rows={4} placeholder="Reminder Description" />
                    </Form.Item>

                    <Form.Item name="petId"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Pet!',
                            }
                        ]}
                    >
                        <Select placeholder="Pet">
                            {
                                props.userPets && (props.userPets.map((pet, index) =>
                                    <Option key={index} value={pet.id}>{pet.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="time"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Reminder!',
                            }
                        ]}
                    >
                        <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                    </Form.Item>

                    <Form.Item name="before"
                        rules={[{
                            required: true,
                            message: 'Please Select Days/Hours!',
                        }]}
                    // initialValue="1"
                    >
                        <Select placeholder="Remind Before">
                            {
                                Array.from({ length: 24 }, (v, k) => k + 1).map((d, index) =>
                                    <Option key={index} value={d}>{d}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="format"
                        rules={[{
                            required: true,
                            message: 'Please Select Days/Hours!',
                        }]}
                    // initialValue="d"
                    >
                        <Select placeholder="Format" >
                            <Option value="d">Day(s)</Option>
                            <Option value="h">Hour(s)</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="type"
                        rules={[{
                            required: true,
                            message: 'Please Select Type',
                        }]}
                    // initialValue="d"
                    >
                        <Select placeholder="Type" >
                            {
                                REMINDER_TYPES.map(type => <Option value={type}>{type.toUpperCase()}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item className="mb-0 text-right">
                            <Button className="text-muted" type="link" onClick={props.handleCancel}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>

            </Modal>
        </>

    )
}

class Reminders extends Component {
    constructor(props) {
        super(props);
        // this.form = Form.useForm();
    }
    // formRef = React.createRef();
    state = {
        visible: false,
        popVisible: false,
        edit: false,
        isLoading: true,
        makeReminder: {},
        reminders: [],
        userPets: [],

    };
    checkVisible = () => {
        this.setState({ popVisible: true })
    }
    modalVisible = (flag) => {
        this.setState({ visible: flag })
    }
    setEditFlag = (flag) => {
        this.setState({ edit: flag })
    }
    setMakeReminder = (obj) => {
        this.setState({ makeReminder: obj })
    }
    async componentDidMount() {
        await getUserPets().then((userPets) => {
            // console.log(userPets);  
            this.setState({ userPets: userPets.data.result })
        });
        this.getRemindersData();
    }

    getRemindersData = async () => {
        await getReminder().then((reminder) => {
            console.log(reminder);
            this.setState({ reminders: reminder.data.result, isLoading: false })
        });
    }

    onDelete = async (index) => {
        console.log(index);
        await deleteReminder(this.state.reminders[index].id).then(resp => {
            notifications('', 'Reminder Deleted Successfully!', 'success');
            this.getRemindersData();

        }).catch(error => {
            notifications('', 'Something went wrong!', 'error');
        })

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            edit: false,
            isLoading: false,
            makeReminder: {}
        });
    };

    saveReminder = async (values) => {
        // this.setState({visible:false})
        values = { ...values, time: moment(values.time).toDate(), remindBefore: values.before + '-' + values.format }
        delete values.before;
        delete values.format;
        // console.log('Success:', values);
        // return
        this.setState({ isLoading: true });
        this.state.edit ? await editReminder({ ...values, id: this.state.makeReminder.id }) : await createReminder(values);
        this.handleCancel();
        this.getRemindersData();
        // this.setState({makeReminder:{}})
    };

    reminderFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <Layout className="dashboard claims">
                <Spin spinning={this.state.isLoading} tip="Request is under process..." size="large">
                    <LoginHeader></LoginHeader>
                    <Layout>
                        <LeftMenu />
                        <Content className="p-3 bg-gray-light">
                            <Card className="card-content claims-page pb-5 rounded-5" extra={<img src="/images/icon-reminder.png" />} title={<Title level={4} className="text-dark-green">Reminders</Title>}>

                                <MainDiv
                                    saveReminder={this.saveReminder}
                                    reminderFailed={this.reminderFailed}
                                    makeReminder={this.state.makeReminder}
                                    userPets={this.state.userPets}
                                    handleCancel={this.handleCancel}
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    columns={this.state.columns}
                                    reminders={this.state.reminders}
                                    showModal={this.showModal}
                                    checkVisible={this.checkVisible}
                                    popVisible={this.state.popVisible}
                                    modalVisible={this.modalVisible}
                                    setEditFlag={this.setEditFlag}
                                    setMakeReminder={this.setMakeReminder}
                                    onDelete={this.onDelete}
                                ></MainDiv>

                            </Card>
                        </Content>
                    </Layout>
                </Spin>
            </Layout>
        );
    }
}

export default Reminders;