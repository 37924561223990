import React, { Component, Fragment } from 'react';
import { Button, Row, Col, Steps } from 'antd';
import QuotePage from './qoutepage';
import YourInfo from './yourinfo';
import Welcome from './welcome';
import { SaveOutlined } from '@ant-design/icons'
import { updateLead, getLead, createWithoutPayment, sendLeadEmail } from './../../api/pets-api';
import { getUser, setUser, notifications } from '../../helpers/utilities';

const { Step } = Steps;

class index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            visible: false,
            planPrices: []
        };

        this.steps = [
            {
                title: "QUOTE",
                content: <QuotePage plans={this.props.plans} petTypeBreeds={this.props.petTypeBreeds} populateQoute={this.props.populateQoute} preQoute={this.props.preQoute} setPlanPrices={this.setPlanPrices} updateGTAG={this.updateGTAG} {...this.props} />,
            },
            {
                title: this.props.hideUserInfo ? "PAYMENT INFO" : "YOUR INFO",
                content: <YourInfo plans={this.props.plans} monthlyPrices={this.state.planPrices} setFinalQoute={this.props.setFinalQoute} petTypeBreeds={this.props.petTypeBreeds} populateQoute={this.props.populateQoute} preQoute={this.props.preQoute} switchStep={this.switchStep} paymentFailed={this.props.paymentFailed} hideUserInfo={this.props.hideUserInfo} updateGTAG={this.updateGTAG} {...this.props} />,
            },
            {
                title: "WELCOME",
                content: <Welcome getFinalQoute={this.props.getFinalQoute} petTypeBreeds={this.props.petTypeBreeds} populateQoute={this.props.populateQoute} preQoute={this.props.preQoute} policyNumber={this.props.policyNumber} updateGTAG={this.updateGTAG} {...this.props}/>,
            },
        ];
    }
    switchStep = () => {
        this.next(2);
    }
    switchToLast = () => {
        this.setState({current:2});
    }
    switchToInfo = () => {
        this.setState({current:1});
    }
    switchToQuote = (index) => {
        this.setState({current:index});
    }
    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    findNameById = (arr, id) => {
        for (const animal of arr) {
            if (animal.id === id) {
                return animal.name;
            }
        }
        return null; // Return null if ID is not found
    }

    updateGTAG = (event) => {
        console.log('Console Output from Continue', this.props.preQoute)
        let runningValue = 0;
        let items = [];
        let plansArr = ['Vital','Favorite','Optimum']
        
        for(let i = 0; i < this.props.preQoute.petsWithPlans.length; i++){
            if(this.props.preQoute.petsWithPlans[i]){
               let curPlan = this.props.preQoute.petsWithPlans[i];
                runningValue += curPlan.plans[curPlan.planId];
                let petType = this.findNameById(this.props.petTypeBreeds, curPlan.type)
                let item = {
                    "item_name": plansArr[curPlan.planId],
                    "item_id": curPlan.planUUID,
                    "item_category": petType,
                    "price": Number(parseFloat(curPlan.plans[curPlan.planId] * 3.67).toFixed(2)),
                    "quantity": 1
                }
                items.push(item);
            }
        }

        runningValue = parseFloat(runningValue * 3.67).toFixed(2)

        let GTAGObj = {
            "event": event,
            "ecommerce": {
                 "currency": "AED",
                 "value": Number(runningValue),
                 "items": items
             }
         };
         console.log('GTAG Obj', GTAGObj)
        let res = window.dataLayer.push(GTAGObj);
         console.log('GTAG Respo', res)
    }

    continueToNext() {
        
        this.updateGTAG("begin_checkout")
        this.next(1);
        
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    componentDidMount() {
        debugger;
        console.log(this.props.preQoute);
        this.props.setQouteRef(null);
        console.log('testpropss',this.props);
        
        if(this.props.payementSuccess){
            console.log('payement successfull');
            this.switchToLast();
            return
        }
        if(this.props.gotToIndex){
            this.switchToQuote(this.props.gotToIndex);
            return;
        }
        if(this.props.paymentFailed){
            this.switchToInfo();
            return
        }
    }
    setPlanPrices = (prices) => {
        console.log('index price', prices);

        this.setState({ planPrices: prices });
    }
    saveLeadInformation = async () => {
        debugger;
        let obj = {
            id:this.props.currentLeadId,
            email:this.props.preQoute.petsWithPlans[0].email,
            planObj:this.props.preQoute,
            userObj:null
        }
        let res = await updateLead(obj);
        if (res.data.errors) {
            res.data.errors.map(error => {
                notifications('Error', error.msg, 'error');
            })
        } else {
            //notifications('Success', 'Your information saved in the system. You may receive an email with link to your quote', 'success');

            let usrObj = {
                user:{
                    email:obj.email,
                    leadId:res.data.result.id
                }
            }
    
            let resEmail = await sendLeadEmail(usrObj);
            if (resEmail.data.errors) {
                resEmail.data.errors.map(error => {
                    notifications('Error', error.msg, 'error');
                })
            } else {
                notifications('Success', 'Please check your inbox for email link for saved quotation.', 'success');
            }
        }
    }
    render() {
        const { current } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <Row gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="get-quote-wrp step-wizard-wrp full-width mt-5 mb-5">
                                <Row gutter="15" justify="center">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Steps className="custom-wizard-steps mt-5" size="large" current={current}>
                                            {this.steps.map(item => (
                                                <Step key={item.title} title={item.title} />
                                            ))}
                                        </Steps>
                                    </Col>
                                </Row>
                                <div className="steps-content mt-5">
                                    {this.steps[current].content}
                                    {current < 1 && (
                                        <div>

                                        <div className="steps-action full-width mt-5 text-center">
                                            {/* <Button size="large" className="mx-2 btn-red" onClick={() => this.next(1)}> */}
                                            <Button size="large" className="mx-2 btn-red" onClick={() => this.continueToNext()}>
                                                Continue
                                            </Button>
                                        </div>
                                        <div className="steps-action full-width mt-2 text-center">
                                            <Button type="text" size="large" className="mx-2 rounded-100" onClick={async() => await this.saveLeadInformation()}>
                                                Not ready? Save your quote
                                            </Button>
                                        </div>
                                        </div>
                                    )}
                                </div>
                                {current > 0 && current === this.steps.length - 2 && (
                                    <Row gutter={15}>
                                        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                                            <div className="steps-action text-center mt-3">
                                                <Button type="link" className="text-muted" onClick={() => this.prev()}>
                                                    Back to Plans
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default index;
