import { toast } from 'react-toastify';
import { css } from 'glamor';


const notify = (msg, id) => {
    return toast(msg, {
        toastId: id,
        className: css({
            // color: selectedTheme.accentColor + ' !important',
            // minHeight: '70px !important',
            // background: selectedTheme.accentBg + ' !important',
            // boxShadow: '3px 3px 25px 3px rgba(0,0,0,0.5) !important'
        }),
        progressClassName: css({
            // background: selectedTheme.accentColor + ' !important'
        })
    }
    );
}

export default notify