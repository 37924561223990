import React, { Component, Fragment } from 'react';
import { Typography, Row, Col, Image, Input, Card, Form, Button } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout'
import { login, forgetPassword } from '../../api/pets-api';
import { setToken, setUser, popupMessages } from '../../helpers/utilities';
const { Paragraph, Title } = Typography;



class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: true,
            linkBtn: false,
            visible:false
        }
        this.onFinish = this.onFinish.bind(this);
        this.onForgetPass = this.onForgetPass.bind(this);
        this.onFinishForgetPassword = this.onFinishForgetPassword.bind(this);
    }

    onVisibleChange(visible) {
        //this.setState({ visible: !visible })
    }

    async componentWillMount(){
        var splittedPath = this.props.location.pathname.split('/');
        console.log(splittedPath[splittedPath.length - 1]);
        if(splittedPath[splittedPath.length - 1] === "reset"){
            this.onForgetPass();
        }
    }

    onForgetPass() {
        this.setState({ showLogin: false })
    }
    onFinishForgetPassword = async (values) => {
        console.log(values);
        this.setState({ linkBtn: true })
        try {
            let res = await forgetPassword(values.email)
            this.setState({ showLogin: true })
            this.setState({ linkBtn: false })
            popupMessages("Check Your Email For Reset Link!", "success", 2)
        } catch (error) {
            this.setState({ linkBtn: false })
            popupMessages("Something went wrong", "error", 2)
            console.log(error);
        }

    }
    onFinish = async (values) => {
        // console.log('Received values of form: ', values);
        try {
            let res = await login(values)
            console.log('resss', res);

            if (res.data.status == 'ok') {
                setToken(res.data.result.token)
                setUser(res.data.result.data)
                let returnUrl = res.data.result.data.returnUrl;
                popupMessages("Successfully Login", "success")
                window.location.href = returnUrl;
                // this.props.history.push('/dashboard')
            } else {
                popupMessages(res.message, "error")
            }

        } catch (error) {
            popupMessages("Authentication Failed", "error", 1.5)
        }

    };

    render() {
        return (
            <Fragment>
                <MainLayout className="asdad">
                    <div className="pet-cloud-login position-relative">
                        {/* <img
                            className="img-fluid bg-img"
                            src="/images/bg-pet-login.png"
                        /> */}
                        <div className="login-inner">
                            <div className="container pt-4">
                                <Row gutter={15} align="middle">
                                    <Col xs={24} sm={24} md={16} lg={12} xl={8}>
                                        <h1 className='text-white text-center'>Pet Cloud</h1>
                                        <h4 className='text-white text-center'>Your very own space and a place you can call home</h4>
                                        <Row gutter={15} justify='center'>
                                            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                                                <div className="login-box mt-5">
                                                    <Card className='bg-gray-light' bordered={false}>
                                                        {
                                                            this.state.showLogin && <Form size="large" name="normal_login" className="customise-form"
                                                                initialValues={{
                                                                    remember: true,
                                                                }}
                                                                onFinish={this.onFinish}
                                                            >
                                                                <div className="text-center mt-3 mb-4">
                                                                    <Image
                                                                        preview={false}
                                                                        className="img-fluid"
                                                                        src="/images/icon-home-new.png"
                                                                    />
                                                                </div>
                                                                <Title level={5} className="text-dark-green">Welcome to your pet cloud</Title>
                                                                <Form.Item
                                                                    name="username"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter Email!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Email" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="password"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter Password!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input.Password
                                                                        type="password"
                                                                        placeholder="Password"
                                                                        visibilityToggle={{ visible: this.state.visible, onVisibleChange: this.onVisibleChange }}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item>


                                                                </Form.Item>
                                                                <Form.Item className="mb-0">
                                                                    <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                                                        Get in
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item className="mt-2 mb-0">
                                                                    <Link to="#" className="text-muted no-text-decoration forgot-pass" onClick={this.onForgetPass}>Forgot your password ?</Link>
                                                                </Form.Item>
                                                            </Form>

                                                        }
                                                        {

                                                            !this.state.showLogin && <Form size='large'
                                                                name="forget_password"
                                                                className="customise-form"
                                                                initialValues={{
                                                                    remember: true,
                                                                }}
                                                                onFinish={this.onFinishForgetPassword}
                                                            >
                                                                <div className="text-center mt-3 mb-4">
                                                                    <Image
                                                                        preview={false}
                                                                        className="img-fluid"
                                                                        src="/images/icon-home-new.png"
                                                                    />
                                                                </div>
                                                                <Title level={5} className="text-dark-green">Enter your email to get recovry link!</Title>
                                                                <Form.Item
                                                                    name="email"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            type: "email",
                                                                            message: 'Enter Email!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Email" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="cemail"
                                                                    dependencies={['email']}
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            type: "email",
                                                                            message: 'Please confirm your email!',
                                                                        },
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (!value || getFieldValue('email') === value) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(new Error('Entered emails does not match!'));
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Confirm Email" />
                                                                </Form.Item>
                                                                <Form.Item className="mb-0">
                                                                    <Button disabled={this.state.linkBtn} type="primary" htmlType="submit" className="btn-dark-green" block>
                                                                        Send Link
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item className="mb-0 mt-2">
                                                                    <Link className='text-muted no-text-decoration forgot-pass' type="link" onClick={() => { this.setState({ showLogin: true }) }} htmlType="button" block>
                                                                        Cancel
                                                                    </Link>
                                                                </Form.Item>
                                                            </Form>
                                                        }
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            </Fragment>
        );
    }
}

export default Login;