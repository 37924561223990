import React, { Component, Fragment } from 'react';
import { Collapse, Col, Typography } from 'antd';

const { Panel } = Collapse;
const { Paragraph } = Typography;

class Sidebar extends Component {
    render() {
        return (
            <Fragment>
                <div className="section-heading mb-3">Need to Know</div>
                <Collapse className="points-toknow" accordion bordered={false} expandIconPosition={"right"}>
                    <Panel header="What’s Covered" key="1">
                        <ul>
                            <li>Exam & Consultation fees</li>
                            <li>Emergency & Hospitalization</li>
                            <li>Surgeries</li>
                            <li>Hereditary & Congenital Conditions</li>
                            <li>Chronic Conditions</li>
                            <li>Cancer treatments</li>
                            <li>Prescriptions</li>
                            <li>Veterinary Specialists</li>
                            <li>Imaging</li>
                            <li>Knee Conditions</li>
                            <li>Prosthetics & Orthopedics</li>
                            <li>Hip Dysplasia</li>
                        </ul>
                    </Panel>
                    <Panel header="What’s Not Covered" key="2">
                        <ul className="red-dots">
                            <li>Pre-existing Conditions</li>
                            <li>Routine & Wellness</li>
                            <li>Preventive Care</li>
                            <li>Spaying or Neutering</li>
                            <li>Experimental Procedures</li>
                            <li>Breeding, Pregnancy or Birth</li>
                            <li>Cloning Procedures or Cloned Pets</li>
                            <li>Cosmetic Surgery</li>
                            <li>Coprophagia (“eating poop”)</li>
                        </ul>
                    </Panel>
                    <Panel header="Deductible & Coinsurance" key="3">
                        <ul>
                            <li>
                                <Paragraph className="mb-2"><span className="text-light-green">Deductible:</span> it is the fixed portion of the vet bill which the pet parent is responsible for.</Paragraph>
                                <Paragraph className="mb-2">With OSLO, the deductible is annual and you may choose between 3 options: AED 500, AED 1,000 or AED 2,000.</Paragraph>
                            </li>
                            <li>
                                <Paragraph className="mb-2"><span className="text-light-green">Coinsurance:</span> it is the portion of the vet bill which the pet parent will be responsible for vs. what the pet insurance will pay.</Paragraph>
                                <Paragraph className="mb-2">At OSLO, you may choose between 3  reimbursement level options: 90%, 80% or 70%.</Paragraph>
                            </li>
                        </ul>
                    </Panel>
                    <Panel header="Waiting Periods" key="4">
                        <ul>
                            <li>
                                <Paragraph className="mb-2">It is the period of time right after you sign up but before full coverage starts.</Paragraph>
                                <Paragraph className="mb-2">At OSLO, it lasts 14 days for illnesses, but only 5 days for accidents. Again orthopedic conditions coverage starts after 6 months.</Paragraph>
                            </li>
                        </ul>
                    </Panel>
                </Collapse>
            </Fragment>
        );
    }
}

export default Sidebar;