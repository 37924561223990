import React, { Fragment } from 'react';
import Routes from './routes'
import { ToastContainer } from 'react-toastify';
import './assets/css/App.css';
import './assets/css/bootstrap.css';
import './assets/font-awesome-4.7.0/css/font-awesome.min.css';
import './assets/css/responsive.css';
import 'react-toastify/dist/ReactToastify.css';
import toastify from './helpers/notifications';
import ReminderNotification from './components/common/reminderNotification';
import { getUpcomingReminders } from './api/pets-api';
import { getToken } from './helpers/utilities';
import 'mapbox-gl/dist/mapbox-gl.css';


function App() {

  const fetchAndDisplayReminders = async () => {
    if(getToken()){
      let reminders = await getUpcomingReminders();
      reminders = reminders?.data?.result
  
      if(reminders)
      for (let reminder of reminders) {
        toastify(<ReminderNotification
          title={reminder.title}
          description={reminder.description}
        />, reminder.id)
      }
  
      console.log("Notifications updated!", reminders);
    }
  }

  fetchAndDisplayReminders();

  setInterval(async function () {
    fetchAndDisplayReminders();
  }, 30000)




  return (
    <Fragment>
      <Routes />
      <ToastContainer
        autoClose={0}
      />
    </Fragment>
  );
}

export default App;
