import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Steps, Button, Input, TimePicker } from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { Step } = Steps;
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';


class CalendarDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimDate: moment(new Date()).format(dateFormat),
            claimTime: moment(new Date()).format(timeFormat),
        }
    }

    componentDidMount() {
        this.props.populateClaimObj('incidentDate', this.props.claimObj.incidentDate || this.state.claimDate);
        this.props.populateClaimObj('incidentTime', this.props.claimObj.incidentTime || this.state.claimTime);
        this.props.claimObj.incidentDate && this.setState({ claimDate: this.props.claimObj.incidentDate })
        this.props.claimObj.incidentTime && this.setState({ claimTime: this.props.claimObj.incidentTime })
    }

    onChangeHandler = (date) => {
        // console.log('datesadasd', date);
        this.setState({
            claimDate: moment(date).format(dateFormat)
        }, () => {
            this.props.populateClaimObj('incidentDate', this.state.claimDate);
        })
    }

    // onTimeChange = (t) => {
    //     console.log('datesadasd', moment(t).format('HH:mm:ss'));
    //     // let time = moment(t).format('HH:mm:ss');
    //     this.setState({
    //         claimTime: moment(t).format(timeFormat)
    //     }, () => {
    //         this.props.populateClaimObj('incidentTime', this.state.claimTime);
    //     })
    // }

    onTimeChange = (t) => {
        const formattedTime = t ? moment(t).format(timeFormat) : "00:00"; // Set to "00:00:00" if t is undefined
        console.log('Formatted Time:', formattedTime);
    
        this.setState(
            { claimTime: formattedTime },
            () => {
                this.props.populateClaimObj('incidentTime', this.state.claimTime);
            }
        );
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">
                        {

                            this.props.value === "lost"
                                ?
                                <div> When did your pet buddy went missing?</div>
                                :
                                this.props.value === "assistance"
                                    ?
                                    <div> When were you admitted to hospital?</div>
                                    :
                                    this.props.value === "passed_away"
                                        ?
                                        <div> When were your pet passed away?</div>
                                        :
                                        this.props.value === "cancel_vacation"
                                            ?
                                            <div> When were your pet buddy showed symptoms of accident or illness?</div>
                                            :
                                            <div>When did you go to the vet?</div>
                        }
                    </Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={15} justify="center">
                                <Col xl={8} lg={16} md={12} sm={20} xs={24}>
                                    {
                                        this.props.value === "lost" ?
                                            <Title level={4} className="text-left text-green">Date</Title>
                                            :
                                            <Fragment />
                                    }
                                    <input readOnly={true} className="custom-datefield ant-input" placeholder="Basic usage" value={this.state.claimDate} />
                                </Col>
                                {this.props.value === 'lost' &&
                                    <Col xl={4} lg={8} md={4} sm={4} xs={24}>
                                        {
                                            this.props.value === "lost" ?
                                                <Title level={4} className="text-left text-green">Time</Title>
                                                :
                                                <Fragment />
                                        }

                                        <TimePicker format={timeFormat} value={moment(this.state.claimTime, "HH:mm")} onChange={this.onTimeChange} className="timepicker" />
                                    </Col>
                                }
                            </Row>
                            <DatePicker
                                inline
                                dateFormat={dateFormat}
                                onChange={(date) => this.onChangeHandler(date)}
                                value={moment(this.state.claimDate)}
                                maxDate={moment().toDate()}
                                minDate={moment().subtract(3, 'months').toDate()}
                                disabledDate={(current) => {
                                    return current > moment();
                                }}
                            />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('date')}>
                                        <i className="fa fa-angle-left"></i>
                                    </Button>
                                </Col>
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    {/* disabled={!this.props.value!=='lost' || this.props.claimObj?.incidentTime}  */}
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('date')}>
                                        <i className="fa fa-angle-right"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">Diggin’ a bit further</span>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default CalendarDatePicker;