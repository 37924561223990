import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Radio, Table, Progress, Tooltip } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import MainLayout from '../../layouts/mainLayout'
import RenewalMainPage from '../getQoutes/renewalMainPage'
import Index from '../getQoutes/index';
import { getPetBreeds, getPlans, getPolicyByIdForAdmin, getUserImage } from '../../api/pets-api';
import { notifications, upperCase, applyDiscountVat, convertAED } from '../../helpers/utilities';
import { PolicyStatus } from '../../api/constants';
import { Spin } from 'antd';
import moment from 'moment';


class RenewPolicyFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            policyId: this.props.match.params.policyid,
            qouteObj: {},
            finalQoute: {},
            showFlag: 'main',
            petTypeBreeds: [],
            plans: [],
            paymentSuccess: false,
            paymentFailed: false,
            policyNumber: '',
            spinning: false,
            tip: '',
            currentLeadId:'',
            qouteRef : null,
            policySerial:'',
            policy:{},
            policyStatus: ''
        };
        this.populateQoute = this.populateQoute.bind(this);
        this.changeFlag = this.changeFlag.bind(this);
        
    }
    
    setQouteRef = (qouteRef) =>{
        this.setState({qouteRef})
    }
    populateQoute(obj){
        this.setState({qouteObj:{...this.state.qouteObj, ...obj}})
        console.log('quoteObj', this.state.qouteObj);
        localStorage.setItem('quoteObj', JSON.stringify(this.state.qouteObj));
        
    }
    changeFlag(val){
        this.setState({showFlag:val})
        console.log(this.state.qouteObj);        
    }
    setFinalQoute = (qoute) => {
        this.setState({finalQoute:qoute});      
    }
    getFinalQoute = () => {
        return this.state.finalQoute;      
    }
    setCurrentLeadId = (leadId) => {
        this.setState({currentLeadId:leadId});   
    }

    async getPolicy(id){
        try{
            let respo = await getPolicyByIdForAdmin(id);
            console.log(respo);
            let policy = {};
            if(respo.status == 200){
                policy = respo.data.result[0];
                var stringPolicyCode = '0000000000' + policy.policySerial;
                var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
                policy.policySerialNumber = 'OSLO-'+policy.policyCurrency.toUpperCase()+'-'+policy.policyType+'-'+delimitedSerial
                
                let benefits = policy.benefits == 5000 ? 20000 : policy.benefits == 10000 ? 35000 : 70000
                
                policy.benefits = benefits;

                let status = policy.status == PolicyStatus.submitted ? "Just Purchased" : policy.status == PolicyStatus.policy_doc_sent ? "Policy Document Sent" : policy.status == PolicyStatus.pet_tag_sent ? "Pet Tag Sent" : "";

                var dateOfBirth = moment(policy.dob);
                var dateOfPurchase = moment(policy.createdAt);

                let policyActBasedDate = dateOfPurchase;
                let dateCopy = dateOfPurchase;

                var diff = dateOfPurchase.diff(dateOfBirth, 'months');
                if(diff < 2){
                    policyActBasedDate = (dateOfBirth.add(2, 'M'));
                    dateCopy = (dateOfBirth.add(2, 'M'));
                }

                let activationDate = dateCopy.toDate();
                activationDate.setDate(policyActBasedDate.toDate().getDate() + 1);

                let illnessCoverDate = dateCopy.toDate();
                illnessCoverDate.setDate(policyActBasedDate.toDate().getDate() + 14);

                let kneeCoverDate = dateCopy.toDate();
                kneeCoverDate.setMonth(policyActBasedDate.toDate().getMonth() + 6);

                //policy.effectiveDate = activationDate;
                policy.illnessCoverDate = illnessCoverDate;
                policy.kneeCoverDate = kneeCoverDate;


                if(policy.petImage){
                    try{
                        await getUserImage(policy.petImage).then((data) => {
                            policy.petImage = data.data.url;
                        })
                    } catch(ex) {
                        policy.petImage = "/images/oslo-badge-new.png";
                    }
                }else{
                    policy.petImage = "/images/oslo-badge-new.png";
                }
                


                this.setState({policy:policy, policySerial:policy.policySerialNumber, policyStatus:status});
                

                console.log(policy);
            }else{
                this.setState({policy:{}});
            }
        }catch(ex){
            this.setState({policy:{}});
        }
    }

    async componentWillMount(){
        this.setState({ spinning: true, tip: "Loading..." });
        let resp = await getPetBreeds();
        let petTypeBreeds = resp.data.result;
        this.setState({petTypeBreeds})
        let plans = await getPlans();
        this.setState({plans: plans.data.result});
        this.setState({ spinning: false, tip: "" });

        console.log(this.props.match.path == "/renew_policy/:policyid")

        if(this.props.match.path == "/renew_policy/:policyid"){
            await this.getPolicy(this.state.policyId);
        }
        
    }

    render() {
        return (
            <Fragment>
                
                <MainLayout>
                
                    { this.state.showFlag === 'main' && this.state.petTypeBreeds && <RenewalMainPage plans={this.state.plans} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} spinning={this.state.spinning} tip={this.state.tip} setQouteRef={this.setQouteRef} setLeadId={this.setCurrentLeadId} addNewPet={false} renewPolicy={true} policy={this.state.policy} {...this.props}/>}

                    { this.state.showFlag === 'qoute' && <Index plans={this.state.plans} setFinalQoute={this.setFinalQoute} getFinalQoute={this.getFinalQoute} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} payementSuccess={this.state.paymentSuccess} paymentFailed={this.state.paymentFailed} setQouteRef={this.setQouteRef} hideUserInfo={false} renewPolicy={true} policy={this.state.policy} addNewPet={false} policyNumber={this.state.policyNumber}/>}
                
                    </MainLayout>
                
            </Fragment>
        );
    }
}

export default RenewPolicyFlow;