import React, { Component, Fragment } from 'react';
import { Layout, Row, Col, Image, Menu, Button, Dropdown, Drawer, Space } from 'antd';
import { DownOutlined, MenuOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { getUser } from '../../helpers/utilities';
import searchIcon from '../../assets/images/icon-search-red.svg'
import resourceIcon from '../../assets/images/icon-file-green.svg'
const { Header } = Layout;

const VetsDropdown = (
    <Menu className="dropdown-link">
        <Menu.Item key="2">
            <Link className="text-red font-600" to={"/find_vets"}>
                <img
                    className="img-fluid icon-search"
                    src={searchIcon}
                />Find a vet
            </Link>
        </Menu.Item>
        <Menu.Item key="1">
            <Link className="text-light-green font-600" to={"/pet_respurce"}>
                <img
                    className="img-fluid icon-file"
                    src={resourceIcon}
                /> Resources for vets
            </Link>
        </Menu.Item>
    </Menu>
);

class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activemenu: false,
            visible: false,
            qouteBtnFlag: false,
            placement: 'left'
        };
    }

    componentDidMount() {
        if (this.props.qouteFlag !== null && this.props.qouteFlag.current !== null && !this.state.qouteBtnFlag) {
            this.setState({ qouteBtnFlag: true });
        } else if (!this.props.qouteFlag === null && this.props.qouteFlag.current === null && this.state.qouteBtnFlag) {
            this.setState({ qouteBtnFlag: false });
        }
        console.log('user ', getUser(), this.props.qouteFlag);

    }
    componentDidUpdate(prevProps) {
        if (this.props.qouteFlag !== null && this.props.qouteFlag.current !== null && !this.state.qouteBtnFlag) {
            this.setState({ qouteBtnFlag: true });
            console.log('user 1st', this.props.qouteFlag, this.props.qouteFlag.current, this.state.qouteBtnFlag);
        } else if (this.props.qouteFlag === null && this.state.qouteBtnFlag) {
            this.setState({ qouteBtnFlag: false });
            console.log('user 2nd', this.props.qouteFlag, this.state.qouteBtnFlag);
        }
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    render() {
        const { placement, visible } = this.state;
        return (
            <Fragment>
                <Header className={"header"} style={{ position: 'sticky', top: 0, zIndex: 1000, width: '100%' }}>
                    <div >
                        <Row gutter={15} align="middle">
                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <div className="logo mx-4 show-on-small-screens">
                                    <Link to={'/'}><Image
                                        preview={false}
                                        className="img-fluid"
                                        alt='logo'
                                        src="/images/logo.png"
                                    /></Link>
                                </div>
                                <Space align='center show-large'>
                                    <div className="logo mx-4 ">
                                        <Link to={'/'}><Image
                                            preview={false}
                                            className="img-fluid"
                                            alt='logo'
                                            src="/images/logo.png"
                                        /></Link>
                                    </div>
                                    <div className="navigation right-menu right-menu-holder">
                                        <Space align='center' className='me-4'>
                                            <Link className={window.location.pathname === '/pet_insurance' ? "active px-2 text-white menu-item" : "px-2 text-white menu-item"} to={"/pet_insurance"}>Pet Insurance</Link>
                                            <Link className={window.location.pathname === '/pet_cloud' ? "active px-2 text-white menu-item" : "px-2 text-white menu-item"} to={"/pet_cloud"}>Pet Cloud</Link>

                                            <Menu className="navigation" mode="horizontal">
                                                <Menu.Item key="vets" className={window.location.pathname === '/find_vets' || window.location.pathname === '/pet_respurce' || window.location.pathname === '/schedule_demo' ? "active" : ""}>
                                                    <Dropdown overlayClassName="find-vets" overlay={VetsDropdown} placement="bottomCenter" arrow>
                                                        <a href="#" onClick={e => e.preventDefault()}>
                                                            Vets <DownOutlined />
                                                        </a>
                                                    </Dropdown>
                                                </Menu.Item>
                                            </Menu>

                                        </Space>
                                    </div>
                                </Space>
                            </Col>
                            <Col xs={24} sm={18} md={12} lg={14} xl={14} className="show-large" align={"end"}>
                                <div className="navigation right-menu right-menu-holder">
                                    <Space align='center' className='me-4'>
                                        <Link className={window.location.pathname === '/about_us' ? "active px-2 text-white menu-item" : "px-2 text-white menu-item"} to={"/about_us"}>About Us</Link>
                                        <a href="https://osloinsurance.pet/blog/" target="_blank" className={window.location.pathname === '/blog' ? "active px-2 text-white menu-item" : "px-2 text-white menu-item"}>Blog</a>
                                        <Link className={window.location.pathname === '/contact_us' ? "active px-2 text-white menu-item" : "px-2 text-white menu-item"} to={"/contact_us"}>Contact Us</Link>
                                        <a className='px-2 menu-item' href="https://wa.me/97142461442?text=Hi%2C%20I%27d%20like%20to%20know%20more%20about%20Oslo%20Pet%20Insurance." target="_blank">
                                            <img src="/images/whatsapp-logo.png" alt="Whatsapp" />
                                        </a>
                                        <Link className='btn btn-custom btn-white btn-block mx-2' to={"/dashboard"}>{!getUser() ? 'Login' : 'Dashboad'}</Link>
                                        <Link to={"/found_lost_pet"} className='btn btn-custom btn-green-white btn-block mx-2'>
                                                <img
                                                    className="img-fluid me-3"
                                                    alt='tag red'
                                                    src="/images/tag-red.png"
                                                />
                                                Found a lost pet
                                            </Link>
                                    </Space>
                                </div>
                                
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <div className='header-right full-width px-4 show-on-small-screens'>
                                    <Row gutter={15} align="middle" className='mt-3'>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <a href="https://wa.me/97142461442?text=Hi%2C%20I%27d%20like%20to%20know%20more%20about%20Oslo%20Pet%20Insurance." className='btn btn-custom btn-green-white btn-block'>
                                                <Space align="middle">
                                                    <WhatsAppOutlined className="text-green" style={{ fontSize: '20px' }}/>
                                                    WhatsApp Us
                                                </Space>
                                                
                                            </a>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <a href="tel:+97142480561" className='btn btn-custom btn-green-white btn-block'>
                                                <img
                                                    className="img-fluid ml-3"
                                                    alt='tag red'
                                                    src="/images/icon-phone-md.png"
                                                />
                                                &nbsp;Call Us
                                            </a>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <a href="mailto:support@osloinsurance.pet" className='btn btn-custom btn-green-white btn-block'>
                                                <img
                                                    className="img-fluid ml-3"
                                                    alt='tag red'
                                                    src="/images/icon-envelop-solid.png"
                                                />
                                                &nbsp;Mail Us
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            {/* {this.state.qouteBtnFlag && <Col xs={24} sm={6} md={5} lg={4} xl={3} className="show-large">
                                <Button className="btn-dark-green rounded-100" block onClick={() => this.props.onGetQouteClick()}>
                                    <Link to='#'>GET A QUOTE</Link>
                                </Button>
                            </Col>} */}
                        </Row>
                    </div>
                    <div className="d-block d-sm-block d-md-none btn-menu" onClick={this.showDrawer}><MenuOutlined /></div>
                    <Drawer
                        className="side-menu"
                        title={
                            <div className="logo mb-0">
                                <Link to={'/'}><Image
                                    preview={false}
                                    alt='dashboard logo'
                                    className="img-fluid"
                                    src="/images/dashboard_logo.png"
                                /></Link>
                            </div>
                        }
                        placement={placement}
                        closable={false}
                        onClose={this.onClose}
                        visible={visible}
                        key={placement}
                        closable
                        footer={<Button className="btn-blue rounded-100" block><Link to={"/found_lost_pet"}>Found a lost pet</Link></Button>}
                    >
                        <Menu className="navigation" mode="vertical">
                            <Menu.Item key="/"><Link className={window.location.pathname === '/pet_insurance' ? "active" : ""} to={"/pet_insurance"}>Pet Insurance</Link></Menu.Item>
                            <Menu.Item key="pet_cloud">
                                <Link className={window.location.pathname === '/pet_cloud' ? "active" : ""} to={"/pet_cloud"}>Pet Cloud</Link>
                            </Menu.Item>
                            <Menu.Item key="vets" className={window.location.pathname === '/contact_us' ? "active" : ""}>
                                <Dropdown overlay={VetsDropdown} placement="bottomRight">
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        Vets <DownOutlined />
                                    </a>
                                </Dropdown>
                            </Menu.Item>
                            <Menu.Item key="aboutus">
                                <Link className={window.location.pathname === '/about_us' ? "active" : ""} to={"/about_us"}>About Us</Link>
                            </Menu.Item>
                            <Menu.Item key="blog">
                                <a href="https://osloinsurance.pet/blog/" target="_blank">Blog</a>
                            </Menu.Item>
                            <Menu.Item key="contacts">
                                <Link className={window.location.pathname === '/contact_us' ? "active" : ""} to={"/contact_us"}>Contact Us</Link>
                            </Menu.Item>
                            <Menu.Item key="login">
                                <Link className='btn btn-custom btn-green-white btn-block show-on-small-screens' to={"/dashboard"}>{!getUser() ? 'Login' : 'Dashboad'}</Link>
                            </Menu.Item>
                            <Menu.Item key="foundalostpet">
                                <Link to={"/found_lost_pet"} className="btn btn-custom btn-green-white btn-block show-on-small-screens">
                                    <img
                                        className="img-fluid ml-3"
                                        alt='tag red'
                                        src="/images/tag-red.png"
                                    />
                                     Found a lost pet
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Drawer>
                </Header>
            </Fragment>
        );
    }
}

export default header;