import React, { Component } from 'react';
import { Typography, Image, Row, Col, Button } from 'antd';
import MainLayout from '../layouts/mainLayout'
import { Link } from "react-router-dom";
const { Paragraph, Title } = Typography;

class pets_cloud extends Component {
    render() {
        return (
            <MainLayout>
                <div className="pets-cloud">
                    <div className="banner">
                        <img
                            className="img-fluid w-100"
                            src="/images/banner-pet-cloud.jpg"
                        />
                        <div className="banner-caption">
                            <div className="container">
                                <Row gutter={15}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={14}></Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                        <div className="text-center">
                                            <Title level={1} className="text-white tagline">Pet Cloud</Title>
                                            <Title level={3} className="text-white mt-0 mb-5 sub-tagline">Your very own space and <br /> a place you can call home</Title>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="full-width-section full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                                    <Title level={2} className="text-green mb-0 section-title">Your Pet Cloud</Title>
                                    <Title level={2} className="text-dark-green mb-5 font-300">Your pet’s life at your fingertips with our Pet Cloud.</Title>
                                </Col>
                            </Row>
                            <Row gutter={15} align="middle">
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <div className="points-toknow dot-center">
                                        <ul>
                                            <li>Access your pet profile</li>
                                            <li>Activate your pet’s tag</li>
                                            <li>Send a claim</li>
                                            <li>Talk to a vet (coming soon)</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Image
                                        preview={false}
                                        src="/images/laptop-screen1.png"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section pet-tags bg-gray-light full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Title level={2} className="text-green mb-0 section-title text-center">Activate your OSLO Pet Tag</Title>
                                </Col>
                            </Row>
                            <Row gutter={15} align="middle">
                                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                    <Title level={2} className="text-dark-green mb-4 font-300">
                                        Let us bring you peace of mind.<br />
                                        All OSLO customers receive an OSLO Pet Tag, which displays a unique ID number.
                                    </Title>
                                    <div className="points-toknow">
                                        <ul>
                                            <li>In case your pet strays, our Pet Tag will help you reunite with your pet faster!</li>
                                            <li>If your pet is found, the finding person will be able to send us the OSLO ID number through our website and we will inform you so you can reunite with your pet.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-center">
                                    <Image
                                        preview={false}
                                        src="/images/oslo-badge-new.png"
                                    />
                                    <div className="text-center mt-4">
                                        <Button type="link" className="btn-blue rounded-100" size="large">
                                            <Link to={"/found_lost_pet"}>
                                                <img
                                                    className="img-fluid mr-2"
                                                    src="/images/tag-red.png"
                                                />Found a lost pet
                                            </Link>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                                    <Title level={2} className="text-green mb-0 section-title">Send us your claim</Title>
                                    <Title level={2} className="text-dark-green mb-5 font-300">Sending a claim for reimbursement has never been so easy!</Title>
                                </Col>
                            </Row>
                            <Row gutter={15} align="middle">
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Image
                                        preview={false}
                                        src="/images/laptop-screen2.png"
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <div className="points-toknow dot-center">
                                        <ul>
                                            <li>Paperless process</li>
                                            <li>Only a few minutes needed</li>
                                            <li>Feedback within 3 days</li>
                                        </ul>
                                        <Button size="large" type="primary" className="btn-red mt-3">
                                            <Link to={"/login"} className="no-text-decoration">Submit Claim</Link>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section pet-tags bg-gray-light full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Title level={2} className="text-green mb-0 section-title text-center">Talk to a vet</Title>
                                </Col>
                                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                    <Title level={2} className="text-dark-green mb-5 font-300">
                                        Pet emergencies happen. <br />
                                        Discover our brand new Pet Cloud Live Vet feature.
                                    </Title>
                                    <div className="points-toknow dot-center">
                                        <ul>
                                            <li>Direct access to a professional vet</li>
                                            <li>Know what you need to do instantly</li>
                                            <li>Anytime, anywhere.</li>
                                        </ul>
                                    </div>
                                    
                                </Col>
                                <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center">
                                    <Title level={3} className="text-light-green text-center">
                                        <span className="coming-soon">Coming soon!</span>
                                    </Title>
                                    <br></br>
                                    <Image
                                        preview={false}
                                        src="/images/mobile-screen-live-vet.png"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </MainLayout >
        );
    }
}

export default pets_cloud;