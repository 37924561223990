import React, { Component } from 'react';
import { Typography, Form, Row, Col, Input, Button } from 'antd';
import MainLayout from '../layouts/mainLayout'
import PhoneInput from 'react-phone-input-2'
const { Title } = Typography;
const { TextArea } = Input;

class lost_pet extends Component {

    
    formRef = React.createRef();
    onTagFocus = () =>{
        this.formRef.current.setFieldsValue({
            oslo_tag: 'OSLO-',
        });
    }
    onTagBlur = () =>{
        this.formRef.current.setFieldsValue({
            oslo_tag: '',
        });
    }
    
    onFinish(values) {
        console.log(values);
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <MainLayout>
                <div className="pets-cloud">
                    <div className="banner">
                        <img
                            className="img-fluid w-100"
                            src="/images/banner-lost-pet.jpg"
                        />
                        <div className="banner-caption">
                            <div className="container">
                                <Row gutter={15}>
                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                        <div className="text-center">
                                            <Title level={1} className="text-white tagline">Found a lost <br /> OSLO pet?</Title>
                                            <Title level={3} className="text-white mt-0 mb-0 sub-tagline">You can help reunite him <br /> with his family!</Title>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="full-width-section full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                                    <Title level={2} className="text-green font-700 mb-0">Please fill the form below so we can contact you and help a lost buddy! </Title>
                                </Col>
                            </Row>
                            <Form
                                name="found_lost_pet"
                                size={"large"}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                className="customise-form"
                                ref={this.formRef}
                            >
                                <Row gutter={15} align="middle" justify="center">
                                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                        <div className="section-gray mt-5">
                                            <Title className="text-light-green" level={3}>Your contact information</Title>
                                            <Row gutter={15}>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="first_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your first name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"First Name *"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="last_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your last name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Last Name *"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item
                                                        name="email_address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Email Address*"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        size={"large"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your phone number',
                                                            },
                                                        ]}
                                                    >
                                                        <PhoneInput
                                                            inputStyle={{ width: '100%', height: '53px' }}
                                                            country={'ae'}
                                                            placeholder={"Add Phone number"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-gray mt-2">
                                            <Row gutter={15}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Title className="text-light-green" level={3}>
                                                        Found Pet’s Tag <img
                                                            className="img-fluid mr-2"
                                                            src="/images/tag-red.png"
                                                        />
                                                    </Title>
                                                    <Form.Item
                                                        name="oslo_tag"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter tag',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"OSLO-AA0000X *"} onFocus={this.onTagFocus} onBlur={this.onTagBlur}/>
                                                    </Form.Item>
                                                    <Title className="text-light-green" level={3}>
                                                        Where and when you found a lost pet?
                                                    </Title>
                                                    <Form.Item
                                                        className="mb-0"
                                                        name="comments"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your notes',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={8} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center mt-3">
                                            <Button type="primary" htmlType="submit" className="btn-red" size="large">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default lost_pet;