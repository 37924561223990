import React, { Component, Fragment } from 'react';
import { Typography, Button, Row, Col, Space, Card } from 'antd';
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';
import MainLayout from '../layouts/mainLayout'
import ReactPlayer from 'react-player'
import osloIcon from '../assets/images/oslo-badge-new.png'
import videoOslo from '../assets/images/about-us-oslo.webm'
import VideoPlayer from '../components/common/VideoPlayer'
const { Paragraph, Title } = Typography;

class about_us extends Component {

    constructor(){
        super();
        this.state = {
            url: '',
            playing: false,
            loop: false,
        }
    }

    handlePlayPause = () =>{
        if(!this.state.playing){
            this.setState({ url: videoOslo});
        }else{
            this.setState({ url: ''});
        }
        this.setState({ playing: !this.state.playing})
    }

    componentDidMount = () => {
        //this.setState({ url: videoOslo})
    }

    playerRef = React.createRef();

    render() {
        return (
            <Fragment>
                <MainLayout>
                    <div className="full-width-section p-0">
                        <Row className='m-0 p-0 bg-gray' align="middle" justify="center">
                        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                            <img
                                className="img-fluid w-100"
                                src="/images/oslo-about-us-banner.jfif"
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="bg-gray">
                            <div className="text-center about-us-banner-caption">
                                <Title level={1} className="text-green tagline">We are OSLO,</Title>
                                <Title level={2} className="text-green mt-0 sub-tagline">Unique Digital pet insurance<br />in Middle East and beyond</Title>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    <div className="full-width-section">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <Title level={2} className="text-green text-center mb-0">We are here to provide modern <span className='text-red'>pet parents</span> with clear choices with the <span className='text-red'>latest technology</span></Title>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section bg-gray">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Title level={1} className="text-green mb-0 text-center">A dog named Oslo</Title>
                                    <Title level={3} className='text-green text-center mb-0'><span className='title-thin'>Founded by pet parents who faced an unexpected incident with our pet and couldn’t get a pet insurance that fit our diverse lifestyles and needs.</span></Title>
                                    <Title level={3} className='text-green text-center mt-4 mb-0'><span className='title-thin'>Oslo was born.</span></Title>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <img
                                    className="img-fluid w-100"
                                    src="/images/about-us-what-oslo.jpg"
                                />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                <img
                                    className="img-fluid w-100"
                                    src="/images/about-us-oslo.jfif"
                                />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <Title level={1} className="text-green my-2 text-center">OSLO</Title>
                                    <Title level={3} className='text-green text-center mb-4'><span className='title-thin'>provides</span> exceptional pet protection.</Title>
                                    <Title level={3} className='text-green text-center mt-0 mb-4'><span className='title-thin'>We believe the</span> best medical care for our pets <span className='title-thin'>should be an automatic “Yes” without hesitation.</span></Title>
                                    <Title level={3} className='text-green text-center'><span className='title-thin'>We'll be</span> here for you and your pet<span className='title-thin'> when you require us the most.</span></Title>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section bg-gray">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Title level={1} className="text-green mb-3 text-center" text-align="center">Why choose OSLO?</Title>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <div class="points-toknow rounded-check">
                                                <ul className="text-green">
                                                    <li>It's Unique</li>
                                                    <li>Simple</li>
                                                    <li>Innovative</li>
                                                    <li>Fast</li>
                                                </ul>
                                            </div>
                                            
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <div class="points-toknow rounded-check">
                                            <ul className="text-green">
                                                <li>Transparent</li>
                                                <li>Easy to enroll</li>
                                                <li>Easy to access</li>
                                                <li>Comprehensive</li>
                                            </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                    {
                                        // !this.state.playing && 
                                        // <div className='video-overlay'>
                                        //     <div className="video-control-holder" >
                                        //         <PlayCircleFilled className="text-green txt-button" onClick={this.handlePlayPause}/>
                                        //     </div>
                                        // </div>
                                    }
                                    {
                                        // this.state.playing && 
                                        // <div className='video-overlay-pause'>
                                        //     <div className="video-control-holder" >
                                        //         <PauseCircleFilled className="text-green txt-button" onClick={this.handlePlayPause}/>
                                        //     </div>
                                        // </div>
                                    }
                                    {/* <ReactPlayer
                                    ref={this.playerRef}
                                    className='about-us-video'
                                    url={[
                                        {src:this.state.url, type:"video/webm"}
                                      ]}
                                    playing={this.state.playing}
                                    width='100%'
                                    height='100%'
                                    /> */}
                                    <VideoPlayer src={videoOslo} paused={this.state.playing} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                                    <Title level={1} className="text-green my-2">OSLO : every pet is unique</Title>
                                    <Title level={3} className='text-green'><span className='title-thin'>Our</span> purpose <span className='title-thin'>is to create a safe environment where your</span> cherished pet<span className='title-thin'> receives the</span> best treatment <span className='title-thin'>while you have</span> no worry <span className='title-thin'>about heavy vet bills.</span></Title> 
                                    <Title level={3} className='text-green'><span className='title-thin'>Our</span> objective <span className='title-thin'>was to build a</span> unique yet comprehensive product <span className='title-thin'>to assist in</span> relieving financial stress <span className='title-thin'>from pet owners’ life.</span></Title>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section bg-gray">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle" className='my-2'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                                    <Title level={1} className="text-green my-2"> Our Oslo’s Values</Title>
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Row>
                                        <Col span={3}>
                                            <img alt="example" className='img-fluid w-100 mt-3' src="/images/oslo-badge-new.png" />
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={20}>
                                            <Title level={4} className="text-green my-2">Simplicity</Title>
                                            <Paragraph>Insurance can be complicated to understand. At Oslo, at registering your pet details to requesting a reimbursment everything is a simple DIY process with zero paperwork.</Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Row>
                                        <Col span={3}>
                                            <img alt="example" className='img-fluid w-100 mt-3' src="/images/oslo-badge-new.png" />
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={20}>
                                            <Title level={4} className="text-green my-2">Passion</Title>
                                            <Paragraph>We love all things Pet and hence are extremely driven to get you the best protection for your furry family member. We truly believe that’s what they deserve. We try to keep up with innovation.</Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Row>
                                        <Col span={3}>
                                            <img alt="example" className='img-fluid w-100 mt-3' src="/images/oslo-badge-new.png" />
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={20}>
                                            <Title level={4} className="text-green my-2">Community</Title>
                                            <Paragraph>The happiest moment for us was when our fellow pet parents reffered us as “Oslo Fam”. Oslo community is built for the pet parents, by the pet parents and very much belongs to the pet parents. We are who we are because of this community.</Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Row>
                                        <Col span={3}>
                                            <img alt="example" className='img-fluid w-100 mt-3' src="/images/oslo-badge-new.png" />
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={20}>
                                            <Title level={4} className="text-green my-2">Transparency</Title>
                                            <Paragraph>Honesty and authenticity runs in Oslo team naturally. And that’s what we try to maintain at all times with our policyholders too. We believe this is what makes us approachable anytime, anyday.</Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="full-width-section bg-green">
                        <div className="container">
                            <Row gutter={0} justify="space-around" align="middle" className='mb-5'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                                    <Title level={1} className="text-white my-2">Meet Team OSLO!</Title>
                                </Col>
                            </Row>
                            <Row gutter={32} justify="center">
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} align='center'>
                                    <img alt="example" className='img-fluid w-100 mt-3 p-3' src="/images/about-us-oslo-ceo.jfif" />
                                    <Title level={5} className="text-white my-2 px-3">Emmanuel OSLO CEO<br/>Dog Executive Officer</Title>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} align='center'>
                                    <img alt="example" className='img-fluid w-100 mt-3 p-3' src="/images/about-us-oslo-legal.jfif" />
                                    <Title level={5} className="text-white my-2 px-3">Gladys CLO<br/>Cat Legal Officer</Title>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} align='center'>
                                    <img alt="example" className='img-fluid w-100 mt-3 p-3' src="/images/about-us-oslo-cto.jfif" />
                                    <Title level={5} className="text-white my-2 px-3">Eric CTO<br/>Donkey Technical Officer </Title>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} align='center'>
                                    <img alt="example" className='img-fluid w-100 mt-3 p-3' src="/images/about-us-oslo-underwriting.jfif" />
                                    <Title level={5} className="text-white my-2 px-3">Catherine CUO<br/>Cat Underwriting Officer</Title>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} align='center'>
                                    <img alt="example" className='img-fluid w-100 mt-3 p-3' src="/images/about-us-oslo-ops.jfif" />
                                    <Title level={5} className="text-white my-2 px-3">Maisa COO<br/>Dog Operations Officer</Title>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="banner pets-cloud">
                        <img
                            className="img-fluid w-100"
                            src="/images/about-us-footer-bg.jfif"
                        />
                        <div className="banner-caption">
                            <div className="container">
                                <Row gutter={15}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                        
                                    </Col>
                                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                        <div className="text-center">
                                            <Title level={1} className="text-white tagline">Where we are</Title>
                                            <Title level={2} className="text-white mt-0 mb-5 sub-tagline">Based in Dubai, UAE we are from<br/>all over the world with the option<br/>to work from home to spend much<br/>of our time with our loved one</Title>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            </Fragment>
        );
    }
}

export default about_us;