import React, { Component, Fragment } from 'react';
import { Layout, Typography, Button, Row, Col, Card, Input, Form, Modal, Table, Upload, message, Select, Popconfirm, Divider, Space, notification } from 'antd';
import { SearchOutlined, DownloadOutlined, UploadOutlined, PlusOutlined, MailOutlined, PhoneOutlined, EditOutlined, SaveOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getPolicyByIdForAdmin, SavePolicyDocumentSentStatusByIdForAdmin, SavePetTagSentStatusByIdForAdmin, getClaimByIdForAdmin, InitiateClaimByIdForAdmin, updateClaim, InitiateCalculateClaimByIdForAdmin, getUserImage, SendEmailMessage, GetVets, AssignVetToClaim, SaveVetAndAssignToClaim, InitiateACall, SaveClaimVetIdentifiedReason, SavePreExistingConditions, GetPolicyAndClaimHealthInfo } from '../../api/pets-api';
import { ClaimStatus, PolicyStatus, UPLOAD_CLAIM_DOCS_WITHOUT_DELETING_API } from '../../api/constants';
import moment from 'moment';
import { notifications, getToken, fixPhoneNumber } from "../../helpers/utilities";


const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;


class AdminClaimDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policyId:'',
            policySerial:'',
            policy:{},
            policyStatus: '',
            claim:{},
            claimSerial:'',
            claimStatus: '',
            visible:false,
            isApprove:false,
            claimId:'',
            vets:[],
            openPopUp: false,
            showAddVet: false,
            showSetVet: false,
            selectedVet:{id:1, clinicName:'', phone:'', emailAddress:'', area:''},
            showSendMessage:false,
            selectedMail:'',
            showInitiateACall:false,
            selectedPhoneNo:'',
            editVetAnalizedReason:false,
            editPreExistingCondition:false,
            editVetAnalizedLoader:false,
            editPreExistingConditionLoader:false,
            policyHealth:{}
        };
        this.approveFormRef = React.createRef();
        this.petTagFormRef = React.createRef();
        this.addVetFormRef = React.createRef();
        this.setVetFormRef = React.createRef();
        this.sendMessageFormRef = React.createRef();
        this.initiateACallFormRef = React.createRef();
        this.inputRef = React.createRef();
        this.setVetAnalizedReasonFormRef = React.createRef();
        this.setPreExistingConditionFormRef = React.createRef();
    }

    handleSendMessage = async (values) => {
        console.log('Email Values',values);
        let mailObj = {
            email:this.state.selectedMail, 
            subject:values.Subject, 
            message:values.Message
        }
        let result = await SendEmailMessage(mailObj);
        console.log('result', result);
        notifications('Email Sent!.', 'Alert', 'success');
        this.setState({showSendMessage:false});
    }

    handleSendMessageCancel = () => {
        this.setState({showSendMessage:false})
    }

    showSendMessageConfirm = (email) => {
        this.setState({showSendMessage:true, selectedMail:email})
    }

    handleInitiateACall = async (values) => {
        console.log('Call Values',values);
        let callObj = {
            phoneNo:this.state.selectedPhoneNo, 
            agentId:values.agentId
        }
        let result = await InitiateACall(callObj);
        console.log('result', result);
        notifications('Call request registered. Please check Ziwo agent app!.', 'Alert', 'success');
        this.setState({showInitiateACall:false});
    }

    handleInitiateACallCancel = () => {
        this.setState({showInitiateACall:false})
    }

    showInitiateACallConfirm = (phoneNo) => {
        this.setState({showInitiateACall:true, selectedPhoneNo:phoneNo})
    }

    loadClaimProcessByVetModel(isApp){
        this.setState({visible:true, isApprove:isApp})
    }

    handleCancel = () => {
        this.setState({visible:false})
    }

    handlePopUpOk = () => {

    }

    handlePopUpCancel = () => {
        this.setState({openPopUp:false})
    }

    showPopconfirm = () => {
        this.setState({openPopUp:true})
    }

    showAddVetForm = () => {
        this.setState({showAddVet:true})
    }

    hideChangeVet = () => {
        this.setState({showAddVet:false, showSetVet:false})
    }

    showSetVet = () => {
        this.setState({showSetVet:true})
    }

    confirmSetVet = async (values) => {
        let claimId = this.state.claimId;
        let vetId = values.vetClinicName;

        let result = await AssignVetToClaim({claimId, vetId});
        if(result.data.result){
            await this.getClaim(claimId);
        }
    }

    confirmAddVet = async (values) => {
        console.log(values);

        let selectedVet = {
            clinicName:values.vetClinicName, 
            phone:values.vetClinicContactNo, 
            emailAddress:values.vetClinicContactEmail, 
            area:values.vetClinicArea
        };
        let claimId = this.state.claimId;

        var obj = {
            vet:selectedVet,
            claimId
        }

        let result = await SaveVetAndAssignToClaim(obj);
        if(result.data.result){
            await this.getClaim(claimId);
            await this.getVets();
        }
        this.hideChangeVet();
    }

    formSetApproveDetails = async (values) => {
        console.log('submitted values', values);
        let claimObj = this.state.claim;
        //console.log('claims', claimObj);
        claimObj.claimSerial = claimObj.serial;
        if(this.state.isApprove){
            claimObj.vetApprovedAmount = values.ApprovedAmount;
            claimObj.vetProcessedDate = new Date();
            claimObj.commentsByVet = values.Comment;
            claimObj.status = 'approved_by_vet';
        }
        else {
            claimObj.status = 'declined_by_vet';
            claimObj.vetProcessedDate = new Date();
            claimObj.commentsByVet = values.Comment;
            claimObj.vetApprovedAmount = 0;
        }
        let result = await InitiateCalculateClaimByIdForAdmin(claimObj);
        console.log('result', result);
        this.setState({visible:false});
        if(result.data.result){
            window.location.reload();
        }
    }


    formApproveDetailsFailed = (e) => {
        console.log("Error", e);
    }

    async componentDidMount() {
        
    }

    async componentWillMount(){
        var splittedPath = this.props.location.pathname.split('/');
        console.log(splittedPath[splittedPath.length - 1]);
        let id = splittedPath[splittedPath.length - 1];
        this.setState({claimId:id});
        await this.getClaim(id);

        await this.getVets();
        

    }

    async getVets(){
        let vetsFromDb = await GetVets();
        console.log('Vets', vetsFromDb.data.result);
        if(vetsFromDb.data.result){
            await this.setState({vets:vetsFromDb.data.result});
        }
    }

    async getClaim(id){
        try{
            let respo = await getClaimByIdForAdmin(id);
            console.log(respo);
            let claim = {};
            if(respo.status == 200){
                claim = respo.data.result[0];
                let claimSerial = "C1/" + moment(claim.createdAt).format('YYYY/MM')+ "/00000" + claim.serial;
                claim.claimSerial = claimSerial;

                let otherClaimsProcessed = claim.otherClaims.map((itm) => {
                    itm.claimSerial = "C1/" + moment(itm.createdAt).format('YYYY/MM')+ "/00000" + itm.serial;
                    return itm;
                })
                
                claim.otherClaims = otherClaimsProcessed;

                let status = claim.status == ClaimStatus.new_claim ? "New Claim" : claim.status == ClaimStatus.in_process ? "In Progress" : claim.status == ClaimStatus.accepted ? "Accepted" : "Rejected";

                let policy = claim.policy[0];
                var stringPolicyCode = '0000000000' + policy.policySerial;
                var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
                policy.policySerialNumber = 'OSLO-'+policy.policyCurrency.toUpperCase()+'-'+policy.policyType+'-'+delimitedSerial
                
                let benefits = policy.benefits == 5000 ? 20000 : policy.benefits == 10000 ? 35000 : 70000
                
                policy.benefits = benefits;

                let claimDs = claim.claimDocs;

                let claimDPrefined = await Promise.all(claim.claimDocs.map(async (item) => {
                    try{
                        await getUserImage(item.accessPath).then((data) => {
                            item.accessPath = data.data.url;
                        })
                    } catch(ex) {
                        item.accessPath = item.accessPath;
                    }
                }))

                var dateOfBirth = moment(policy.dob);
                var dateOfPurchase = moment(policy.createdAt);

                let policyActBasedDate = dateOfPurchase;
                let dateCopy = dateOfPurchase;

                var diff = dateOfPurchase.diff(dateOfBirth, 'months');
                if(diff < 2){
                    policyActBasedDate = (dateOfBirth.add(2, 'M'));
                    dateCopy = (dateOfBirth.add(2, 'M'));
                }

                let activationDate = dateCopy.toDate();
                activationDate.setDate(policyActBasedDate.toDate().getDate() + 6);

                let illnessCoverDate = dateCopy.toDate();
                illnessCoverDate.setDate(policyActBasedDate.toDate().getDate() + 14);

                let kneeCoverDate = dateCopy.toDate();
                kneeCoverDate.setMonth(policyActBasedDate.toDate().getMonth() + 6);

                policy.effectiveDate = activationDate;
                policy.illnessCoverDate = illnessCoverDate;
                policy.kneeCoverDate = kneeCoverDate;

                let vetData = {};
                let ifShowSetVet = false;

                if(claim.vetId){
                    vetData = claim.vetData;
                } else {
                    ifShowSetVet = true;
                }

                let policyHealth = await GetPolicyAndClaimHealthInfo(policy.policyId);

                this.setState({claim:claim, claimSerial:claim.claimSerial, claimStatus:status, policy:policy, policySerial:policy.policySerialNumber, selectedVet:vetData, showSetVet: ifShowSetVet, policyHealth:policyHealth.data.result});
                
                console.log(claim);
            }else{
                this.setState({claim:{}});
            }
        }catch(ex){
            this.setState({claim:{}});
        }
    }


    initiateClaimProcess = async () => {
        let id = this.state.claim.id;
        let res = await InitiateClaimByIdForAdmin(id);
        if(res.data.result){
            window.location.reload();
        }
    }

    savePolicyDocSentDate = async (values) => {
        let data = {
            id:this.state.policy.policyId,
            status:PolicyStatus.policy_doc_sent,
            date:moment(values.documentSentDate).format('YYYY/MM/DD')
        }
        let res = await SavePolicyDocumentSentStatusByIdForAdmin(data);
        if(res.data.result){
            window.location.reload();
        }
    }

    savePetTagSentDate = async (values) => {
        let data = {
            id:this.state.policy.policyId,
            status:PolicyStatus.pet_tag_sent,
            date:moment(values.documentSentDate).format('YYYY/MM/DD')
        }
        let res = await SavePetTagSentStatusByIdForAdmin(data);
        if(res.data.result){
            window.location.reload();
        }
    }

    navigateToClaim(claimNo){
        console.log('Claim Number', claimNo);
        //window.location.pathname = '/admin_claims/'+claimNo;
        window.open('/admin_claims/'+claimNo, '_blank')
    }

    uploadProps = {
        name:'files',
        headers:{
            authorization: `Bearer ${getToken()}`
        },
        onChange(info) {
            if(info.file.status !== 'uploading'){
                console.log(info.file, info.fileList);
            }
            if(info.file.status === 'done'){
                message.success(`${info.file.name} file uploaded successfully.`)
                window.location.reload();
            } else if (info.file.status === 'done'){
                message.error(`${info.file.name} file upload failed.`)
            }
        }
    }

    enableVetAnalizedReason = () => {
        this.setState({editVetAnalizedReason:true})
    }

    enablePreExistingCondition = () => {
        this.setState({editPreExistingCondition:true})
    }

    updateVetAnalizedReason = async (values) => {
        this.setState({editVetAnalizedLoader:true});
        let data = {
            claimId:this.state.claim.id,
            vetIdentifiedCause:values.vetIdentifiedCause
        }
        let res = await SaveClaimVetIdentifiedReason(data);
        if(res.status == 200){
            notifications('Vet Analized Reason Updated.', 'Alert', 'success');
            this.setState({editVetAnalizedLoader:false, editVetAnalizedReason:false, claim:{...this.state.claim, vetIdentifiedCause:values.vetIdentifiedCause}});
        }else{
            notifications('Error Occured. Please try again.', 'Alert', 'error');
            this.setState({editVetAnalizedLoader:false});
        }
        
    }

    updatePreExistingCondition = async (values) => {
        this.setState({editPreExistingConditionLoader:true});
        let data = {
            id:this.state.claim.petId,
            preExistingCondition:values.preExistingCondition
        }
        console.log(data);
        let res = await SavePreExistingConditions(data);
        if(res.status == 200){
            notifications('Pre Existing Conditions Updated.', 'Alert', 'success');
            this.setState({editPreExistingConditionLoader:false, editPreExistingCondition:false, policy:{...this.state.policy, preExistingCondition:values.preExistingCondition}});
        }else{
            notifications('Error Occured. Please try again.', 'Alert', 'error');
            this.setState({editPreExistingConditionLoader:false});
        }
    }

    disableVetAnalizedReason = () => {
        this.setState({editVetAnalizedReason:false})
    }

    disablePreExistingCondition = () => {
        this.setState({editPreExistingCondition:false})
    }

    render() {

        const columns = [
            {
                title: 'Claim',
                dataIndex: 'claimSerial',
                render: (claimSerial) => <Fragment key={1}>
                    
                    <Paragraph className="text-muted p-0 mb-0"> {claimSerial} </Paragraph>
                </Fragment>
            },
            {
                title: 'What Happen',
                dataIndex: 'whatHappen',
                render: whatHappen => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{whatHappen?.toUpperCase()}</Paragraph>
                </Fragment>
            },
            {
                title: 'Incid. Date',
                dataIndex: 'incidentDate',
                render: incidentDate => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{moment(incidentDate).format('YYYY/MMM/DD')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: status => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{status.replace('_',' ').toUpperCase()}</Paragraph>
                </Fragment>
            },
            {
                title: 'View More',
                dataIndex: 'id',
                render: (id) => <Fragment key={2}>
                    <Button htmlType="button" type="primary" shape="circle" onClick={() => this.navigateToClaim(id)}  >
                        <SearchOutlined  />
                    </Button>
                </Fragment>
            },
        ];

        return (
            <Layout className="dashboard claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Claims</Title>
                    <Title level={3} className="text-green font-600 pt-1 ps-3 m-0">{this.state.claimSerial}</Title>
                    <Title level={4} className="text-red font-600 pt-1 ps-3 m-0">{this.state.claimStatus}</Title>
                </div>
                <div className="m-2">
                    <Row gutter={15}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card title="Claim Details" className='full-width p-2'>
                                <Title level={5} className="text-green font-600 pt-1 mt-2">Claim</Title>
                                <hr/>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Client Reason For Claim</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.claim.whatHappen?.toUpperCase()}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10} className='my-2'>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Vet Analized Reason For Claim</span>
                                    </Col>
                                    {
                                        this.state.editVetAnalizedReason && <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                            <Form
                                                    name="basic"
                                                    className="customise-form"
                                                    ref={this.setVetAnalizedReasonFormRef}
                                                    onFinish={this.updateVetAnalizedReason}
                                                    initialValues={{vetIdentifiedCause:this.state.claim.vetIdentifiedCause}}
                                                >
                                                <Space align='center'>
                                                    <Form.Item
                                                        className='mb-0'
                                                        name="vetIdentifiedCause"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please select vet analized reason',
                                                            },
                                                        ]}
                                                    >
                                                        <Select className='admin-edit-box' placeholder="Select Vet Clinic *"
                                                                            showSearch
                                                                            allowClear
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            filterSort={(optionA, optionB) =>
                                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                            }
                                                                            dropdownRender={(menu) => (
                                                                                <>
                                                                                {menu}
                                                                                </>
                                                                            )}
                                                                        >
                                                                            <Option key="0" value="Accident">Accident</Option>
                                                                            <Option key="1" value="Illness">Illness</Option>
                                                                            <Option key="2" value="Lost My Pet">Lost My Pet</Option>
                                                                            <Option key="3" value="Pet Assistance">Pet Assistance</Option>
                                                                            <Option key="4" value="Pet Passed Away">Pet Passed Away</Option>
                                                                            <Option key="5" value="Cancel My Vacation">Cancel My Vacation</Option>

                                                        </Select> 
                                                    </Form.Item>
                                                    {this.state.editVetAnalizedLoader && <LoadingOutlined className='text-green' />} 
                                                    <Form.Item className='mb-0'>
                                                        {!this.state.editVetAnalizedLoader && <Button type="primary" htmlType='submit' shape="circle" size='small' icon={<SaveOutlined />}/>}
                                                    </Form.Item>
                                                    <Form.Item className='mb-0'>
                                                    {!this.state.editVetAnalizedLoader && <Button type="danger" shape="circle" size='small' icon={<CloseCircleOutlined onClick={() => this.disableVetAnalizedReason()} />} /> }
                                                    </Form.Item>
                                                        
                                                </Space>
                                            </Form>
                                        </Col>
                                    }
                                    {
                                        !this.state.editVetAnalizedReason && <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                            <Space align='center'>
                                                <span>{this.state.claim.vetIdentifiedCause}</span>
                                                <Button type="primary" shape="circle" size='small' icon={<EditOutlined />} onClick={() => this.enableVetAnalizedReason()} />
                                            </Space>
                                        </Col>
                                    }
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Incident Date</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.claim.incidentDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Reported Date</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.claim.createdAt).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Comment</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.claim.comments}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Amount</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>AED {this.state.claim.amount}</span>
                                    </Col>
                                </Row>
                                {
                                    this.state.claim.claimDocs?.length > 0 &&
                                    <div>
                                        <Title level={5} className="text-green font-600 pt-1 mt-2">Documents</Title>
                                        <hr/>
                                        <div>
                                            {
                                                this.state.claim.claimDocs?.map((claimDoc) => {
                                                    return (
                                                        <Button className='my-2' type="dashed" icon={<DownloadOutlined />} href={claimDoc.accessPath}>
                                                            {claimDoc.name}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    // [ClaimStatus.accepted, ClaimStatus.rejected, ClaimStatus.paid, ClaimStatus.approved_by_vet, ClaimStatus.declined_by_vet].includes(this.state.claim.status) &&
                                    <div>
                                        <Title level={5} className="text-green font-600 pt-1 mt-2">Vet Clinic Information</Title>
                                        <hr/>
                                    {
                                        !(this.state.showAddVet || this.state.showSetVet) &&
                                        <div>

                                    
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Clinic Name</span>

                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.selectedVet.clinicName}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Area</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.selectedVet.area}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Email</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.selectedVet.emailAddress} </span> 
                                        <Button type="primary" shape="circle" size='small' icon={<MailOutlined />} onClick={() => {this.showSendMessageConfirm(this.state.selectedVet.emailAddress)}} />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Contact No</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{fixPhoneNumber(this.state.selectedVet.phone)} </span>
                                        <Button type="primary" shape="circle" size='small' icon={<PhoneOutlined />} onClick={() => {this.showInitiateACallConfirm(fixPhoneNumber(this.state.selectedVet.phone))}} />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button className='btn-blue my-2' type="warning" htmlType="button" onClick={this.showSetVet}>Update Vet Info</Button>
                                    </Col>
                                </Row>
                                </div>
                                
                                }
                                {
                                    this.state.showSetVet &&
                                    <div>
                                        {
                                            !this.state.showAddVet &&
                                            <Row gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                    name="basic"
                                                    className="customise-form"
                                                    ref={this.setVetFormRef}
                                                    onFinish={this.confirmSetVet}
                                                >
                                                    <Form.Item
                                                        name="vetClinicName"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please enter vet clinic name',
                                                            },
                                                        ]}
                                                    >
                                                    
                                                    <Select placeholder="Select Vet Clinic *"
                                                        showSearch
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        dropdownRender={(menu) => (
                                                            <>
                                                              {menu}
                                                              <Divider
                                                                style={{
                                                                  margin: '8px 0',
                                                                }}
                                                              />
                                                              <Space
                                                                style={{
                                                                  padding: '0 8px 4px',
                                                                }}
                                                              >
                                                                <Button icon={<PlusOutlined />} type="primary" htmlType="button" className="btn-blue btn-150 rounded-100" onClick={this.showAddVetForm}>
                                                                  Add New Vet
                                                                </Button>
                                                              </Space>
                                                            </>
                                                          )}
                                                    >
                                                        {
                                                            this.state.vets && (this.state.vets.map((vet, index) =>
                                                            <Option key={index} value={vet.id}>{vet.clinicName}</Option>
                                                        ))
                                                        }

                                                    </Select>                                                    
                                                    </Form.Item>

                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Form.Item className="mb-0 text-right">
                                                            <Button className="text-muted" type="link" onClick={this.hideChangeVet}>
                                                                Cancel
                                                            </Button>
                                                            <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                                Confirm & Proceed
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Form>
                                            </Col>
                                        </Row>
                                        }
                                        
                                        {
                                            this.state.showAddVet &&
                                            <Row gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                    name="basic"
                                                    className="customise-form"
                                                    ref={this.addVetFormRef}
                                                    onFinish={this.confirmAddVet}
                                                >
                                                    <Form.Item
                                                        name="vetClinicName"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please enter vet clinic name',
                                                            },
                                                        ]}
                                                    >
                                                         

                                                        <Input placeholder="* Clinic Name " />

                                                    
                                                    </Form.Item>
                                                    
                                                    <Form.Item
                                                        name="vetClinicArea"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please enter vet clinic name',
                                                            },
                                                        ]}
                                                    >

                                                        <Input placeholder="* Area " />

                                                    </Form.Item>

                                                    <Form.Item
                                                        name="vetClinicContactNo"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please enter vet clinic contact no',
                                                            },
                                                        ]}
                                                    >

                                                        <Input placeholder="* Contact No " />

                                                    </Form.Item>

                                                    <Form.Item
                                                        name="vetClinicContactEmail"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '*Please enter vet clinic email',
                                                            },
                                                        ]}
                                                    >

                                                        <Input placeholder="* Email " />

                                                    </Form.Item>

                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Form.Item className="mb-0 text-right">
                                                            <Button className="text-muted" type="link" onClick={this.hideChangeVet}>
                                                                Cancel
                                                            </Button>
                                                            <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                                Confirm & Proceed
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Form>
                                            </Col>
                                        </Row>}
                                    </div>
                                }
                                    </div>
                                }
                                        
                                {
                                    [ClaimStatus.accepted, ClaimStatus.rejected, ClaimStatus.paid, ClaimStatus.approved_by_vet, ClaimStatus.declined_by_vet].includes(this.state.claim.status) &&
                                    <div>
                                        <Title level={5} className="text-green font-600 pt-1 mt-2">OSLO's Vet Processed Information</Title>
                                        <hr/>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Approved Amount</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                <span>AED {this.state.claim.vetApprovedAmount}</span>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Processed Date</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                <span>{moment(this.state.claim.vetProcessedDate).format('YYYY/MMM/DD')}</span>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Comment</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                <span>{this.state.claim.commentsByVet}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    [ClaimStatus.accepted, ClaimStatus.rejected, ClaimStatus.paid].includes(this.state.claim.status) &&
                                    <div>
                                        <Title level={5} className="text-green font-600 pt-1 mt-2">System Processed Information</Title>
                                        <hr/>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Approved Amount</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                <span>AED {this.state.claim.systemApprovedAmount}</span>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Processed Date</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                <span>{moment(this.state.claim.systemProcessedDate).format('YYYY/MMM/DD')}</span>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <span className='text-green'>Process Flow</span>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                                {
                                                 this.state.claim.flowBySystem && JSON.parse(this.state.claim.flowBySystem).map((item) => {
                                                    return (<p className='m-0 p-0'>{item.step} - {item.result}</p>)
                                                })}
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                
                            </Card>
                            {
                                this.state.claim.otherClaims &&
                                <Card title={"Other Claims Related To Policy - [Lost Ratio : "+ parseFloat(this.state.policyHealth.lostRatio).toFixed(0) +"%]"} className='full-width mt-3 p-2'>
                                    <Table className="noborder" columns={columns} dataSource={this.state.claim.otherClaims} pagination={{pageSize:5}} size="small" />
                                </Card>
                            }
                            
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card title="Policy, Pet & Parent Details" className='full-width p-2'>
                            <Title level={5} className="text-green font-600 pt-1 mt-2">Policy</Title>
                                <hr/>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Policy Number</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policySerial}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Plan</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.planTitle}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Benefits</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>AED {this.state.policy.benefits}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Co-Payment</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.copayment} %</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Deductible</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>AED {this.state.policy.deductable}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Policy Eff. Date</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.createdAt).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Accident Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.effectiveDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Illness Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.illnessCoverDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Knee Cov. Start</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.kneeCoverDate).format('YYYY/MMM/DD')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Policy Value</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>AED {this.state.policy.policyValue}</span>
                                    </Col>
                                </Row>
                                <Title level={5} className="text-green font-600  pt-1 mt-2">Pet</Title>
                                <hr/>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Name</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.name}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Pet Type</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.type}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Breed</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.breed}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Date of Birth</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{moment(this.state.policy.dob).format('MMM/YYYY')}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Gender</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.gender}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Microchip No</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.microchip}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Tag</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.tag}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Pre Existing Conditions</span>
                                    </Col>
                                    {(!this.state.editPreExistingCondition) && <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <Space align='center'>
                                            <span>{this.state.policy.preExistingCondition}</span>
                                            <Button type="primary" shape="circle" size='small' icon={<EditOutlined />} onClick={() => this.enablePreExistingCondition()} />
                                        </Space>
                                    </Col>}
                                    {(this.state.editPreExistingCondition) && <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <Form
                                            name="basic"
                                            className="customise-form"
                                            ref={this.setPreExistingConditionFormRef}
                                            onFinish={this.updatePreExistingCondition}
                                            initialValues={{preExistingCondition:this.state.policy.preExistingCondition}}
                                        >
                                            <Space align='end'>
                                                <Form.Item
                                                    className='mb-0'
                                                    name="preExistingCondition"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '*Please add pre existing conditions',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={4}>
                                                    </TextArea>
                                                </Form.Item>
                                                {this.state.editPreExistingConditionLoader && <LoadingOutlined className='text-green' />} 
                                                <Form.Item className='mb-0'>
                                                    {!this.state.editPreExistingConditionLoader && <Button type="primary" htmlType='submit' shape="circle" size='small' icon={<SaveOutlined />}/>}
                                                </Form.Item>
                                                <Form.Item className='mb-0'>
                                                {!this.state.editPreExistingConditionLoader && <Button type="danger" shape="circle" size='small' icon={<CloseCircleOutlined onClick={() => this.disablePreExistingCondition()} />} /> }
                                                </Form.Item>
                                            </Space>
                                        </Form>
                                    </Col>}
                                </Row>
                                <Title level={5} className="text-green font-600 pt-1 mt-2">Pet Parent</Title>
                                <hr/>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>First Name</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.firstName}</span>
                                    </Col>
                                </Row>   
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Last Name</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.lastName}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Email</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.email} </span>
                                        <Button type="primary" shape="circle" size='small' icon={<MailOutlined />} onClick={() => {this.showSendMessageConfirm(this.state.policy.email)}} />
                                        <Modal
                                            className="find-location-modal"
                                            title="Send A Message"
                                            visible={this.state.showSendMessage}
                                            footer={false}
                                            closable={false}
                                            onCancel={this.handleSendMessageCancel}
                                        >
                                            <Form
                                                name="basic"
                                                onFinish={this.handleSendMessage}
                                                onFinishFailed={this.handleSendMessageCancel}
                                                
                                                className="customise-form"
                                                ref={this.sendMessageFormRef}
                                            >
                                                <Form.Item
                                                    name="Subject"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input Subject',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Subject" />
                                                    
                                                </Form.Item>
                                                <Form.Item
                                                    name="Message"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input message!',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={4} placeholder="Message" className="mt-2" />
                                                </Form.Item>
                                                

                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item className="mb-0 text-right">
                                                        <Button className="text-muted" type="link" onClick={this.handleSendMessageCancel}>
                                                            Cancel
                                                        </Button>
                                                        <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                            Confirm & Send
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Form>
                                        </Modal>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Phone No</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{fixPhoneNumber(this.state.policy.phoneNo)} </span>
                                        <Button type="primary" shape="circle" size='small' icon={<PhoneOutlined />} onClick={() => {this.showInitiateACallConfirm(fixPhoneNumber(this.state.policy.phoneNo))}} />
                                        <Modal
                                            className="find-location-modal"
                                            title="Initiate a Call"
                                            visible={this.state.showInitiateACall}
                                            footer={false}
                                            closable={false}
                                            onCancel={this.handleInitiateACallCancel}
                                        >
                                            <Form
                                                name="basic"
                                                onFinish={this.handleInitiateACall}
                                                onFinishFailed={this.handleInitiateACallCancel}
                                                
                                                className="customise-form"
                                                ref={this.initiateACallFormRef}
                                            >
                                                <Form.Item
                                                    name="agentId"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select Agent',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select Agent *"
                                                        
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value="0005">Heshitha Chathuranga</Option>
                                                        <Option value="0002">Emmanuel Isendrandt</Option>
                                                        <Option value="0003">Maisa Fabricio</Option>
                                                        

                                                    </Select>
                                                    
                                                </Form.Item>
                                                
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item className="mb-0 text-right">
                                                        <Button className="text-muted" type="link" onClick={this.handleInitiateACallCancel}>
                                                            Cancel
                                                        </Button>
                                                        <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                            Initiate a Call
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Form>
                                        </Modal>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Emirates Id</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.emiratesId}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Nationality</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.nationality}</span>
                                    </Col>
                                </Row>
                                <Title level={5} className="text-green font-600 pt-1 mt-2">Address</Title>
                                <hr/>
                                
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Apartment Number</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.addressApartment}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Street</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.addressStreet}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>City</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.addressCity}</span>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <span className='text-green'>Country</span>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <span>{this.state.policy.addressCountry}</span>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <hr className='m-1'/>
                { 
                    this.state.claim.status == ClaimStatus.new_claim &&
                    <div className='m-2'>
                        <Row gutter={15}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="end">
                                <Button className='btn-blue mx-2' type="primary" htmlType="button" onClick={() => this.initiateClaimProcess()}>Start Processing</Button>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    this.state.claim.status == ClaimStatus.in_process &&
                    <div className='m-2'>
                        <Row gutter={15}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="end">
                                <Upload className='btn p-0 m-2' {...this.uploadProps} action={UPLOAD_CLAIM_DOCS_WITHOUT_DELETING_API + '/' + this.state.claimId}>
                                    <Button type="dashed" htmlType="button" icon={<UploadOutlined/>}>Upload Additional Documents</Button>
                                </Upload>
                                
                                <Button className='btn-blue mx-2' type="primary" htmlType="button" onClick={() => this.loadClaimProcessByVetModel(true)}>Approve Claim By Vet</Button>
                                <Button className='mx-2' type="danger" htmlType="button" onClick={() => this.loadClaimProcessByVetModel(false)}>Decline Claim By Vet</Button>
                                            <Modal
                                                className="find-location-modal"
                                                title={<div className="text-dark-green font-600">{ this.state.isApprove ? 'Approve' : 'Decline'} Claim By Vet</div>}
                                                visible={this.state.visible}
                                                footer={false}
                                                closable={false}
                                                onCancel={this.handleCancel}
                                            >
                                                <Form
                                                    name="basic"
                                                    onFinish={this.formSetApproveDetails}
                                                    onFinishFailed={this.formApproveDetailsFailed}
                                                    
                                                    className="customise-form"
                                                    ref={this.approveFormRef}
                                                >
                                                    <Form.Item
                                                        name="Comment"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input Comments',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={4} placeholder="Comment By Vet" className="mt-4" />
                                                    </Form.Item>
                                                    {
                                                        this.state.isApprove &&
                                                        <Form.Item
                                                        name="ApprovedAmount"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input amount!',
                                                            },
                                                        ]}
                                                        >
                                                            <Input placeholder="* Approved Amount" />
                                                        </Form.Item>
                                                    }
                                                    

                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Form.Item className="mb-0 text-right">
                                                            <Button className="text-muted" type="link" onClick={this.handleCancel}>
                                                                Cancel
                                                            </Button>
                                                            <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                                Confirm & Proceed
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Form>
                                            </Modal>
                            </Col>
                        </Row>
                        
                    </div>
                }
            </Layout>
        );
    }
}

export default AdminClaimDetails;