import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Typography, Row, Col, Card, Modal, Form, Button, Image, Input, Spin, Upload } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import SideMenu from '../pet_cloud/sidebar_menu_card'
import PetSelectionCard from '../pet_cloud/pet_selection_cards'
import { getUser, setUser, notifications } from '../../helpers/utilities';
import { updateUser, uploadUserImage, getUserDetails, getUserPolicies, getUserImage, getUserPets } from '../../api/pets-api';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TextArea } = Input;


class MyPersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'personal_info',
            visible: false,
            accvisible: false,
            spinning: false,
            accspinning: false,
            mainSpin: false,
            userInfo: {},
            policies: [],
            userPets: [],
            selectedPetId: 0,
            userImage: ''
        };
    }

    async componentDidMount() {
        let userInfo = getUser();
        await getUserPolicies().then((data) => {
            console.log('policyInfo', data.data.result);
            if(data.data.result){
                this.setState({ policies: data.data.result});
            }
        });
        
        console.log('user', userInfo);
        this.setState({ userInfo });
        let userImg = await getUserImage(userInfo.image).then((data) => {
            console.log('user image', data.data);
            this.setState({userImage: data.data.url})
        })

        await this.getPets();
    }

    changePetIndex = (i) => {
        this.setState({ selectedPetId: i });
    }

    tabList = [
        {
            key: 'personal_info',
            tab: 'Personal Info',
        },
        {
            key: 'billing_info',
            tab: 'Billing Info',
        },
        {
            key: 'policy_info',
            tab: 'Policy Info',
        }
    ];

    calculateBenefit = (benfit) => {
        if(benfit == 5000){
            return 20000;
        } else if (benfit == 10000){
            return 35000;
        } else if (benfit == 20000){
            return 70000;
        }
        return 0;
    }
    

    getPets = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let userPets = await getUserPets();
                console.log(userPets)
                let userPets2 = await Promise.all(userPets.data.result.map(async (item) => {
                    try{
                        if(item.image)
                        {
                            await getUserImage(item.image).then((data) => {
                                item.image = data.data.url;
                            })
                        }
                        else{
                            item.image = (item.type === 'Dog' ? "/images/dog-default-300-300.png" : "/images/cat-default-300-300.png");
                        }
                    } catch(ex) {
                        item.image = (item.type === 'Dog' ? "/images/dog-default-300-300.png" : "/images/dog-default-300-300.png");
                    }
                }));
                console.log('userpet2',userPets2);
                this.setState({ userPets: userPets.data.result }, () => {
                    console.log('userPets', this.state.userPets);
                    resolve(userPets.data.result)
                })
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    };

    getPolicyNumber(policy){
        if(policy){
            var stringPolicyCode = '0000000000' + policy.policySerial;
            var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
            let policyCode = ('Oslo-'+policy.policyCurrency+'-'+policy.policyType+'-'+delimitedSerial).toUpperCase();
            return policyCode;
        }
    }

    getPolicyNumberWithPetName(policy){
        if(policy){
            var stringPolicyCode = '0000000000' + policy.policySerial;
            var delimitedSerial = stringPolicyCode.substring(stringPolicyCode.length - 7);
            let policyCode = ('Oslo-'+policy.policyCurrency+'-'+policy.policyType+'-'+delimitedSerial).toUpperCase();
            return policyCode;
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showAccModal = () => {
        this.setState({
            accvisible: true,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            accvisible: false,
        });
    };

    onFinish = (values) => {
        let user = this.state.userInfo;
        let phoneNo = values.phoneNo;
        let email = values.email;
        delete values.email;
        delete values.phoneNo;
        user.addresses = (user.addresses && [{ ...user.addresses[0], ...values }].concat(user.addresses.slice(1, user.addresses.length))) || [{ ...values }];
        user.phoneNo = phoneNo;
        user.email = email;
        console.log('updateduser', user);

        this.setState({ spinning: true }, async () => {
            await updateUser(user);
            this.setState({
                visible: false,
                spinning: false
            });
            setUser(user);
        });

        // console.log('Received values of form: ', values);
    };

    onAccFinish = (values) => {
        let user = this.state.userInfo;
        let iban = values.iban;
        let bank_name = values.bank_name;
        let bank_address = values.bank_address;
        let swift_code = values.swift_code;
        user.iban = iban;
        user.bankName = bank_name;
        user.bankAddress = bank_address;
        user.swiftCode = swift_code;
        console.log('updateduser', user);

        this.setState({ accspinning: true }, async () => {
            try {
                await updateUser(user);
                this.setState({
                    accvisible: false,
                    accspinning: false
                });
                setUser(user);

            } catch (err) {
                console.log(err);

                notifications('Something went wrong!', 'Error', 'error', 3)
            }
        });

        // console.log('Received values of form: ', values);
    };

    contentList = (key) => {
        return key === 'personal_info' ?
            (<div className="info-inner personal position-relative">
                <Button type="link" onClick={this.showModal} className="btn-edit p-0"><img src="/images/icon-pencil-green.png" /></Button>
                <Modal
                    centered
                    title="My Personal Info"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <Spin className="spinner-wrp" spinning={this.state.spinning} size="medium" />
                    <Form size="large" name="normal_login" className="customise-form"
                        onFinish={this.onFinish}
                        initialValues={{
                            email: this.state.userInfo.email,
                            phoneNo: this.state.userInfo.phoneNo || '',
                            apartmentNumber: this.state.userInfo.addresses && this.state.userInfo.addresses[0].apartmentNumber,
                            street: this.state.userInfo.addresses && this.state.userInfo.addresses[0].street,
                            city: this.state.userInfo.addresses && this.state.userInfo.addresses[0].city,
                            country: this.state.userInfo.addresses && this.state.userInfo.addresses[0].country
                        }}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Email!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="phoneNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Phone!',
                                },
                            ]}
                        >
                            <Input
                                type="phone"
                                placeholder="Phone"
                            />
                        </Form.Item>
                        <Form.Item
                            name="apartmentNumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter apartment number!',
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Apartment number"
                            />
                        </Form.Item>
                        <Form.Item
                            name="street"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter street!',
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Street"
                            />
                        </Form.Item>
                        <Form.Item
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter city!',
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="City"
                            />
                        </Form.Item>
                        <Form.Item
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter country!',
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Country"
                            />
                        </Form.Item>
                        <Form.Item className="mb-0">
                            <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row gutter={15}>
                    <Col xs={24} sm={24} md={18} lg={12} xl={14}>
                        <Paragraph className="text-green mb-4 mt-4 text-left font-700">Oslo membership: <span className="text-red float-right">{this.getPolicyNumber(this.state.policies[0])}</span></Paragraph>
                        <div className="list-with-icon mb-3">
                            <figure>
                                <img src="/images/icon-envelop-solid.png" />
                            </figure>
                            <div className="text">
                                <Paragraph className="text-green mb-0">{this.state.userInfo?.email}</Paragraph>
                            </div>
                        </div>
                        <div className="list-with-icon mb-3">
                            <figure>
                                <img src="/images/mobile-phone-solid.png" />
                            </figure>
                            <div className="text">
                                <Paragraph className="text-green mb-0">{this.state.userInfo?.phoneNo || 'N/A'}</Paragraph>
                            </div>
                        </div>
                        <div className="list-with-icon mb-3">
                            <figure>
                                <img src="/images/icon-home-solid.png" />
                            </figure>
                            <div className="text">
                                <Paragraph className="text-green mb-0">{this.state.userInfo && this.state.userInfo.addresses?.length ? 'Apt #' + this.state.userInfo.addresses[0].apartmentNumber : ''} <br />
                                    {this.state.userInfo && this.state.userInfo.addresses?.length ? (this.state.userInfo.addresses[0].street + ' - ' + this.state.userInfo.addresses[0].city + ' - ' + this.state.userInfo.addresses[0].country) : 'N/A'}</Paragraph>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>)
            : key === 'billing_info' ? 
            (<div className="info-inner billing position-relative text-left">
                <Button type="link" onClick={this.showAccModal} className="btn-edit p-0"><img src="/images/icon-pencil-green.png" /></Button>
                <Modal
                    centered
                    title="My Account Info"
                    visible={this.state.accvisible}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <Spin className="spinner-wrp" spinning={this.state.accspinning} size="medium" />
                    <Form size="large" name="normal_login" className="customise-form"
                        onFinish={this.onAccFinish}
                        initialValues={{
                            iban: this.state.userInfo.iban || '',
                            bank_name: this.state.userInfo.bankName || '',
                            bank_address: this.state.userInfo.bankAddress || '',
                            swift_code: this.state.userInfo.swiftCode || '',
                        }}
                    >
                        <Form.Item
                            name="iban"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter IBAN!',
                                },
                            ]}
                        >
                            <Input placeholder="IBAN" />
                        </Form.Item>
                        <Form.Item
                            name="bank_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Bank Name"
                            /></Form.Item>
                            <Form.Item
                            name="bank_address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Address!',
                                },
                            ]}
                        >
                            <Input placeholder="Bank Address"
                            /></Form.Item>
                            <Form.Item
                            name="swift_code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Swift Code!',
                                },
                            ]}
                        >
                            <Input placeholder="Swift Code"
                            />
                        </Form.Item>
                        <Form.Item className="mb-0">
                            <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Row gutter={[50, 15]} align="middle" justify="center">
                    <Col xs={24} sm={14} md={16} lg={12} xl={14}>
                        {/* <Paragraph className="text-red mt-3 mb-0">Billing Preferences: <span className="text-green">Annual</span></Paragraph>
                        <Paragraph className="text-red mt-3">Next Billing Date: <span className="text-green">01/01/2022</span></Paragraph> */}

                        <Paragraph className="text-red mt-3 mb-0">IBAN: <span className="text-green">{this.state.userInfo?.iban || ''}</span></Paragraph>
                        <Paragraph className="text-red mt-3">Bank Name: <span className="text-green">{this.state.userInfo?.bankName || ''}</span></Paragraph>
                        <Paragraph className="text-red mt-3 mb-0">Bank Address: <span className="text-green">{this.state.userInfo?.bankAddress || ''}</span></Paragraph>
                        <Paragraph className="text-red mt-3">Swift Code: <span className="text-green">{this.state.userInfo?.swiftCode || ''}</span></Paragraph>
                    </Col>
                    <Col xs={24} sm={10} md={8} lg={12} xl={10}>
                        <Paragraph className="text-green text-center mt-5 mb-3">
                            <img src="/images/icon-warning-red.png" />
                        </Paragraph>
                        <Paragraph className="text-light-green text-center">
                            If you want to modify your billing info, please contact us at support@osloinsurance.pet
                        </Paragraph>
                    </Col>
                </Row>
            </div>)
            : 
            (
                <div className="info-inner policy position-relative text-left">
                    <Row gutter={[50, 15]} justify="center">
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <PetSelectionCard userPets={this.state.policies} changePetIndex={this.changePetIndex} />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Paragraph className="text-red mt-3 mb-0">Policy No: <span className="text-green">{this.getPolicyNumberWithPetName(this.state.policies[this.state.selectedPetId]) || ''}</span></Paragraph>                            
                            <Paragraph className="text-red mt-3 mb-0">Plan Name: <span className="text-green">{this.state.policies[this.state.selectedPetId]?.planTitle || ''}</span></Paragraph>
                            <Paragraph className="text-red mt-3">Benefits: <span className="text-green">{
                               'AED ' + this.calculateBenefit(this.state.policies[this.state.selectedPetId]?.benefits) || ''
                            }</span></Paragraph>
                            <Paragraph className="text-red mt-3 mb-0">Reimbursment: <span className="text-green">{this.state.policies[this.state.selectedPetId]?.copayment + '%' || ''}</span></Paragraph>
                            <Paragraph className="text-red mt-3">Deductible: <span className="text-green">{'AED ' + this.state.policies[this.state.selectedPetId]?.deductable || ''}</span></Paragraph>
                        </Col>
                    </Row>
                </div>
            )
    }

    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };

    uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };

        this.setState({ mainSpin: true })
        fmData.append("image", file);
        console.log('id',this.state.userInfo.id);
        
        try {
            const res = await uploadUserImage(this.state.userInfo.id, fmData, config)
            onSuccess("Ok");
            notifications(res.data.message, 'Uploads')
            let user = await getUserDetails();
            console.log('user', user);

            setUser(user.data.result);
            this.setState({ mainSpin: false }, () => {
                window.location.href = window.location.href;
            });

        } catch (err) {
            console.log("Eroor: ", err);
            onError({ err });
            this.setState({ mainSpin: false })
            notifications('Something went wrong', 'Uploads', 'error')
        }
    };

    render() {
        return (
            <Layout className="dashboard middle my-personal-info">
                <LoginHeader />
                <Spin className="spinner-wrp" spinning={this.state.mainSpin} tip="Loading..." size="large" />
                <Content className="my-personal-info-content pt-5 pb-5">
                    <SideMenu />
                    <div className="personal-info">
                        <div className="user-image text-center mb-4">
                            <Upload
                                showUploadList={false}
                                customRequest={this.uploadImage}
                            >
                                <div className={(this.state.userInfo && this.state.userInfo.image)?"pet-image image-uploaded":"pet-image"}>
                                    {
                                        this.state.userInfo && this.state.userInfo.image &&
                                        <img alt={this.state.userInfo.firstName} src={this.state.userImage} />
                                    }
                                    {
                                        this.state.userInfo && !this.state.userInfo.image &&
                                        <img alt={this.state.userInfo.firstName} src="/images/bg-pet-login.png" />
                                    }
                                </div>
                                {/* <img src="/images/bg-pet-login.png" /> */}

                            </Upload>
                        </div>
                        <Card
                            tabList={this.tabList}
                            activeTabKey={this.state.key}
                            onTabChange={key => {
                                this.onTabChange(key, 'key');
                            }}
                        >
                            {this.contentList(this.state.key)}
                        </Card>
                    </div>
                </Content>
                <LoginFooter />
            </Layout>
        );
    }
}

export default MyPersonalInfo;