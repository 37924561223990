import React, { Component } from 'react';
import LeftMenu from '../pet_cloud/left_menu'
import { Link } from "react-router-dom";
import { Spin, Layout, Popconfirm, Typography, Menu, Card, Button, Table, Checkbox, Upload, Col, Modal, Form, Input, Progress } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import { uploadDocs, getDocs, deleteDocs, editDocs } from '../../api/pets-api';
import { notifications } from '../../helpers/utilities';
import moment from 'moment';
const { Title } = Typography;
const { SubMenu } = Menu;

const { Content, Sider } = Layout;


const data = [
    {
        id: '1',
        name: 'Vet bills.jpeg',
        date_modified: "10/2/2020",
    },
    {
        id: '2',
        name: 'Inboice.jpeg',
        date_modified: "10/2/2020",
    },
    {
        id: '3',
        name: 'Invoice_23012021.jpeg',
        date_modified: "10/2/2020",
    },
    {
        id: '4',
        name: 'copy.jpeg',
        date_modified: "10/2/2020",
    },
    {
        id: '5',
        name: 'bills.jpeg',
        date_modified: "10/2/2020",
    },
];

class inbox extends Component {
    constructor(props) {
        super(props);        
        this.uploadImage = this.uploadImage.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.deleteFiles = this.deleteFiles.bind(this);
        this.getDocs = this.getDocs.bind(this);
        this.editFile = this.editFile.bind(this);
        this.state = {
            spin:false,
            visible:false,
            popVisible:false,
            docs:[],
            columns: [
                {
                    title: 'NAME',
                    dataIndex: 'name',
                    key: 'name',
                    render: (name, row, index) => {return <Checkbox key={(index+1)} value={index} onChange={this.onChange}>{name}</Checkbox>},
                },
                {
                    title: 'LAST MODIFIED',
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    render: date => <div className="date-modify">{moment(date).format('MM/DD/YYYY hh:mm:ss A')} <i className="fa fa-star"></i></div>,
                }
            ],
            selectedFiles:[],
            editFileName:''
        };
        
    }
    formRef = React.createRef();
    onChange(e) {
        let sf = this.state.selectedFiles;
        if(e.target.checked){
            sf.push(e.target.value)
        }else{
            const index = sf.indexOf(e.target.value);
            if (index > -1) {
                sf.splice(index, 1);
              }
        }
        this.setState({selectedFiles: sf},()=>{
            if(this.state.selectedFiles.length == 1 && e.target.checked) this.editFile();
        })
        console.log(`checked = ${e.target.checked} ${e.target.value}`);
    }

    uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;
    
        const fmData = new FormData();
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        
        this.setState({spin:true})
        fmData.append("image", file);
        try {
            const res =  await uploadDocs(fmData, config)
            onSuccess("Ok");
            this.setState({spin:false})
            notifications(res.data.message,'Uploads')
            this.getDocs()
        } catch (err) {
            console.log("Eroor: ", err);
            onError({ err });
            this.setState({spin:false})
            notifications('Something went wrong','Uploads','error')
        }
    };

    async downloadFiles(){
        
        if(!this.state.selectedFiles.length){
            notifications('','Please select file(s)','warning');
            return
        }
        let a = document.createElement('a');
        this.state.selectedFiles.map(index=>{
            fetch(this.state.docs[index].accessPath).then(response => {
                if (!response.ok) {
                    notifications('','Something went wrong','error');
                    return
                }
                response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = this.state.docs[index].name;
                a.click();
                });
            });
            
        })
    }

    async deleteFiles(){
        
        if(!this.state.selectedFiles.length){
            notifications('','Please select file(s)','warning');
            return
        }
        let ids = []
        this.state.selectedFiles.map(index=>{
            ids.push(this.state.docs[index].id)
        })
        let commaSeperated = ids.join(', ')

        this.setState({popVisible:false});
        await deleteDocs(commaSeperated).then(resp=>{
            notifications('','Selected File(s) Deleted Successfully!','success');
            this.setState({selectedFiles:[]})
            this.getDocs();

        }).catch(error=>{
            notifications('','Something went wrong!','error');
        })
    }

    editFile(){
        if(!this.state.selectedFiles.length){
            notifications('','Please select file','warning');
            return
        }
        if(this.state.selectedFiles.length > 1){
            notifications('','Please select single file','warning');
            return
        }
        console.log(this.state.selectedFiles,'files');
        
        let fileName = this.state.docs[this.state.selectedFiles[0]].name;
        fileName = fileName.indexOf('.') ? fileName.substr(0, fileName.lastIndexOf('.')) : fileName
        
        this.setState({visible:true},()=>{
            this.formRef.current.setFieldsValue({
                name: fileName
              });
        })
    }
    
    handleOnChange = ({ file, fileList, event }) => {
    };

    getDocs = async() =>{
        let docs = await getDocs()
        console.log('docs',docs);
        if(docs.data.data){
            this.setState({docs:docs.data.data})
        }
    }

    async componentDidMount(){
        this.getDocs()
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    updateDoc = async (values) => {
        // this.setState({visible:false})
        console.log('Success:', values);
        let updateObj = {
            "fileId": this.state.docs[this.state.selectedFiles[0]].id,
            "updatedName": values.name
        }
        await editDocs(updateObj).then(resp=>{
            this.setState({selectedFiles:[], visible: false})
            this.getDocs();
        }).catch(error=>{
            notifications('','Something went wrong!','error');
        })

    };

    docFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    checkVisible = () =>{
        if(this.state.selectedFiles.length) this.setState({popVisible:true})
    }

    render() {
        return (
            <Layout className="dashboard inbox">
                <Spin spinning={this.state.spin} tip="Uploading..." size="large">
                    <LoginHeader></LoginHeader>
                    <Layout>
                        <LeftMenu />
                        <Content className="p-3 bg-gray-light">
                            <Card className="card-content rounded-5" extra={<img src="/images/icon-home.png" />} title={<Title level={4} className="text-dark-green">Inbox & Docs</Title>}>
                                <div className="option-menu mb-4">
                                    <ul>
                                        <li>
                                            <style>{"\
                                                .ant-upload.ant-upload-select{\
                                                display:inline-flex;\
                                                }\
                                            "}</style>
                                            <Button type="link">
                                                <Upload
                                                    showUploadList={false}
                                                    multiple={true}
                                                    customRequest={this.uploadImage}
                                                    onChange={this.handleOnChange}
                                                    // listType="picture-card"
                                                    style={{display: 'inline-flex'}}
                                                >
                                                    <img src="/images/icon-upload.png" />
                                                    <Title level={5}>UPLOAD</Title>
                                                </Upload>
                                                {/* <img src="/images/icon-upload.png" />
                                                <Title level={5}>UPLOAD</Title> */}
                                            </Button>
                                        </li>
                                        <li>
                                            <Button type="link" onClick={this.downloadFiles}>
                                                <img src="/images/icon-download.png" />
                                                <Title level={5}>DOWNLOAD</Title>
                                            </Button>
                                        </li>
                                        {/* <li>
                                            <Button type="link">
                                                <img src="/images/icon-share.png" />
                                                <Title level={5}>SHARE</Title>
                                            </Button>
                                        </li> */}
                                        <li>
                                            <Button type="link" onClick={this.editFile}>
                                                <img src="/images/icon-rename.png" />
                                                <Title level={5}>RENAME</Title>
                                            </Button>
                                        </li>
                                        <li>
                                        <Popconfirm
                                            title="Are you sure you want to delete this file(s)"
                                            onConfirm={this.deleteFiles}
                                            onCancel={()=>{this.setState({popVisible:false});}}
                                            visible={this.state.popVisible}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="link" onClick={this.checkVisible}>
                                                <img src="/images/icon-delete.png" />
                                                <Title level={5}>DELETE</Title>
                                            </Button>
                                        </Popconfirm>
                                        </li>
                                        <li>
                                            <Button type="link">
                                                <img src="/images/icon-move.png" />
                                                <Title level={5}>MOVE</Title>
                                            </Button>
                                        </li>
                                        {/* <li>
                                            <Button type="link">
                                                <img src="/images/icon-filter.png" />
                                                <Title level={5}>FILTER</Title>
                                            </Button>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="table-responsive">
                                    <Table pagination={false} columns={this.state.columns} dataSource={this.state.docs} />
                                </div>
                            </Card>
                            <div>
                                <Modal
                                    title={<div className="text-dark-green font-600">Update Document</div>}
                                    visible={this.state.visible}
                                    footer={false}
                                    closable={false}
                                    onCancel={this.handleCancel}
                                >
                                    <Form
                                        name="basic"
                                        onFinish={this.updateDoc}
                                        onFinishFailed={this.docFailed}
                                        size={"large"}
                                        className="customise-form"
                                        ref={this.formRef}
                                    >
                                        
                                        <Form.Item name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Enter Document Name!',
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Document Name" suffix={<span className="text-danger" style={{cursor: "pointer"}}>CHANGE</span>} />
                                        </Form.Item> 
                                        
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item className="mb-0 text-right">
                                                <Button className="text-muted" type="link" onClick={this.handleCancel}>
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Form>
                                </Modal>
                        
                            </div>
                        </Content>
                    </Layout>
                </Spin>
            </Layout>
        );
    }
}

export default inbox;