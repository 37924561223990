import React, { Component, Fragment } from 'react';
import { Radio } from 'antd';
import { upperCase, calculateAge } from '../../helpers/utilities';

class PetSelectionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPets: [],
            value: 0
        };
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
        this.props.changePetIndex(e.target.value)
    };

    loadDefaultPetImage = (e, pet) => {
        debugger;
        e.target.onerror = null;
        if(pet && pet.type) {
            if (pet.type === 'Dog') {
                e.target.src = "/images/dog-default-300-300.png";
            } else {
                e.target.src = "/images/cat-default-300-300.png";
            }
        }
        else{
            e.target.src = "/images/dog-default-300-300.png";
        }
        
    }

    render() {
        return (
            <Fragment>
                <Radio.Group className="pet-selction-card mb-3 full-width" onChange={this.onChange} value={this.state.value}>
                    {
                        this.props.userPets && this.props.userPets.map((pet,index)=>
                        <Radio.Button key={index} className="mb-3 " value={index}>
                            <figure>
                            {
                                pet.image && 
                                <img alt={pet.name} src={pet.image} onError={(e, pet) => {this.loadDefaultPetImage(e, pet)}}/>
                            } 
                            {
                                !pet.image && 
                                <img alt={pet.name} src={pet.type === 'Dog' ? "/images/banner-dog.png" : "/images/banner-cat.png"} onError={(e, pet) => {this.loadDefaultPetImage(e, pet)}} />
                            }
                            </figure> 
                            <div className="text font-700 full-width px-3">{upperCase(pet.name)} <br /> 
                            <span className="text font-300 full-width text-center">{pet.breed}</span>
                            <span className="text font-300 full-width">{calculateAge(pet.dob) || '<1'} yo</span>
                            </div>
                        </Radio.Button>)
                    }
                    {/* <Radio.Button className="mr-5" value={"bob"}><figure><img src="/images/icon-bob-md.png" /></figure> <div className="text font-700">Bob <br /> <span className="font-300">pug - 2 yo</span></div></Radio.Button>
                    <Radio.Button className="mr-5" value={"kitty"}><figure><img src="/images/icon-kitty-md.png" /></figure> <div className="text"><div className="text font-700">Kitty <br /> <span className="font-300">cat - 4 yo</span></div></div></Radio.Button>
                    <Radio.Button className="mr-5" value={"doug"}><figure><img src="/images/icon-doug-md.png" /></figure> <div className="text"><div className="text font-700">Doug <br /> <span className="font-300">spaniel - 1 yo</span></div></div></Radio.Button> */}
                </Radio.Group>
            </Fragment>
        );
    }
}

export default PetSelectionCard;