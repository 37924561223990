const faqItems = [
    {
        title: "POLICY BASICS",
        items: [
            {
                question: "How do I add another pet?",
                answer: "Each pet needs its own policy and you will have to set each policy one at a time. If you’re buying the policies during your first purchase, please select “add a pet”. If you wish to purchase another policy after having purchased a policy for your first pet, just login to your Oslo Pet Cloud and hit “add a pet” in your account to get a new quote for your other furry friend – and get a 5% multi-pet discount!",
                hasChildItems: false
            },
            {
                question: "Does Oslo offer a wellness plan?",
                answer: "Currently, our policy does not offer coverage for routine wellness or preventative care yet. Examples of routine wellness or preventative care include, but are not limited to, annual wellness visits, annual or routine blood work, vaccinations, flea or tick preventative medications, etc.",
                hasChildItems: false
            },
            {
                question: "How does Oslo calculate my premium?",
                answer: "In general, your premium is based on your pet's age, breed and gender. Some other factors that impact your premium are: the plan you choose, your deductible, your co-payment, and any discounts for which you are eligible.",
                hasChildItems: false
            },
            {
                question: "What is an annual deductible for pet insurance?",
                answer: "An annual deductible is the fixed amount per policy period (one year) that needs to be met before we would begin to pay benefits for covered claims, at the elected reimbursement amount. Only covered claims will be applied to your annual deductible. Your deductible will reset when your policy renews each year.",
                hasChildItems: false
            },
            {
                question: "What is co-insurance for pet insurance?",
                answer: "Co-insurance is the percentage of the vet bill the pet parent is responsible for versus what OSLO will pay. With Oslo, you have the option to choose between 70% /80% /90%. Keep in mind that this is applied to every claim.",
                hasChildItems: false
            },
            {
                question: "Are there age requirements for Oslo pet insurance policies?",
                answer: "We will insure any cat or dog that is at least 8 weeks old and 8 years old for dogs and 10 years old for cats with lifetime covers. However, if you are renewing a policy for a dog that is 8 years or older, or for a cat that is 10 years or older, we ask that you follow your veterinarian’s advice regarding senior wellness testing.",
                hasChildItems: false
            },
            {
                question: "What if I am not sure about my pet’s age?",
                answer: "If you do not know the exact date of birth of your pet, please use your best estimate based on your pet’s medical records or shelter/rescue documentation. We will validate the correct age when reviewing your pet's medical records.",
                hasChildItems: false
            },
            {
                question: "Does my pet have to be spayed or neutered to enroll?",
                answer: "Your pet does not need to be spayed or neutered to purchase a policy and coverage is not affected based on if your pet is spayed/neutered or not. However, please keep in mind that our policy does not cover spaying or neutering.",
                hasChildItems: false
            },
            {
                question: "What is a pre-existing condition?",
                answer: "A pre-existing condition is any injury or illness whose symptoms were showing before the start of your pet’s policy, or during a relevant waiting period. A condition is considered pre-existing whether or not it’s been officially diagnosed or treated; all that matters is when its symptoms first displayed.",
                hasChildItems: false
            },
            {
                question: "What pet insurance covers pre-existing conditions?",
                answer: "Pre-existing conditions aren’t covered by any pet insurance providers, including Oslo. Think of it this way: If your car is damaged or starts showing signs of engine failure, you wouldn’t buy an insurance policy after-the-fact on that vehicle and expect the provider to pay for repairs. The purpose of insurance is to protect you from unknowns: You can’t protect yourself against something that already happened or is happening.",
                hasChildItems: false
            },
            {
                question: "Can I still insure my pet if (s)he has a pre-existing condition?",
                answer: "Of course! Pets with a pre-existing condition (or two, or even three) aren’t prohibited from enrolling in our policies; their coverage will just exclude those specific ailments.",
                hasChildItems: false
            },
            {
                question: "Is there anything I can do to avoid pre-existing condition exclusions?",
                answer: "Yes, and it’s by enrolling your pet in a policy while they’re still healthy. Taking this sort of precaution improves their chances of being fully covered if something happens down the road. Note: If you have specific questions about your pet’s possible pre-existing conditions, just drop us a line to discuss yours further. We will not be able to guarantee coverage.",
                hasChildItems: false
            },
            {
                question: "How do you determine a pre-existing condition?",
                answer: "We will evaluate your pet's medical history during the claim process to determine if the condition existed prior to your enrollment or within the subsequent waiting period. In general, a pre-existing condition is a medical condition which first showed signs or symptoms before the effective date of your Oslo policy or which occurred during the policy waiting period.",
                hasChildItems: false
            },
            {
                question: "Is a veterinary exam required to enroll for pet insurance?",
                answer: "We do not require a veterinary exam to enroll. However, if you cannot provide medical records showing your pet’s annual health exam by a licensed veterinarian occurring within the 12 months prior to the effective date of your Oslo pet insurance policy, the first documented veterinary examination after the effective date of the policy may be used as a basis for determining any pre-existing conditions.",
                hasChildItems: false
            },
            {
                question: "What is an annual health exam?",
                answer: "An annual health exam is the routine wellness exam that your vet recommends your pet has yearly to ensure that your pet is in good health. During this exam, your vet will examine your pet’s various systems, typically including their eyes, ears, throat, listening to their heart and breathing, completing an oral exam, and more.",
                hasChildItems: false
            },
            {
                question: "When does my policy start?",
                answer: "Oslo policies are effective at 12:01am standard time at your mailing address the day after enrollment.",
                hasChildItems: false
            },
            {
                question: "Are there waiting periods?",
                answer: "Yes. Visit Information on waiting periods.",
                hasChildItems: false
            },
            {
                question: "What medical records are needed from my veterinarian?",
                answer: "Medical records are detailed notes that your veterinarian logs after each time your pet goes in for a visit. Medical records include the findings or results from a veterinary visit, including lab results, x-rays etc. In some cases, these notes are not something that your vet will provide to you up front, so you will need to request them.",
                hasChildItems: false
            },
            {
                question: "What do I need to submit with my first claim?",
                answer: "To process your initial claim, we will need the last two years of medical records (dating back two years prior to the effective date of your pet's Oslo policy) including notes from all veterinarians your pet has visited. If your pet is less than two years old, or if they have not been a part of your family for at least two years, we will need all available records. If your pet was adopted, we will also need any medical records provided to you by the shelter or rescue organization including the date of adoption, as well as the adoption certificate if available.",
                hasChildItems: false
            },
            {
                question: "What do I need to include with each future claim?",
                answer: "For every claim you submit the following items are needed:",
                hasChildItems: true,
                childItems: [
                    {
                        question: "Item 1",
                        answer: "All notes related to the date of service associated with the claim."
                    },
                    {
                        question: "Item 2",
                        answer: "A clear, fully paid, itemized invoice."
                    }
                ]
            },
            {
                question: "Where can I send the medical records?",
                answer: "Your veterinarian can email the records. Your veterinarian can also email them to support@Osloinsurance.pet.",
                hasChildItems: false
            },
            {
                question: "How does Oslo compare to other pet insurance companies?",
                answer: "Since we will make all competitors irrelevant, choosing an Oslo pet insurance plan is the right decision for you and your pet.",
                hasChildItems: false
            },
            {
                question: "What To Look For When You Compare Pet Insurance Plans",
                answer: "Pet insurance companies may seem similar, but the differences can be significant when it matters most. In addition to pet insurance comparison reviews and ratings, here are some key policy differences to help you find the best pet insurance for your pet.",
                hasChildItems: true,
                childItems: [
                    {
                        question: "Coverage",
                        answer: "Look closely at covered medical expenses, and more importantly, expenses not covered when you compare pet insurance companies. All pet healthcare coverage details are provided upfront via the FAQs page, sample policies, or you can contact Customer Experience."
                    },
                    {
                        question: "Annual deductible vs. per condition deductible",
                        answer: "With our plans, once you have met your chosen annual deductible, you no longer pay a deductible for the remainder of your annual policy period. In contrast, a per condition deductible assigns deductibles per incident or condition—meaning cancer treatment may be subjected to a separate deductible than an ear infection. It is important to note that an annual deductible typically reimburses you more than per incident or per condition deductible. With OSLO you can choose your level of annual deductible."
                    },
                    {
                        question: "Reimbursement",
                        answer: "Compare dog insurance or cat insurance for reimbursement details, as not all companies pay on the actual veterinary bill, and some don’t cover the examination fees. All Oslo pet insurance plans are covering examination fees related to covered injuries and illnesses, and all plans offer a waiver for the co-pay and deductible associated with life-saving treatment."
                    },
                    {
                        question: "Claims Limits",
                        answer: "Some companies apply claim limits per incident, per year, or over the pet’s lifetime. Oslo does not limit the number of claims you can submit, nor will we raise your rates due to plan usage."
                    },
                    {
                        question: "Benefits Schedule vs. Annual Plan Cap",
                        answer: "Plans that adhere to a benefits schedule will limit the total amount of money that will be paid out per condition. Oslo’s plans offer one annual plan cap, no sub-limit and the highest plan benefits in the market."
                    },
                    {
                        question: "Pre-existing Conditions",
                        answer: "No pet insurance providers cover pre-existing conditions. Our definition of a pre-existing condition is any injury or illness whose symptoms were showing before the start of your pet’s policy, or during a relevant waiting period. (Note: A condition is considered pre-existing based on when its symptoms first displayed—regardless of whether or not it has been officially diagnosed or treated.)"
                    },
                    {
                        question: "Contract Terms and Customer Testimonials",
                        answer: "It is important to read all contract terms thoroughly, as misaligned expectations are often the root of dissatisfaction with a pet insurance provider."
                    }
                ]
            }
        ]
    },
    {
        title: "ADMINISTRATION",
        items: [
            {
                question: "How do I cancel my policy?",
                answer: "You may cancel your policy at any time by emailing us. We would be more than happy to discuss your cancellation over the phone.",
                hasChildItems: false
            },
            {
                question: "How do I add a pet to an existing policy or account?",
                answer: "Each pet has its own policy with its own plan, co-insurance, deductible options. The easiest way for you to enroll an additional pet is by logging into your Oslo Pet Cloud. Remember, you can always call us at +971 4 248 0561. We are more than happy to help!",
                hasChildItems: false
            },
            {
                question: "What are Oslo's hours of operation?",
                answer: "Customer Experience (Central Time): Monday-Friday 8.30am-4.30pm | Saturday-Sunday contact support@osloinsurance.pet",
                hasChildItems: true,
                childItems: [
                    {
                        question: "Claims Support (Central Time):",
                        answer: "Monday-Friday 8.30am-4.30pm (claim-related support)"
                    },
                    {
                        question: "Customer Experience (Central Time):",
                        answer: "Monday-Friday 8.30am-4.30pm | Saturday-Sunday contact support@osloinsurance.pet"
                    }
                ]
            },
            {
                question: "Does Oslo have a money back guarantee?",
                answer: "We offer a 30-day money-back guarantee, so feel free to take a look and see what Oslo can do for you and your pet. If, within the first 30 days of your policy you wish to cancel, we will provide you with a full refund of the premium amount, provided you have not filed a claim. If you cancel your policy after the first 30 days or if you have submitted a claim, any possible refund is subject to terms and conditions.",
                hasChildItems: false
            },
            {
                question: "What is the one-time administration fee?",
                answer: "We may charge an enrollment fee. Please contact Customer Experience to know more.",
                hasChildItems: false
            },
            {
                question: "What happens to my pet insurance policy if I move?",
                answer: "If you move within UAE, you are able to adjust your address via the “My Account” section in the Oslo Pet Cloud. You will also need to update your billing address via the “Insurance” section in the Oslo Pet Cloud. If you are moving to a new country, please call us at +971 4 248 0561. We will need to assist in transferring your policy to your new country, in order to maintain your pre-existing conditions history.",
                hasChildItems: false
            },
            {
                question: "Can I reinstate my coverage if my policy cancels due to non-payment?",
                answer: "We know things happen and sometimes automatic payments don’t go through. That’s why if your policy cancels due to non-payment, we may reinstate the policy with no change in coverage. As long as there are no claims during the cancellation period, this will be an easy fix.",
                hasChildItems: false
            },
            {
                question: "Can I transfer my policy to a new owner?",
                answer: "If the ownership of your pet changes to another individual, we are able to transfer the policy to the new pet owner without any interruption of coverage. To process this request, you will need to submit proof of new ownership, including the new owner's full name, address and phone number. We will also need to update the billing portion of the policy to include the new customer's preferred payment method prior to processing the policy transfer.",
                hasChildItems: false
            },
            {
                question: "Can I change my pet’s coverage?",
                answer: "For questions about upgrading or downgrading your policy—subject to terms and conditions—please contact Customer Experience for more information.",
                hasChildItems: false
            },
            {
                question: "How will I receive my policy documents?",
                answer: "All policy documents will be conveniently sent by email. If you would prefer paper documentation mailed to you or if you have any questions about accessing this information, please contact Customer Experience.",
                hasChildItems: false
            }
        ]
    },
    {
        title: "BILLING",
        items: [
            {
                question: "Are there any payment fees?",
                answer: "We may charge a payment processing fee or installment fees.",
                hasChildItems: false
            },
            {
                question: "Can I change my billing date?",
                answer: "Unfortunately, no. Once a policy is issued, you cannot change the billing date because it is linked to the effective date of your policy.",
                hasChildItems: false
            },
            {
                question: "If I am paying annually, when is my premium due next?",
                answer: "If you are paying annually: 10 days before your renewal, you will be billed for the annual premium amount for the next term. You may also contact Customer Experience if you have any questions.",
                hasChildItems: false
            },
            {
                question: "What if my credit or debit card changes?",
                answer: "To protect us from fraud, credit card companies are frequently issuing new account and card numbers. We get this. If your payment is rejected for any reason, please contact Customer Experience.",
                hasChildItems: false
            }
        ]
    },
    {
        title: "CLAIMS",
        items: [
            {
                question: "Do I need pre-authorization from Oslo for veterinary treatment?",
                answer: "No, but if you would like more information as to if a treatment will be covered you may contact us at any time.",
                hasChildItems: false
            },
            {
                question: "How do I appeal my claim?",
                answer: "Please contact Customer Experience regarding your claim. All requests to appeal your claim must be made in writing to us. Any submitted appeal should state clearly why you or your vet disagree with the initial determination and include supporting documentation. To appeal your claim simply email your request and supporting documentation to us at Customer Experience.",
                hasChildItems: false
            },
            {
                question: "How do I file a claim?",
                answer: "Electronic/Paperless Claims Submission",
                hasChildItems: true,
                childItems: [
                    {
                        question: "Step 1",
                        answer: "Log into your Oslo Pet Cloud from any device."
                    },
                    {
                        question: "Step 2",
                        answer: "Select the “Send a claim” card and answer the online questions."
                    },
                    {
                        question: "Step 3",
                        answer: "Upload/attach a copy of your paid invoice and submit your claim."
                    },
                    {
                        question: "Step 4",
                        answer: "Have your veterinarian send us your pet’s medical records."
                    },
                    {
                        question: "Step 5",
                        answer: "You’re done!"
                    }
                ]
            },
            {
                question: "Documents Required",
                answer: "In order to process your (initial) claim, your veterinarian needs to send us the last 2 years of medical records including notes. Your veterinarian can email the records to your Oslo Pet Cloud directly using your Personal Pet Cloud Email Address. The email address is located at the top of the page after you log into your account on desktop or mobile. Your veterinarian can also email them to support@Osloinsurance.pet.",
                hasChildItems: false
            },
            {
                question: "Claims Reimbursement",
                answer: "After your completed claims, along with all necessary invoices and medical records are submitted, we will work diligently to expedite your claim. We will process your claim within 30 days of our receipt of all required information, but our goal is 7-10 business days.",
                hasChildItems: false
            },
            {
                question: "How do I get reimbursed or check my claim status?",
                answer: "You can check the status of your claim by logging into the Oslo Pet Cloud and selecting the claims icon. Your reimbursement method is selected at the time of submission for each claim.",
                hasChildItems: false
            },
            {
                question: "How long do I have to file a claim?",
                answer: "We ask that you file your claim within a reasonable timeframe following your pet’s illness or injury.",
                hasChildItems: false
            },
            {
                question: "Should I file a claim if my bill is less than my deductible?",
                answer: "Yes. All covered treatments will help you meet your annual deductible. And filing a claim does not impact your rates.",
                hasChildItems: false
            }
        ]
    },
    {
        title: "RENEWAL",
        items: [
            {
                question: "Will my premium increase?",
                answer: "All pet insurance companies, no matter how they promote their plans, will raise rates accordingly to keep up with the rising cost of veterinary care. These necessary increases provide you the opportunity to offer your pet the best medical treatment options available. Additionally, your premium will be affected by your pet's age each year, as the risk for more costly claims grows higher. Oslo strives to provide coverage well into your pet's old-age by not placing upper-age limitations on our policies.",
                hasChildItems: false
            },
            {
                question: "How do I renew my policy?",
                answer: "Your policy will renew automatically each year unless you notify Oslo that you wish to cancel. You can view your renewal declaration page at least 30 days prior to your policy’s expiration date.",
                hasChildItems: false
            }
        ]
    }
];

export default faqItems;