import React, { Component } from 'react';
import { Typography, Checkbox, Row, Col, Button, Divider, Input, Pagination, Spin } from 'antd';
import MainLayout from '../layouts/mainLayout'
import Map from './getQoutes/map';
import { searchVet, searchVetDetails } from '../api/pets-api';
import _ from 'lodash';
const { Title } = Typography;
const incRad = 1000;

class vets_page extends Component {

    constructor(props) {
        super(props);
        this.debouncedChangeHandler = _.debounce(this.debouncedChangeHandler, 1000);
        this.state = {
            lat: 25.2048,
            lng: 55.2708,
            keyword: 'v',
            allowed: localStorage['authorizedGeoLocation']=='true',
            spin: false,
            mountFlag: false,
            radius: 10000,
            vetsNearMe: [],
        };
    }
    
    onChange = async (e) => {
        console.log(`checked = ${e.target.checked}`);
        localStorage['authorizedGeoLocation'] = e.target.checked;
        if(e.target.checked == true){
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({lat: position.coords.latitude, lng: position.coords.longitude, allowed: true, spin: true, keyword:'v', radius: 10000},async ()=>{
                    this.searchAndSetVets('loc')
                })
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
            });
        }else{
            this.setState({lat: 25.2048, lng: 55.2708, allowed: false});
        }
        console.log('permission', localStorage['authorizedGeoLocation']);
    }

    searchAndSetVets = async (from) => {
        let vets = await this.searchVets(from);
        this.setState({vetsNearMe: vets.data?.response?.results, spin: false, mountFlag:true})

        var selectedVets = vets.data?.response?.results;

        if(selectedVets && selectedVets.length > 0){
            for(let i = 0; i < vets.data?.response?.results.length; i++){
                var itm = vets.data?.response?.results[i];
                 let respo = await searchVetDetails(itm.place_id);
                 if(respo && respo.data?.response && respo.data?.response?.result){
                    itm = {place_details: respo.data?.response.result, ...itm};
                 }
                 selectedVets[i] = itm;
            }
            // var vetsWithDetails = await vets.data?.response?.results.map(async(itm) => {
            //     console.log('itm', itm.place_id);
            //      let respo = await searchVetDetails(itm.place_id);
            //      if(respo && respo.data?.response && respo.data?.response?.result){
            //         itm.place_details = respo.response.result;
            //      }
            //      return itm;
            // });
            // console.log('vets with details', vetsWithDetails);
            console.log('vets with detials two', selectedVets);
            this.setState({vetsNearMe: selectedVets})
        }

        // console.log('vets1',vets);
        //this.setState({vetsNearMe: vets.data?.response?.results, spin: false, mountFlag:true})
        
    }
    
    searchVets = async (from) => {
        return new Promise(async (resolve, reject) => {
            let vets = await searchVet(this.state.lat, this.state.lng, this.state.keyword, from==='loc'?this.state.radius:10000);
            
            // console.log('vets2',vets);
            if(!vets.data.response.results && from==='loc' && this.state.radius<6371000){
                this.setState({radius: this.state.radius*3},()=>{
                    this.searchVets('loc')
                })
            }else{
                resolve(vets)
            }
        })
    }

    debouncedChangeHandler = (e) => {
        try {
            console.log(`key value = ${e.target.value.length}`, this.state.allowed, e.target.value);
            this.onKeywordChange(e)
        } catch(error) {
            this.setState({error});
        }
    }    

    onKeywordChange = (e) => {
        console.log(`key value = ${e.target.value.length}`, this.state.allowed, e.target.value);
        this.setState({keyword: e.target.value || 'v',spin: true},()=>{
            this.state.allowed && this.searchAndSetVets('key');
        })
    }

    componentDidMount = () => {
        // console.log('permission', this.state.allowed == 'true');
        if(this.state.allowed){
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({lat: position.coords.latitude, lng: position.coords.longitude, allowed: true, spin: true, keyword:'v', radius: 10000},async ()=>{
                    this.searchAndSetVets('loc')
                })
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
            });
        }else{
            this.setState({lat: 25.2048, lng: 55.2708, allowed: false, mountFlag:true});
        }        
    }

    onClickIncrease = () => {
        this.setState({radius: (this.state.radius+incRad)},()=>{
            this.searchAndSetVets();
        })
    }

    onDirectionClick = (lat,lng,placeId) => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${placeId}`, '_blank')
    }

    render() {
        return (
            <MainLayout>
                <div className="banner">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-vets-page.jpg"
                    />
                    <div className="banner-caption mt-5">
                        <div className="container">
                            <Row gutter={15} className="mt-5">
                                <Col xs={24} sm={24} md={24} lg={24} xl={14}></Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-white tagline">Vets</Title>
                                        <Title level={3} className="text-white mt-0 mb-5 sub-tagline">Let’s find the nearest one!</Title>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="full-width-section vets-page-content full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green text-center section-title mb-5">Find a vet near me </Title>
                            </Col>
                        </Row>
                        <Row gutter={[15, 40]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                {/* <img
                                    className="img-fluid w-100"
                                    src="/images/vet-map.png"
                                /> */}
                                
                                {this.state.mountFlag && <Map
                                    google={this.props.google}
                                    center={{ lat: this.state.lat, lng: this.state.lng }}
                                    height='300px'
                                    zoom={13}
                                    autoComplete={false}
                                    vetPageFlag={true}
                                    mountFlag={this.state.mountFlag}
                                    keyword={this.state.keyword}
                                    nearMe={this.state.vetsNearMe}
                                />}
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <div className="customise-form">
                                    <Checkbox className="use-location" checked={this.state.allowed} onChange={this.onChange}>Use my location</Checkbox>
                                    <Input className="mt-3" size="large" placeholder="Vet’s name or location" onChange={this.debouncedChangeHandler}/>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Spin spinning={this.state.spin}>

                                    {
                                        this.state.allowed && this.state.vetsNearMe?.map(place=>(
                                            <div className="section-gray p-5">
                                                <Row gutter={15} align="middle" justify="center">
                                                    <Col xs={24} sm={244} md={24} lg={24} xl={24}>
                                                        <Title level={2} className="text-green font-300">{place.name}</Title>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                        <div className="vet-address">
                                                            <Title level={3} className="font-300">
                                                                {place.vicinity}
                                                            </Title>
                                                            {place.place_details && <div>
                                                            {place.place_details.formatted_phone_number && <Title level={3} className="font-300 mt-0">
                                                                <img
                                                                    className="img-fluid"
                                                                    src="/images/icon-phone-md.png"
                                                                /> {place.place_details.formatted_phone_number}
                                                            </Title>}
                                                            {place.place_details.website && <a className="text-light-green" href={place.place_details.website} target="_blank">{place.place_details.website}</a>}</div>}
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={2} lg={2} xl={2} className="text-center">
                                                        <Divider className="custom-divider-vertical" type="vertical" />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                        <div className="opening-hours text-center">
                                                            {/* <Title level={3} className="font-300">
                                                                Open from 6am to 11pm <br />
                                                                Sunday to Friday
                                                            </Title> */}
                                                            <Button size="large" type="primary" className="btn-blue rounded-100" onClick={()=>this.onDirectionClick(place.geometry.location.lat, place.geometry.location.lng, place.place_id)}>Get directions</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>    
                                        ))
                                    }
                                    {
                                        !this.state.allowed && <div className="vet-address">
                                            <Title level={3} className="font-300">
                                                
                                            </Title>
                                        </div>
                                    }
                                    {
                                        ((this.state.allowed && !this.state.vetsNearMe?.length) && 
                                            <div className="section-gray p-5">
                                                <Row gutter={15} align="middle" justify="center">
                                                    <Col xs={24} sm={244} md={24} lg={24} xl={24}>
                                                        <Title level={2} className="text-green font-300">No vets found near you</Title>
                                                    </Col>
                                                    {/* <Col xs={24} sm={24} md={2} lg={2} xl={2} className="text-center">
                                                        <Divider className="custom-divider-vertical" type="vertical" />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                        <div className="opening-hours text-center">
                                                            <Button size="large" type="primary" className="btn-blue rounded-100" onClick={this.onClickIncrease}>Find in bigger radius</Button>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                            </div>    
                                        
                                        )
                                    }

                                </Spin>
                            </Col>
                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                                <Pagination defaultCurrent={1} total={30} />
                            </Col> */}
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default vets_page;