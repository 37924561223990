import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Typography, Card } from 'antd';

const { Title } = Typography;
const { Meta } = Card;

class SideMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="card-sidebar">
                <Link className="no-text-decoration" to={"/dashboard"}>
                    <Card className="card-content pb-5 pt-5 v1" cover={<img src="/images/img-talk-to-vet.png" />}>
                        <Meta className="mb-4 text-center" title={<Title className="mb-0 text-green font-700" level={2}>Talk to a vet</Title>} />
                    </Card>
                </Link>
                <Link className="no-text-decoration" to={"/claim-steps"}>
                    <Card className={window.location.pathname === '/claim-steps' ? "card-content pb-5 pt-5 v1 active" : "card-content pb-5 pt-5 v1"} cover={<img src="/images/img-send-claim.png" />}>
                        <Meta className="mb-4 text-center" title={<Title className="mb-0 text-green font-700" level={2}>Send a claim</Title>} />
                    </Card>
                </Link>
            </div>
        );
    }
}

export default SideMenu;