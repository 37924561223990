import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Layout, Row, Col } from 'antd';
const { Footer } = Layout;

class loginFooter extends Component {
    render() {
        return (
            <Fragment>
                <Footer className="dashboard-footer">
                    <Row gutter={15} align="middle">
                        <Col xs={24} sm={24} md={5} lg={3} xl={4}>
                            <div className="footer-links">
                                <Link className="text-white no-text-decoration" to={'/contact'}>
                                    Contact
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={19} lg={21} xl={20}>
                            <div className="social-links">
                                <ul className="mb-0">
                                    <li>
                                        <a href="https://www.instagram.com/osloinsurance.pet/" target={'_blank'} rel="noreferrer">
                                            <img
                                                alt='instagram'
                                                className="img-fluid"
                                                src="/images/instagram.png"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/osloinsurance/?ref=py_c" target={'_blank'} rel="noreferrer">
                                            <img
                                                alt='facebook'
                                                className="img-fluid"
                                                src="/images/facebook.png"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/oslo-insurance-pet-49ba26206/" target={'_blank'} rel="noreferrer">
                                            <img
                                                alt='linkedin'
                                                className="img-fluid"
                                                src="/images/linkedin.png"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Footer>
            </Fragment>
        );
    }
}

export default loginFooter;