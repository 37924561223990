import React, { Component } from 'react';
import { Typography, Card, Row, Col, Button, Input } from 'antd';


const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const onChange = (e) => {
    console.log('Change:', e.target.value);
}

class VacatoinBooked extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yesBtn: 'btn-red',
            noBtn: 'btn-red-outline',
        }
    }

    componentDidMount(){
        // this.props.populateClaimObj('isVacationBooked',this.props.claimObj.isVacationBooked || true)
        this.props.claimObj.isVacationBooked || this.props.claimObj.isVacationBooked === undefined ? this.onYesClicked() : this.onNoClicked()
    }

    onYesClicked = () =>{
        console.log('yes');
        this.props.populateClaimObj('isVacationBooked',true)
        this.setState({yesBtn: 'btn-red', noBtn: 'btn-red-outline'});
    }
    
    onNoClicked = () =>{
        console.log('no');
        
        this.props.populateClaimObj('isVacationBooked',false)
        this.setState({yesBtn: 'btn-red-outline', noBtn: 'btn-red'});
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={17} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">Was your vacation booked 28 days or more in advance?</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[40, 20]} justify="center">
                                <Col xl={5} lg={5} md={7} sm={7} xs={24}>
                                    <Button onClick={this.onYesClicked} block size="large" className={`${this.state.yesBtn} font-800 border-radius-10`}>
                                        Yes
                                    </Button>
                                </Col>
                                <Col xl={5} lg={5} md={7} sm={7} xs={24}>
                                    <Button onClick={this.onNoClicked} block size="large" className={`${this.state.noBtn} font-800 border-radius-10`}>
                                        No
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={2} lg={2} md={2} sm={2}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('vacation')}>
                                        <i className="fa fa-angle-up"></i>
                                    </Button>
                                </Col>
                                <Col xl={2} lg={2} md={2} sm={2}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('vacation')}>
                                        <i className="fa fa-angle-down"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* <Col xl={7} lg={7} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                    </Card>
                </Col> */}
            </Row>
        );
    }
}

export default VacatoinBooked;