import React, { Component, Fragment } from 'react';
import { Typography, Image, Row, Col, Button, Tooltip, Form, Input, Select } from 'antd';
import { Link } from "react-router-dom";
import MainLayout from '../layouts/mainLayout'
import { COVERAGE_MAP } from '../constants';
import FeaturesTable from './getQoutes/features_table';
const { Paragraph, Title } = Typography;
const { Option } = Select;
// const [form] = Form.useForm();

class PetInsurance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cvrObj: {}
        };

    }
    pageRef = React.createRef();
    formRef = React.createRef();

    onDetailClick = () => {
        this.pageRef.current.scrollIntoView();
    }
    onFinish(values) {
        console.log(values);
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    onBreedChange = (breed) => {
        let cvrObj = { breed }
        if (this.state.cvrObj.breed && this.state.cvrObj.breed !== breed) {
            this.formRef.current.setFieldsValue({ pet_disease: '' });
            this.formRef.current.setFieldsValue({ oslo_plan: '' });
            this.formRef.current.setFieldsValue({ oslo_deductible: '' });
            this.formRef.current.setFieldsValue({ oslo_reimbursement: '' });
            this.formRef.current.setFieldsValue({ i_paid: '' });
            this.formRef.current.setFieldsValue({ oslo_paid: '' });

        }
        // this.formRef.resetFields();
        this.setState({ cvrObj })

    }
    onReimbursmentChange = (r) => {
        let cvrObj = this.state.cvrObj;
        cvrObj.reimbursment = r;
        this.setState({ cvrObj }, () => {
            if (cvrObj.deductable) {
                this.calculateInsurance();
            }
        })

    }

    onDeductableChange = (d) => {
        let cvrObj = this.state.cvrObj;
        cvrObj.deductable = d;
        this.setState({ cvrObj }, () => {
            if (cvrObj.reimbursment) {
                this.calculateInsurance();
            }
        })


    }

    calculateInsurance = () => {
        let breed = this.state.cvrObj.breed;
        let disease = this.state.cvrObj.disease;

        let reimbursment = this.state.cvrObj.reimbursment;
        let deductable = this.state.cvrObj.deductable;
        let ratio = reimbursment + '_' + deductable;

        let coverage = COVERAGE_MAP[breed][disease].claim;
        let given = COVERAGE_MAP[breed][disease][ratio];

        let iPaid = coverage - given;

        if(!isNaN(iPaid) && !isNaN(given))
        {
            this.formRef.current.setFieldsValue({ oslo_paid: 'AED '+ given, i_paid: 'AED '+ iPaid });
            this.setState({ osloPaid: given, iPaid })
            console.log(iPaid, ratio);
        }
        else{
            this.formRef.current.setFieldsValue({ oslo_paid: '', i_paid: '' });
            this.setState({ osloPaid: '', iPaid: '' })
        }
    }

    onDiseaseChange = (d) => {
        let cvrObj = this.state.cvrObj
        cvrObj['disease'] = d;
        this.setState({ cvrObj }, () => {
            console.log('disease', this.state.cvrObj.disease, COVERAGE_MAP[this.state.cvrObj.breed][this.state.cvrObj.disease].claim);
            this.calculateInsurance();
        })
    }

    onPlanChange = (p) => {
        let cvrObj = this.state.cvrObj
        cvrObj.plan = p;
        this.setState({ cvrObj });
    }

    render() {
        return (
            <MainLayout>
                <div className="banner">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-pet-insurance.jpg"
                    />
                    <div className="banner-caption">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-white tagline">Pet Insurance</Title>
                                        <Title level={3} className="text-white mt-0 mb-5 sub-tagline">Peace of mind, that’s what you’re buying</Title>
                                        <Button size="large" type="primary" className="btn-red"><Link className="no-text-decoration" to={"/ins"}>Get a Quote</Link></Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="full-width-section full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green mb-0 text-center section-title">What is pet insurance?</Title>
                                <Title level={1} className="text-dark-green mb-4 font-300">We know, pet insurance can be confusing! Let OSLO free you from stress when choosing the best available veterinary care for your pet.</Title>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="points-toknow dot-center">
                                    <ul>
                                        <li>Our plans cover vet bills due to unexpected accident or illness for your pet</li>
                                        <li>3 flexible plans which can be customized to meet your budget and coverage needs</li>
                                        <li>Visit any licensed vet practice, emergency hospital or specialist in the UAE</li>
                                        <li>We may reimburse up to 90% of your actual vet bill</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="full-width-section pet-tags bg-gray-light full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green mb-0 section-title text-center">Is it worth?</Title>
                                <Title level={1} className="text-dark-green mb-5 font-300">
                                    We believe the best medical care for our pets should lead to an automatic “<span className="text-light-green">YES</span>”, without an hesitation.
                                </Title>
                                <Title level={3} className="text-muted font-300">Though we don’t like to think about it, 1 in 3 pets need emergency vet treatment each year when they get hurt or sick.  For us, pet parents, it may be a financial challenge!
                                    More vet specialist and advanced treatments are available today. While this is great, it can come at a steep price.</Title>
                                <Title level={3} className="text-muted font-300">Pet insurance will help secure the right decision for your pet and yourself.
                                    With OSLO, you’ll get coverage against unexpected injuries and illnesses.</Title>
                                <div className="total-amount p-5 mt-5 font-700">Get reimbursed up to 90% of your vet bill</div>
                            </Col>
                        </Row>

                    </div>
                </div>
                <div className="full-width-section full-width">
                    <div className="container">
                        <Title level={2} className="text-green text-center mb-5 section-title">How does OSLO work?</Title>
                        <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="pet-insurance-box text-center">
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/step1.png"
                                    />
                                    <Title level={3} className="text-green font-700">Take your pet to the vets</Title>
                                    <Paragraph>Seek the best veterinary treatment for your pet when an accident or illness occurs.</Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="pet-insurance-box text-center">
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/step2.png"
                                    />
                                    <Title level={3} className="text-green font-700">Send us your claim</Title>
                                    <Paragraph>Send us a pic of your vet bill and submit it through our OSLO pet Cloud.</Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="pet-insurance-box text-center">
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/step3.png"
                                    />
                                    <Title level={3} className="text-green font-700">Get your money back</Title>
                                    <Paragraph>Follow your claim status and get paid by direct transfer.  </Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="text-center mt-3">
                                    <Button type="link" className="btn-red" size="large"><Link className="no-text-decoration" to={"/ins"}>Get a Quote</Link></Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="full-width-section bg-gray-light full-width">
                    <div className="container">
                        <Row gutter={[80, 30]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green mb-0 section-title text-center">Things you need to Know</Title>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div className="things-toknow">
                                    <Title level={3} className="text-green font-700">Veterinary Exam Fees</Title>
                                    <Paragraph className="text-muted text-desc">Believe it or not, some insurers won’t pay your vet examination fee related to a covered illness or injury. We do.</Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div className="things-toknow">
                                    <Title level={3} className="text-green font-700">Waiting periods</Title>
                                    <Paragraph className="text-muted text-desc">That’s the period of time right after you sign up but before full coverage starts.</Paragraph>
                                    <Paragraph className="text-muted text-desc">At OSLO, it lasts 14 days for illnesses. But only 5 days for accidents, because we know things happen!</Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div className="things-toknow">
                                    <Title level={3} className="text-green font-700">Annual Deductible</Title>
                                    <Paragraph className="text-muted text-desc">This is the fixed portion of the vet bill which the pet parent is responsible for. Some companies require you to pay a deductible for each new injury or illness that occurs. They call it “per condition”, we call it “not cool!”.</Paragraph>
                                    <Paragraph className="text-muted text-desc">With OSLO, the deductible is annual.</Paragraph>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div className="things-toknow">
                                    <Title level={3} className="text-green font-700">Co-insurance</Title>
                                    <Paragraph className="text-muted text-desc">This may sound confusing. Co-insurance is the portion of the vet bill which the pet parent will be responsible for vs. what the pet insurance will pay.</Paragraph>
                                    <Paragraph className="text-muted text-desc">At OSLO, you may choose between 3 various reimbursement options for your plan.</Paragraph>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="full-width-section pb-0 full-width">
                    <div className="container">
                        <Row gutter={[15, 15]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green mb-2 section-title text-center">What’s covered and what’s not?</Title>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="full-width bg-gray-light rounded-10 p-4">
                                    <Row gutter={[15, 15]}>
                                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                            <div className="points-toknow rounded-check">
                                                <ul>
                                                    <li>Exams & Consultation Fees</li>
                                                    <li>Emergency & Hospitalization</li>
                                                    <li>Surgeries</li>
                                                    <li>Hereditary & Congenital conditions</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                            <div className="points-toknow rounded-check">
                                                <ul>
                                                    <li>Chronic conditions</li>
                                                    <li>Cancer Treatments</li>
                                                    <li>Prescriptions</li>
                                                    <li>Veterinary Specialists</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                            <div className="points-toknow dot-center rounded-check">
                                                <ul>
                                                    <li>Emergency Boarding Fees</li>
                                                    <li>Imaging</li>
                                                    <li>Knee conditions</li>
                                                    <li>Prosthetic & Orthotic devices</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ marginTop: '-20px' }} className="full-width text-center mb-4">
                                    <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="right" title={<Fragment>
                                        <Title level={4} className="text-white text-center">OSLO Assistance is included for free!</Title>
                                        <Paragraph className="text-white mb-0">• Emergency boarding fees</Paragraph>
                                        <Paragraph className="text-white mb-0">• Lost pet</Paragraph>
                                        <Paragraph className="text-white mb-0">• Pet death</Paragraph>
                                        <Paragraph className="text-white mb-0">• Vacation cancellation</Paragraph>
                                    </Fragment>}>
                                        <img
                                            className="img-fluid"
                                            src="/images/iocn-gift.png"
                                        />
                                    </Tooltip>
                                </div>
                                <div className="full-width bg-gray-light rounded-10 p-4 mt-4">
                                    <Row gutter={15}>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                            <div className="points-toknow rounded-check red-cross">
                                                <ul>
                                                    <li>Pre-existing conditions</li>
                                                    <li>Routine & Wellness</li>
                                                    <li>Preventive Care</li>
                                                    <li>Spaying or Neutering</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                            <div className="points-toknow rounded-check red-cross">
                                                <ul>
                                                    <li>Experimental procedures</li>
                                                    <li>Breeding, pregnancy or birth</li>
                                                    <li>Cloning procedures or cloned pets</li>
                                                    <li>Cosmetic surgery</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                            <div className="points-toknow dot-center rounded-check red-cross">
                                                <ul>
                                                    <li>Coprophagia (“eating poop”)</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="full-width text-center mt-4">
                                    <Button type="link" className="btn-blue rounded-100" size="large" onClick={this.onDetailClick}>See more details</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="pet-needs-table-outer full-width" ref={this.pageRef}>
                    <div className="container">
                        <Title level={2} className="text-green text-center mb-4 section-title">The exact coverage level your pet needs</Title>
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <FeaturesTable />
                                
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="text-center mt-3">
                                    <Button type="link" className="btn-red" size="large"><Link className="no-text-decoration" to={"/ins"}>Get a Quote</Link></Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green text-center mb-3 mt-5 section-title">Now, it’s your turn!</Title>
                                <Title level={2} className="text-dark-green mb-5 font-300">Just choose from dropdown selections below to calculate how OSLO may pay your claim.</Title>
                                <Form
                                    name="found_lost_pet"
                                    size={"large"}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    className="customise-form calculate-oslo-claim"
                                    ref={this.formRef}
                                >
                                    <Row gutter={[15, 15]} align="middle">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={7} md={5} lg={24} xl={4}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">Hey, so my</Title>
                                                </Col>
                                                <Col xs={24} sm={6} md={6} lg={24} xl={4}>
                                                    <Form.Item
                                                        name="breed"
                                                        size={"large"}
                                                        className="mb-0 text-blue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select Dog or Cat',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select Dog/Cat" onChange={this.onBreedChange}>
                                                            <Option value={'dog'}>Dog</Option>
                                                            <Option value={'cat'}>Cat</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={14} md={12} lg={7} xl={7}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">got treated at the vet for </Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={5}>
                                                    <Form.Item
                                                        name="pet_disease"
                                                        size={"large"}
                                                        className="mb-0 text-blue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select disease!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select Disease" onChange={this.onDiseaseChange}>
                                                            {
                                                                this.state.cvrObj.breed && Object.keys(COVERAGE_MAP[this.state.cvrObj.breed]).map((key) => (
                                                                    <Option value={key}>{COVERAGE_MAP[this.state.cvrObj.breed][key].text}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">and the bill came up to</Title>
                                                </Col>
                                                <Col xs={24} sm={5} md={7} lg={5} xl={4}>
                                                    <Form.Item
                                                        name="pet_billing_amount"
                                                        className="mb-0 text-center"
                                                    >
                                                        {!this.state.cvrObj.disease && <Input readOnly placeholder="Bill" value="" />}
                                                        {this.state.cvrObj.disease && <Input readOnly placeholder="Bill" value={'AED '+ COVERAGE_MAP[this.state.cvrObj.breed][this.state.cvrObj.disease].claim} />}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={6} md={7} lg={5} xl={5}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">Yikes!</Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={14} md={12} lg={7} xl={5}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">Luckily, I had the</Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={5}>
                                                    <Form.Item
                                                        name="oslo_plan"
                                                        size={"large"}
                                                        className="mb-0 text-blue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Select your OSLO Plan',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select your OSLO Plan" onChange={this.onPlanChange}>
                                                            <Option value={'vital'}>Vital Plan</Option>
                                                            <Option value={'favorite'}>Favorite Plan</Option>
                                                            <Option value={'optimum'}>Optimum Plan</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={14} md={12} lg={7} xl={5}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">My deductible was </Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={4}>
                                                    <Form.Item
                                                        name="oslo_deductible"
                                                        size={"large"}
                                                        className="mb-0 text-blue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select your deductible!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select Deductible" onChange={this.onDeductableChange}>
                                                            <Option value={500}>AED 500</Option>
                                                            <Option value={1000}>AED 1000</Option>
                                                            <Option value={2000}>AED 2000</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={14} md={12} lg={7} xl={8}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">and my reimbursement was at</Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={4}>
                                                    <Form.Item
                                                        name="oslo_reimbursement"
                                                        size={"large"}
                                                        className="mb-0 text-blue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select your reimbursement!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select Reimbursement" onChange={this.onReimbursmentChange}>
                                                            <Option value={70}>70%</Option>
                                                            <Option value={80}>80%</Option>
                                                            <Option value={90}>90%</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row gutter={15} align="middle">
                                                <Col xs={24} sm={14} md={12} lg={7} xl={4}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">All I paid was</Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={4}>
                                                    <Form.Item
                                                        name="i_paid"
                                                        className="mb-0 text-center"
                                                        initialValue={this.state.cvrObj?.iPaid}
                                                    >
                                                        <Input readOnly={true} value={!isNaN(this.state.cvrObj?.iPaid) ? 'AED '+ this.state.cvrObj?.iPaid : ''} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={14} md={12} lg={7} xl={4}>
                                                    <Title level={2} className="text-dark-green mb-0 font-300">and OSLO paid</Title>
                                                </Col>
                                                <Col xs={24} sm={8} md={7} lg={5} xl={4}>
                                                    <Form.Item
                                                        name="oslo_paid"
                                                        className="mb-0 text-center"
                                                        initialValue={this.state.cvrObj?.osloPaid}
                                                    >
                                                        <Input readOnly={true} value={!isNaN(this.state.cvrObj?.osloPaid) ? 'AED '+ this.state.cvrObj?.osloPaid : ''} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-5">
                                            <Title level={3} className="text-muted font-300 bottom-text">Please note that amounts shown above are provided as an example for information purposes only.
                                                Actual cover level may vary depending on your final insurance terms and conditions.</Title>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center mt-5">
                                            <Button type="primary" htmlType="submit" className="btn-red" size="large">
                                                <Link to={"/ins"}>Get Quote</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default PetInsurance;