import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Typography, Row, Col, Card } from 'antd';
import LoginSidebar from '../../components/common/loginSidebar';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import { getUserPets } from '../../api/pets-api';

const { Title } = Typography;
const { Meta } = Card;
const { Content } = Layout;

class dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPets: []
        };
    }
    async componentDidMount() {
        await getUserPets().then((userPets) => {
            this.setState({ userPets: userPets.data.result })
        })
    }
    render() {
        return (
            <Layout className="dashboard middle home">
                <LoginHeader />
                <Content className="bg-paws">
                    <div className="home-cards">
                        <Link className="no-text-decoration card-outer">
                            <Card className="card-content v1" cover={<img src="/images/img-talk-to-vet.png" />}>
                                <Meta className="mt-5 mb-4 text-center" title={<Title className="mb-0 text-green font-700" level={2}>Talk to a vet</Title>} />
                            </Card>
                        </Link>

                        <Link className={window.location.pathname === '/claim-steps' ? "active no-text-decoration card-outer" : "no-text-decoration card-outer"} to={"/claim-steps"}>
                            <Card className="card-content v1" cover={<img src="/images/img-send-claim.png" />}>
                                <Meta className="mt-5 mb-4 text-center" title={<Title className="mb-0 text-green font-700" level={2}>Send a claim</Title>} />
                            </Card>
                        </Link>
                    </div>
                </Content>
                <LoginFooter />
            </Layout >
        );
    }
}

export default dashboard;