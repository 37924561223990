import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Button, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { logout, getUser } from '../../helpers/utilities';
const { Header } = Layout;


const MyAccountSettings = (
    <Menu className="dropdown-link">
        <Menu.Item key="1">
            <Link to={"/pets_cloud"}>1st menu item</Link>
        </Menu.Item>
        <Menu.Item key="2">
            <Link to={"/pets_cloud"}>2nd menu item</Link>
        </Menu.Item>
        <Menu.Item key="3">
            <Link to={"/pets_cloud"}>3rd menu item</Link>
        </Menu.Item>
        <Menu.Item key="4">
            <Link to={"/pets_cloud"}>4th menu item</Link>
        </Menu.Item>
        <Menu.Item key="5">
            <Link to={"/pets_cloud"}>5th menu item</Link>
        </Menu.Item>
    </Menu>
);

class LoginHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
        };
        this.logout = this.logout.bind(this);
    }
    componentDidMount() {
        this.setState({ user: getUser() });
    }
    logout() {
        logout();
    }
    render() {
        return (
            <Fragment>
                <Header className="dashboard-header">
                    <Row gutter={15} align="middle">
                        <Col xs={24} sm={24} md={4} lg={3} xl={4}>
                            <Link to={'/dashboard'}><img src="/images/logo.png" /></Link>
                        </Col>
                        <Col xs={24} sm={24} md={20} lg={21} xl={20}>
                            <div className="menu-dashboard">

                                <Link to={"/dashboard"} type="link" className={window.location.pathname === '/dashboard' ? "active" : ""}>Home</Link>
                                <Link to={"/my-pets"} type="link" className={window.location.pathname === '/my-pets' ? "active" : ""}>My Pets</Link>
                                <Link to={"/my-personal-info"} type="link" className={window.location.pathname === '/my-personal-info' ? "active" : ""}>My Personal Info</Link>
                                <Link to={"/claims"} type="link" className={window.location.pathname === '/claims' ? "active" : ""}>My Claims</Link>
                                {/* <Link to={"/claim-steps"} type="link" className={window.location.pathname === '/claim-steps' ? "active" : ""}>Claim Steps</Link> */}
                                {/* {this.state.user?.email} */}
                                {/* <li>
                                <Dropdown overlay={MyAccountSettings} placement="bottomRight">
                                    <a href="#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        My account <DownOutlined />
                                    </a>
                                </Dropdown>
                            </li> */}
                                <Link to={"/"} type="link" onClick={this.logout}>Logout</Link>

                            </div>
                        </Col>
                    </Row>
                </Header>
            </Fragment>
        );
    }
}

export default LoginHeader;