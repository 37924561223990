import React, { Component } from 'react';
import { Typography, Row, Col } from 'antd';
import MainLayout from '../layouts/mainLayout'
const { Paragraph, Title } = Typography;

class privacy_policy extends Component {

    render() {
        return (
            <MainLayout>
                <div className="banner privacy-policy">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-privacy-policy.jpg"
                    />
                    <div className="banner-caption">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-white tagline">Privacy policy</Title>
                                        <Title level={3} className="text-green mt-0 mb-5 sub-tagline">No worries, you’re safe  <br /> with us</Title>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="full-width-section privacy-policy-content full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="points-toknow">
                                    <ul className="red-dots">
                                        <li>
                                            <Title level={4} className="text-green">At OSLO, we value our relationship, and we believe strongly in protecting your privacy.</Title>
                                            <Paragraph className="text-green mb-0">Please read this Privacy Policy carefully to learn more about the ways in which we use and protect your Personal Data. This Privacy Policy outlines what types of non-public information is collected, to whom it may be disclosed, and how that information may be used.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">OSLO understands the importance of your privacy and the information that you may supply to us when using our Site. </Title>
                                            <Paragraph className="text-green mb-0">
                                                This Privacy Policy outlines how OSLO FZE and its partnering companies, including OSLO product underwriters (“<span className="text-light-green">OSLO</span>”, “<span className="text-light-green">we</span>”, “<span className="text-light-green">our</span>” or “<span className="text-light-green">us</span>”) use and protect the information that its policyholders, users, visitors, and customers (“<span className="text-light-green">you</span>”, “<span className="text-light-green">your</span>” or “<span className="text-light-green">User</span>”) provide to us through our website (the “<span className="text-light-green">Site</span>”) and services (the “<span className="text-light-green">Services</span>”). </Paragraph>
                                            <Paragraph className="text-green mb-0">By accessing the Site or using our Services, you agree that you have read, understood, and consented to the use and transfer of your information on the terms set out in this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Site or Services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-0">We reserve the right to modify or change this Privacy Policy at any time at our sole discretion. By continuing to access or use the Site, you will be agreeing to any such changes.</Paragraph>
                                            <Paragraph className="text-green mb-0">The official language used by OSLO is English; in the event of a conflict between the English language version of this document and any version translated into any other language, the English language version shall prevail.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">1. Definitions</Title>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Account</span>” means the account established by you in order to obtain certain benefits of the Site or Services.</Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Affiliates</span>” means companies that, either directly or indirectly, we control, are controlled by, or that are owned or controlled by the same entity that controls us.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Cookies</span>” means small files that are placed on your hard drive for identification purposes. These files are used for site registration and customization the next time you visit us.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Debit/Credit Card Information</span>” means certain credit card information, including your card number, CVV number, expiration date, phone number, name, billing address, and email address.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Personal Data</span>” means any information relating to an identified or identifiable natural person.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Sensitive Data</span>” means data that reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, data that concerns health, or data that concerns a natural person’s sex life or sexual orientation.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Site or Services</span>” means all OSLO domains, websites, software, applications, online services, and products, including osloinsurance.pet, Oslopetcloud.com, the OLSO Pet Cloud, and OSLO Connect Social Network.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">“<span className="text-light-green">Third Party Services</span>” means applications, products, services, or websites that are not under the control of or maintained by OSLO.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-0">“<span className="text-light-green">You</span>” and “<span className="text-light-green">Your</span>” means our policy holders, users, visitors, and customers.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">2. Collection of Personal Data</Title>
                                            <Paragraph className="text-green mb-0">OSLO may require you to register to use certain portions of the Site or certain Services and we may collect Personal Data from you to provide or service our products/services and to complete various other transactions, including without limitation to maintain an Account with OSLO and to obtain the benefits of the OSLO Pet Cloud.</Paragraph>
                                            <Paragraph className="text-green mb-0">The Personal Data we may collect from you in the process of creating and maintaining an Account may include:
                                            </Paragraph>
                                            <ul>
                                                <li>your name, postal address, email address, and telephone number</li>
                                                <li>policy coverage, claim history, premiums, and payment history
                                                </li>
                                                <li>information from a veterinary medical provider concerning your pet’s medical records
                                                </li>
                                            </ul>
                                            <Paragraph className="text-green mb-0">We may collect such Personal Data from you in the following ways:</Paragraph>
                                            <ul>
                                                <li>electronically (email or internet);</li>
                                                <li>in person;</li>
                                                <li>via telephone; or </li>
                                                <li>via forms you submit to us such as applications, claims, and policy updates.</li>
                                            </ul>
                                            <Paragraph className="text-green mb-0">The Site or Services may provide links to Third Party Services for your convenience and information. If you access those Third Party Services, you will leave the Site or Services. OSLO does not control those Third Party Services or their privacy practices, which may differ from OSLO’s practices. We do not endorse or make any representations about Third Party Services. The Personal Data you choose to provide to, or that is collected by, those Third Party Services is not covered by this Privacy Policy. We have no responsibility for any use of your Personal Data by such third parties. Any information you choose to provide to Third Party Services will be governed by the policies of such Third Party. </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">3. Purposes of Personal Data Collection</Title>
                                            <Paragraph className="text-green mb-2">OSLO uses the information collected for the following purposes:</Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Provision of our Services.</span> To provide the services we offer on the Site or Services, to communicate with you about your use of the Site or Services, to respond to your enquiries, to provide troubleshooting and for other customer services designed to make your experience better.</Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Personalization.</span> To tailor the content and information that we may send or display to you, to suggest personalized help and instructions, and to otherwise personalize your experience while visiting or using the Site or Services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Advertising.</span> To display interest-based advertising to you, to improve our advertising and measurement systems so that we can show you more relevant ads, and to measure the effectiveness and reach of ads and services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Marketing and Promotions.</span> For marketing, commercial, and promotional purposes, such as to send you news and updates, special offers, and promotions, or other otherwise contact you about products, services, or information we think may interest you, including information about third party products and services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Analytics.</span> To gather metrics to better understand how users access and use the Site or Services; to evaluate and improve the Site or Services; and to develop new products and services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Comply with the Law.</span> To comply with legal obligations, as part of our general business operations, and for other business administration purposes.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">• <span className="text-light-green">Prevent Misuse.</span> Where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, or violations of our terms of use or this Privacy Policy.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">4. Use of Information Collected</Title>
                                            <Paragraph className="text-green mb-0">Generally, OSLO collects your information to understand your needs and to help us deliver a consistent and personalized experience. As such, OSLO may use your Personal Data to:
                                            </Paragraph>
                                            <ul>
                                                <li>provide you with the Services which you have requested and notifying you about important changes or developments to these Services;</li>
                                                <li>enable the underwriting and administration of your insurance policy, including the processing of your claims and provision of support;</li>
                                                <li>administer offers, competitions and other personalized promotional offers;</li>
                                                <li>develop new products and services communicate with you about certain products and Services;</li>
                                                <li>supply you with relevant research on pet health or other selected content;</li>
                                                <li>measure performance of our marketing initiatives and for research, training and statistical analysis with the aim of improving our Services; </li>
                                                <li>prevent and detect security threats, fraud, or other malicious activity;</li>
                                                <li>administer your records and to maintain our database and records;</li>
                                                <li>train our employees in connection with providing our Service to you.</li>
                                            </ul>
                                            <Paragraph className="text-green mt-4 mb-0">We will treat your Personal Data as confidential but we may share it with our Affiliates or third parties that provide services to us and that agree to keep such Personal Data confidential, if:</Paragraph>
                                            <ul>
                                                <li>you consent;</li>
                                                <li>it is needed by our agents, advisors or others involved in providing services to you;</li>
                                                <li>the law or public interest permits or requires it;</li>
                                                <li>required by us or others to investigate or prevent crime.</li>
                                            </ul>
                                            <Paragraph className="text-green mt-4 mb-2">In order for us to obtain or to service the products that you ask for from us, we may need to disclose your Personal Data to our Affiliates and to unaffiliated companies but will only do so with your prior affirmative and unequivocal consent, and we will share only the amount of Personal Data as is necessary to provide a particular service or product.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-0">We may disclose Personal Data to the following persons, including without limitation:</Paragraph>
                                            <ul>
                                                <li>veterinary facilities or providers, to verify coverage and benefits;</li>
                                                <li>financial or insurance companies and insurance agents or brokers;</li>
                                                <li>service providers that perform insurance or business functions for us, including our insurance carrier and our actuarial consultants; and</li>
                                                <li>service providers, including veterinary services providers, who help process or service your account or respond to your queries.</li>
                                            </ul>
                                            <Paragraph className="text-green mb-2">We may share Personal Data with entities or organizations that collect insurance data about claim history and amounts paid. Such entities or organizations may retain that information and disclose it to other insurance companies and others legally entitled to see such data.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">We may collect and aggregate general information about you and your pets and use that information for limited commercial purposes. We may for instance contact you about products and services available from unaffiliated selected companies, which we believe may interest you or benefit you. We may do this by post or by phone unless you have told us not to; and by email, text or other electronic means, if you have told us that we can or as otherwise allowed by law. You may tell us at any time if you change your mind.</Paragraph>
                                            <Paragraph className="text-green mb-4">We will however not sell information that could be used by a third party to identify you individually, whether by name, address, or other similarly private information. For example, we may collect and aggregate broadly measurable demographic information such as the breed of your pets and your city of residence.</Paragraph>
                                            <Paragraph className="text-green mb-2">OSLO may post customer news, events, or testimonials on the Site or Services.  The OSLO Pet Cloud may contain images and content supplied by users.  Such information is only posted with the express permission of the individual or company who provided that information, which consent may be granted in the process of registering to obtain an Account. </Paragraph>
                                            <Paragraph className="text-green mb-4">OSLO may also, from time to time, take photographs and videos of events at OSLO locations or OSLO sponsored events. OSLO may post such photographs and videos on the Site or Services, including OSLO’s social network pages (e.g., Facebook, Instagram and LinkedIn). OSLO will use its reasonable efforts to remove any posted photo or video upon written notice from you requesting such removal. All such requests should be submitted to <span className="text-light-green">media@osloinsurance.pet.</span>
                                            </Paragraph>
                                            <Paragraph className="text-green mb-0">We may also disclose Personal Data under the following circumstances:
                                            </Paragraph>
                                            <ul>
                                                <li>to respond to duly authorized information requests from police and governmental authorities;</li>
                                                <li>to comply with any law, regulation or court order;</li>
                                                <li>to investigate and help prevent security threats, fraud, or other malicious activity;</li>
                                                <li>to enforce and protect the rights and properties of OSLO or its Affiliates; and</li>
                                                <li>to protect the rights or personal safety of OSLO employees and third parties on or using OSLO property.
                                                </li>
                                            </ul>
                                            <Paragraph className="text-green mt-4 mb-0">We do not store Debit/Credit Card Information or other payment method information, and direct debit payments are processed through a third-party vendor.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">5. Personal Data Processing Location</Title>
                                            <Paragraph className="text-green mb-2">The processing of your Personal Data takes place in the UAE.</Paragraph>
                                            <Paragraph className="text-green mb-2">Your information may be transferred to and maintained on computers and servers located outside of your country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside UAE and choose to provide information to us, OSLO transfers Personal Data to the UAE and processes it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">6. Access to and accuracy of your information</Title>
                                            <Paragraph className="text-green mb-2">OSLO strives to keep your Personal Data accurately recorded. You may access and request the correction, amendment, or deletion of all of your recorded Personal Data that has been collected by us. If you wish to review your recorded Personal Data, please contact us at media@osloinsurance.pet.
                                            </Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">7. Use of Cookies</Title>
                                            <Paragraph className="text-green mb-2">The Site or Services use standard Internet technologies, such as Cookies in order to retrace your interaction with the Site and Services. These files are used for site registration and customization the next time you visit us. Your web browser may allow you to be notified when you are receiving a Cookie, giving you the choice to accept it or not. You have the ability to prevent Cookies from being saved on your computer by making the appropriate adjustments in your browser options. By not accepting Cookies, some pages may not fully function and you may not be able to access certain information on a Site or Services.
                                            </Paragraph>
                                            <Paragraph className="text-green mb-2">Cookies help us to understand your website usage, so we can continually improve its usability. Cookies are used in this Site by us and by third parties:</Paragraph>
                                            <ul>
                                                <li>to monitor and manage Site traffic;</li>
                                                <li>to evaluate the effectiveness of our advertising and promotions;</li>
                                                <li>to identify you when you visit the Site, to personalize the content of our Site and to enable you to carry out transactions and have access to your Account;</li>
                                                <li>to temporarily to store details input into calculators and other tools on the Site; and.</li>
                                                <li>where required as a matter of law or regulation, to protect our or our customers’ rights.</li>
                                            </ul>
                                            <Paragraph className="text-green mb-0">OSLO does not have control over third party Cookies, including without limitation third party cookies from the web analysis tool Google Analytics.</Paragraph>
                                        </li>
                                        <li>
                                            <Title level={4} className="text-green">8. Contact</Title>
                                            <Paragraph className="text-green mb-4">We value your opinions. If you have comments or questions about this Privacy Policy, please send them to <span className="text-light-green">media@osloinsurance.pet.</span>
                                            </Paragraph>
                                            <em className="text-light-green">Last updated on December 8, 2021. </em>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default privacy_policy;