//require('dotenv').config()

//Base URL
// export const BASE_URL = process.env.BASE_URL;
// console.log(process.env);
export const BASE_URL = 'https://api-staging.osloinsurance.pet/api';


//API URL
export const LOGIN_API = BASE_URL + '/users/authenticate'
export const USER_PETS_API = BASE_URL + '/users/pets'
export const USER_POLICIES_API = BASE_URL + '/users/policies'
export const USER_DETAILS = BASE_URL + '/users'
export const FORGET_PASSWORD_API = BASE_URL + '/users/forgotPassword'
export const RESET_PASSWORD_API = BASE_URL + '/users/resetPassword'
// export const UPLOAD_DOCS_API = BASE_URL + '/file/upload'
export const EMIRATES_ID_API = BASE_URL + '/quotes/upload'
export const UPLOAD_DOCS_API = BASE_URL + '/files'
export const GET_DOCS_API = BASE_URL + '/files'
export const DELETE_DOCS_API = BASE_URL + '/file'
export const EDIT_DOCS_API = BASE_URL + '/files'
export const CREATE_REMINDER_API = BASE_URL + '/reminders'
export const CREATE_QOUTES_API = BASE_URL + '/quotes/create'
export const CREATE_QOUTES_WITHOUT_PAYMENT_API = BASE_URL + '/quotes/createWithoutPayment'
export const VALIDATE_QOUTES_API = BASE_URL + '/quotes/validate'
export const EDIT_REMINDER_API = BASE_URL + '/reminder'
export const GET_REMINDER_API = BASE_URL + '/reminders'
export const GET_CLAIMS_API = BASE_URL + '/claims'
export const DELETE_REMINDER_API = BASE_URL + '/reminders'
export const UPLOAD_PET_IMAGE_API = BASE_URL + '/pets/upload'
export const UPLOAD_USER_IMAGE_API = BASE_URL + '/users/upload'
export const UPLOAD_CLAIM_DOCS_API = BASE_URL + '/claims/uploads'
export const UPLOAD_CLAIM_DOCS_WITHOUT_DELETING_API = BASE_URL + '/claims/uploadWithoutDeleting'
export const TYPE_BREEDS_API = BASE_URL + '/pets/breeds'
export const PET_UPDATE = BASE_URL + '/pets'
export const TYPES_API = BASE_URL + '/pets/types'
export const UPCOMING_REMINDERS = BASE_URL + '/reminders/upcoming'
export const GET_PLANS = BASE_URL + '/plans'
export const GENRATE_PRICE = BASE_URL + '/quotes/priceGenerator'
export const CREATE_CLAIM = BASE_URL + '/claims'
export const FETCH_VETS = BASE_URL + '/searchVets'
export const FETCH_VETS_DETAILS = BASE_URL + '/getPlaceDetails'
export const MAKE_PAYMENT = BASE_URL + '/payment/make'
export const MAKE_PAYMENT_TAMARA = BASE_URL + '/payment/tamara'
export const GET_PAYMENT_STATUS = BASE_URL + '/payment/getStatus'
export const GET_TAMARA_PAYMENT_STATUS = BASE_URL + '/payment/getTamaraStatus'
export const GET_LEAD_DETAILS = BASE_URL + '/lead/getLead'
export const GET_LEAD_DETAILS_BY_ID = BASE_URL + '/lead/getLeadById'
export const SEARCH_LEADS = BASE_URL + '/lead/searchLeads'
export const CREATE_LEAD = BASE_URL + '/lead/save'
export const UPDATE_LEAD = BASE_URL + '/lead/updateLead'
export const SEND_LEAD_MAIL = BASE_URL + '/lead/sendmail'
export const GET_USER_IMAGE = BASE_URL + '/users/image'
export const GET_POLICIES_ADMIN = BASE_URL + '/policies/bystatus'
export const GET_POLICY_BY_ID_ADMIN = BASE_URL + '/policies/byid'
export const GET_CURRENT_POLICY_BY_USERID_PETID = BASE_URL + '/policies/getcurrentpolicy'
export const SEARCH_POLICY_BY_ID_ADMIN = BASE_URL + '/policies/bykeyword'
export const SAVE_POLICY_DOCUMENT_SENT_BY_ID_ADMIN = BASE_URL + '/policies/savestatuspolicysent'
export const SAVE_PET_TAG_SENT_BY_ID_ADMIN = BASE_URL + '/policies/savestatuspettagsent'
export const SAVE_PET_PRE_EXISTING_CONDITIONS = BASE_URL + '/pets/updatepreexistingcondition'
export const GET_CLAIMS_ADMIN = BASE_URL + '/claims/bystatus'
export const SEARCH_CLAIMS_BY_ADMIN = BASE_URL + '/claims/search'
export const GET_CLAIM_BY_ID_ADMIN = BASE_URL + '/claims/byid'
export const INITIATE_CLAIM_BY_ID_ADMIN = BASE_URL + '/claims/initiateprocess'
export const INITIATE_CALCULATE_CLAIM_BY_ID_ADMIN = BASE_URL + '/claims/claimsinitiatecalculateprocess'
export const SAVE_CLAIM_VET_IDENTIFIED_REASON = BASE_URL + '/claims/updateVetIdentifiedReason'
export const GET_CLAIMS_AND_POLICY_HEALTH_INFO = BASE_URL + '/claims/bypolicyinfobypolicyid'
export const SAVE_POLICY_CANCEL_REQUEST = BASE_URL + '/policies/savepolicycancelrequest'
export const SAVE_POLICY_CHANGE_REQUEST = BASE_URL + '/policies/savepolicychangerequest'
export const SAVE_VET_DEMO_REQUEST = BASE_URL + '/vets/requestdemo'
export const GET_VETS = BASE_URL + '/vets'
export const ASSIGN_VET_TO_CLAIM = BASE_URL + '/claims/attachVetToClaim'
export const SAVE_VET_AND_ASSIGN_TO_CLAIM = BASE_URL + '/vets/savevet'
export const SEND_EMAIL_MESSAGE = BASE_URL + '/message/email'
export const INITIATE_A_CALL = BASE_URL + '/message/call'




//cookies data
export const TOKEN_COOKIE = 'OSLO_TOKEN'
export const USER_COOKIE = 'OSLO_USER'
export const COOKIE_EXPIRE = 60 * 60 * 24 * 1
export const PATH_COOKIE = '/'

export const PolicyStatus = {
    submitted: 'submitted',
    policy_doc_sent: 'policy_doc_sent',
    pet_tag_sent: 'pet_tag_sent',
    expired: 'expired'
}

export const ClaimStatus = {
    new_claim: 'new_claim',
    in_process: 'in_process',
    approved_by_vet: 'approved_by_vet',
    declined_by_vet: 'declined_by_vet',
    accepted: 'accepted',
    rejected: 'rejected',
    paid: 'paid'
}
//Reminder Types
export const REMINDER_TYPES = [
    'birthday', 
    'vaccination', 
    'appointment', 
    'other'
]
