import React, { Component } from 'react';
import { Card, Col, Row } from 'antd';

const ReminderNotification = (props) => {
    return (
        <div className="site-card-wrapper">
            <Card title={props.title} bordered={false}>
                {props.description}
            </Card>
        </div>
    );
}
       

export default ReminderNotification;