import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { Header } = Layout;


const MyAccountSettings = (
    <Menu className="dropdown-link">
        <Menu.Item key="1">
            <Link to={"/pets_cloud"}>1st menu item</Link>
        </Menu.Item>
        <Menu.Item key="2">
            <Link to={"/pets_cloud"}>2nd menu item</Link>
        </Menu.Item>
        <Menu.Item key="3">
            <Link to={"/pets_cloud"}>3rd menu item</Link>
        </Menu.Item>
        <Menu.Item key="4">
            <Link to={"/pets_cloud"}>4th menu item</Link>
        </Menu.Item>
        <Menu.Item key="5">
            <Link to={"/pets_cloud"}>5th menu item</Link>
        </Menu.Item>
    </Menu>
);

class header extends Component {
    render() {
        return (
            <Fragment>
                <Header className="dashboard-header">
                    <div className="col-left">
                        <Link to={'/dashboard'}><img src="/images/dashboard_logo.png" /></Link>
                    </div>
                    <div className="col-right">
                        <ul>
                            <li>avabrown@gmail.com</li>
                            <li>
                                <Dropdown overlay={MyAccountSettings} placement="bottomRight">
                                    <a href="#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        My account <DownOutlined />
                                    </a>
                                </Dropdown>
                            </li>
                            <li><Link className="text-light-green" to={"/login"}>Logout</Link></li>
                        </ul>
                    </div>
                </Header>
            </Fragment>
        );
    }
}

export default header;