import React, { Component } from 'react';
import { Typography, Card, Row, Col, Button, Input, Spin } from 'antd';
import { createClaim, uploadPetImage, uploadClaimDocs } from '../../../api/pets-api';
import { notifications, getUser } from '../../../helpers/utilities';
import axios from 'axios';
import { UPLOAD_CLAIM_DOCS_API } from '../../../api/constants';


const { Paragraph, Title } = Typography;
const { TextArea } = Input;

class LastStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            otherClaim: true,
            fileClaim: false,
            userDetails: {},
            yesBtn: 'btn-red-outline',
            noBtn: 'btn-red',
        }
    }

    componentDidMount() {
        this.setState({ userDetails: getUser() }, console.log('userDetails', this.state.userDetails)
        );
    }

    makeAnotherClaim = () => {
        this.setState({ yesBtn: 'btn-red', noBtn: 'btn-red-outline' });
        this.props.resetSteps();
        // this.fileClaim("leave");
    }

    makeOnlyClaim = () => {
        this.setState({ yesBtn: 'btn-red-outline', noBtn: 'btn-red' });
        this.fileClaim("stay");
    }

    fileClaim = async (flag) => {
        let files = this.props.claimObj.files;
        console.log('claimfile', this.props.claimObj, files);
        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };

        // return ;
        // fmData.append("image", this.props.claimObj.files[0]);
        this.setState({ spinning: true })
        fmData.append('files', files);

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append("files", file);
        });

        try {
            // const res = await uploadPetImage('20512938-c9cf-4061-83f8-726c5d50ab0d', fmData, config)
            // delete this.props.claimObj.files;
            const res = await createClaim(this.props.claimObj);
            console.log('response', res);

            // await uploadClaimDocs(res.data.claimId, fmData, config);  not working don't know why
            axios({
                method: "POST",
                url: UPLOAD_CLAIM_DOCS_API + '/' + res.data.claimId,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            this.setState({ spinning: false, otherClaim: false, fileClaim: true });
            notifications('Claim filed Successfully!', 'Claims', 'success', 3);
            flag === "leave" && this.props.resetSteps();

        } catch (err) {
            console.log("Eroor: ", err);
            this.setState({ spinning: false })
            notifications('Something went wrong', 'Uploads', 'error');
        }
    }

    render() {
        return (
            <Spin className="spinner-wrp" spinning={this.state.spinning} tip="Filing Claim..." size="large" >
                <Row gutter={[15, 30]} align="middle">
                    <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                        <Title level={3} className="text-green font-700 mb-5">{this.state.userDetails?.firstName} {this.state.userDetails?.lastName}, you’re done!</Title>
                        <Row gutter={[15, 50]} justify="center">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <img src="/images/icon-check-red.png" />
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4} className="text-green">Now give us 3 days to get back to you.</Title>
                            </Col>
                            
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row gutter={[40, 20]} justify="center">
                                    {
                                        !this.state.fileClaim &&
                                        <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                            <Button disabled={this.state.fileClaim} block size="large" className={`${this.state.noBtn} font-800 border-radius-10`} onClick={() => this.makeOnlyClaim()}>
                                                Submit
                                            </Button>
                                        </Col>
                                    }
                                    {
                                        !this.state.otherClaim && 
                                        <Col xl={5} lg={12} md={7} sm={7} xs={24}>
                                            <Button disabled={this.state.otherClaim} block size="large" className={`${this.state.yesBtn} font-800 border-radius-10`} onClick={() => this.makeAnotherClaim()}>
                                                Another claim
                                            </Button>
                                        </Col>
                                    }
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                        <Card className="text-center border-green rounded-10 w-100">
                            <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                            <span className="text-green h5">Thanks mate!</span>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default LastStep;