import React, { Component } from 'react';
import { Typography, Form, Row, Col, Input, Button } from 'antd';
import MainLayout from '../layouts/mainLayout'
import PhoneInput from 'react-phone-input-2'
import { SaveDemoRequest } from './../api/pets-api';
import { notifications, applyDiscountVat } from './../helpers/utilities';
const { Title } = Typography;
const { TextArea } = Input;

class schedule_demo extends Component {
    async onFinish(values) {
        console.log(values);
        //await this.saveDemoRequest(values);
        let obj = {
            clinicName: values.clinic_name,
            firstName: values.first_name,
            lastName: values.last_name,
            emailAddress: values.email_address,
            phone: values.phone,
            area: values.area,
        }

        let result = await SaveDemoRequest(obj);
        console.log('result', result);
        // this.setState({visible:false});
        if(result.data.result){
            // window.location.reload();
            notifications('Successully Saved Your Request.', 'Alert', 'success')
        }
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    saveDemoRequest = async (values) => {
        console.log('submitted values', values);
        let obj = {
            clinicName: values.clinic_name,
            firstName: values.first_name,
            lastName: values.last_name,
            emailAddress: values.email_address,
            phone: values.phone,
            area: values.area,
        }

        let result = await SaveDemoRequest(obj);
        console.log('result', result);
        // this.setState({visible:false});
        if(result.data.result){
            // window.location.reload();
            notifications('Successully Saved Your Request.', 'Alert', 'success')
        }
    }

    render() {
        return (
            <MainLayout>
                <div className="pets-cloud">
                    <div className="banner">
                        <img
                            className="img-fluid w-100"
                            src="/images/banner-schedule-demo.jpg"
                        />
                        <div className="banner-caption">
                            <div className="container">                                
                                <Row gutter={15}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                        <div className="text-center">
                                            <Title level={1} className="text-green tagline">We want to <br /> make your job <br /> easier!</Title>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="full-width-section full-width">
                        <div className="container">
                            <Row gutter={15} align="middle" justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                                    <Title level={2} className="text-green font-700 mb-0">Learn how OSLO can support the vet community</Title>
                                </Col>
                            </Row>
                            <Form
                                name="found_lost_pet"
                                size={"large"}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                className="customise-form"
                            >
                                <Row gutter={15} align="middle" justify="center">
                                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                        <div className="section-gray mt-5">
                                            <Title className="text-light-green" level={3}>Your contact information</Title>
                                            <Row gutter={15}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item
                                                        name="clinic_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your clinic name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Clinic Name*"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="first_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your first name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"First Name *"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="last_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your last name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Last Name *"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item
                                                        name="email_address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Email Address*"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        size={"large"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your phone number',
                                                            },
                                                        ]}
                                                    >
                                                        <PhoneInput
                                                            inputStyle={{ width: '100%', height: '53px' }}
                                                            country={'ae'}
                                                            placeholder={"Add Phone number"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="area"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your area!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Area *"} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center mt-3">
                                            <Title level={3} className="text-red text-center mt-5 mb-5">OSLO will contact you to schedule a demo!</Title>
                                            <Button type="primary" htmlType="submit" className="btn-red" size="large">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default schedule_demo;