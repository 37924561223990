function searchFAQData(faqData, searchTerm) {
    // Function to check if a string contains the search term
    function containsSearchTerm(text, searchTerm) {
        return text.toLowerCase().includes(searchTerm.toLowerCase());
    }

    // Function to filter items recursively
    function filterItems(items, searchTerm) {
        return items.map(item => {
            let childMatches = item.hasChildItems && item.childItems
                ? filterItems(item.childItems, searchTerm)
                : [];

            let matches = containsSearchTerm(item.question, searchTerm) ||
                          containsSearchTerm(item.answer, searchTerm) ||
                          childMatches.length > 0;

            if (matches) {
                return {
                    ...item,
                    childItems: childMatches.length > 0 ? childMatches : undefined,
                    hasChildItems: childMatches.length > 0
                };
            } else {
                return null;
            }
        }).filter(item => item !== null);
    }

    // Main filtering logic
    return faqData.map(section => {
        const filteredItems = filterItems(section.items, searchTerm);
        if (filteredItems.length > 0) {
            return {
                ...section,
                items: filteredItems
            };
        } else {
            return null;
        }
    }).filter(section => section !== null);
}

export default searchFAQData;